import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';

@Injectable()
export class APIURL {
	appVersion:string = env.appVersion;
	baseUrl:string = env.baseUrl;
	ipInfoUrl: string = env.ipInfoUrl;
	apiUrl: string = env.apiUrl;
	apiV3: string = env.apiV3;
	apiV5: string = env.apiV5;
	themeUrl: string =  env.themeUrl;
	themeUrlV3: string = env.themeUrlV3;
	campaignUrl: string = env.campaignUrl;
	leadsUrl: string = env.leadsUrl;
	transUrl: string = env.transUrl;
	bksyncUrl: string = env.bksyncUrl;
	BKUtilUrl: string = env.BKUtilUrl;
	imgBase: string = env.imgBase;
	assetsBase: string = env.assetsBase;
	domainName: string = env.domainName;
	tinymceAPIKey: string = env.tinymceAPIKey;
	fileCdn: string = env.fileCdn;
	bkcdn: string = env.bkcdn;
	themeVersion : string = env.themeVersion;
	checklistUrl: string = env.checklistUrl;
	invoiceUrl: string =  env.invoiceUrl;
	ejabSocketURL: string = env.ejabSocketURL;
	ejabDomain: string = env.ejabDomain;
	privateCdn: string = env.privateCdn;
}
