<div [id]="secId" class="py-35 py-md-80 tjs-section-review" *ngIf="utilServ.appPermission('rating') && pageSett && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && (isReviews && reviews && reviews.length > 0)">
	<div class="section-reviews live-reviewblock-wrap">
		<div class="review-block live-reviewblock text-center">
			<div class="tjs-section__title text-center" *ngIf="(section?.review?.title || section?.review?.tooltip_text)" [id]="section?.review?.id">
				<div class="tjs-section__heading">
					<div class="d-flex align-items-center justify-content-center mb-10 mb-md-35">
						<h3 *ngIf="section?.review?.title" [id]="section?.review?.title_id" class="display-5 fw-bold bk-title" [innerHTML]="section?.review?.title | safeHtml"></h3>
						<bk-tooltip customClass="bk-tooltip" [id]="section?.review?.tooltip_text_id" *ngIf="section?.review?.tooltip_text" [content]="section?.review?.tooltip_text | translate">
						</bk-tooltip>
					</div>
				</div>
			</div>
			<!-- Slider -->
			<div class="tjs-live-review position-relative mx-auto" [id]="section?.review_card">
				<div [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'" class="keen-slider bk-slider" #reviewSlide>
					<div class="keen-slider__slide" *ngFor="let review of reviews;trackBy: secServ.trackByFnIndex">
						<div class="bk-slider-item tjs-live-review__content rounded-5 tjs-liveslider-shadow text-center position-relative bg-white m-15 p-25 p-md-50">
							<ng-container *ngTemplateOutlet="contentTemp; context:{review:review,isReadMore: true}"></ng-container>
						</div>
					</div>
				</div>
				<span (click)="slider.prev()" class="tjs-live-review-arrow__left slider-arrow h4 mb-0 position-absolute tjs-pointer"><i class="tjsicon-left-arrow text-dark"></i></span>
				<span (click)="slider.next()" class="tjs-live-review-arrow__right slider-arrow h4 mb-0 position-absolute tjs-pointer"><i class="tjsicon-right-arrow text-dark"></i></span>
			</div>
		</div>
	</div>
</div>
<!-- Content template -->
<ng-template #contentTemp let-review="review" let-isReadMore="isReadMore">
	<!-- User name -->
	<div class="mb-10" *ngIf="custDetail != 'no_name' && (review?.review_fields?.first_name || review?.review_fields?.last_name)">
		<h5 class="text-capitalize fw-bold bk-slider-item-title">{{review?.review_fields?.first_name}} {{utilServ.lastInitial(custDetail, review?.review_fields?.last_name)}}</h5>
	</div>
	<!-- Rating -->
	<div class="d-flex justify-content-center mb-20">
		<bk-rating-display class="bk-slider-rating" [rating]="review?.rating" [readOnly]="true"></bk-rating-display>
	</div>
	<!-- Description -->
	<p class="mb-0 bk-slider-item-desc" *ngIf="review.review">
		{{isReadMore ? (utilServ.reviewDesc(review.review)) : review.review}}
		<button type="button" class="btn btn-link read-more bk-slider-read-more fs-14 text-capitalize p-0" *ngIf="isReadMore && utilServ.reviewCount(review.review)" (click)="openModal(review)">{{initServ.appStr?.text?.readMore}}</button>
	</p>
</ng-template>
<!-- Modal -->

<div class="extend-content-popup" *ngIf="modalData">
	<div id="reviewModal_{{modalData?.id}}" class="modal fade" tabindex="-1" data-bs-keyboard="true" aria-labelledby="" aria-hidden="true">
		<div class="tjs-popup-review modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content tjs-popup tjs-popup--sm border-0 mx-auto">
				<div class="px-20 pt-15 text-end">
					<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-label="Close" (click)="modalData = null">
						<i class="tjsicon-close text-danger bg-white"></i>
					</button>
				</div>
				<div class="modal-body tjs-popup__content text-center testimonial-modal-content">
					<ng-container *ngTemplateOutlet="contentTemp; context:{review:modalData}"></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>