import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild,ElementRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, InitServ, UtilServ, LoaderServ, SectionServ, ApiRespCacheServ } from '../../../Services';
@Component({
	selector: 'bk-live-review',
	templateUrl: './LiveReview.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class LiveReviewComponent implements OnInit, OnDestroy {
	//Variables
	@Input() section: any;
	@Input() pageType: string = 'booking';
	@Input() pageSett: any;
	@ViewChild('liveReviewSlide') sliderRef : ElementRef<HTMLElement> | undefined;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	slider: any = null;
	isReviews: boolean = false;
	custDetail: string = '';
	reviews: any = [];
	reviewsLimit: number = 30;
	modalData:any;
	// Slider configuration
	slideConfig = {
		slides: { perView: 1 },
		rtl:(this.initServ.selectedLang?.rtl) ? true : false,
		loop:false,
		defaultAnimation:{duration:1000},
	};
	loaderId: string = 'live-review-loader';
	sectionStatus: any ={
		section: true,
		title: true,
		tooltip: true
	}
	showReview:boolean = false;

	constructor(public initServ: InitServ, public utilServ: UtilServ, @Self() private destroy: NgOnDestroy,private cDRef: ChangeDetectorRef, private loader: LoaderServ, private secServ: SectionServ, private apiRespCacheServ: ApiRespCacheServ) {
		// Set the RTL value
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
		this.checkCustomerStoreSett();
	}

	ngOnInit(): void {
		if(this.utilServ.appPermission('rating') && this.isReviews){
			if(this.pageType == 'booking' && this.section){
				this.sectionStatus.section = this.secServ.checkEleStatus(this.pageSett, this.section.id);
				this.sectionStatus.title = this.secServ.checkEleStatus(this.pageSett, this.section.title_id);
				this.sectionStatus.tooltip = this.secServ.checkEleStatus(this.pageSett, this.section.tooltip_text_id);
			}
			//Load reviews
			if(this.sectionStatus.section){
				// this.getReviews();
				this.apiRespCacheServ.getLiveReviewsApi(this.reviewsLimit).subscribe(data => {
					this.loader.show(this.loaderId);
					this.reviews = data;
					this.updateSliderConfig();
				});
			}
		}
	}

	/**
	 * Checks and applies customer-related settings from merchant preferences.
	 * Determines if live reviews should be enabled and sets customer detail visibility.
	 */
	private checkCustomerStoreSett(): void {
		let {enable_live_reviews = null, show_customer_detail = null} = this.initServ.appAdmnStngs?.merchant_settings?.customers ?? {};
		// Review enable
		if(enable_live_reviews && enable_live_reviews == 'yes'){
			this.isReviews = true;
		}
		// Review customer name settings
		if(show_customer_detail){
			this.custDetail = show_customer_detail;
		}
	}

	/**
	 * Updates the slider configuration based on the number of reviews.
	 * If there is more than one review, looping is enabled.
	 * Reinitializes the slider with autoplay functionality after a short delay.
	 */
	private updateSliderConfig(): void {
		if(this.utilServ.checkArrLength(this.reviews)){
			this.slideConfig['loop'] = this.reviews.length > 1;
			setTimeout(()=>{
				if(this.sliderRef){
					if (this.slider) {this.slider.destroy();}
					this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig,[(slider) => this.utilServ.autoPlay(slider,6000)])
				}
			});
		}
		this.showReview = true;
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}

	/**
	 * Open modal
	 * @param review
	 */
	public openModal(review: any): void {
		this.modalData = review;
		setTimeout(() => {
			this.utilServ.togglePopup(`review_${review.id}`);
		}, 100);
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}