import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from "rxjs/operators";
// Services
import { NgOnDestroy, UtilServ, ApiServ, LoaderServ, PopupServ, InitServ, SectionServ, RenderComponentServ, ApiRespCacheServ } from '../Services';
@Component({
	selector: 'bk-dashboard',
	templateUrl: './Dashboard.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DashboardComponent implements OnInit {
	// Variables
	tabs: any[] = [
		{ name: 'Appointments', slug: 'upcoming' },
		{ name: 'Previous appointments', slug: 'completed'},
		{ name: 'Canceled appointments', slug: 'cancelled'},
		{ name: 'Declined payments', slug: 'declined'}
	]
	active: string = 'upcoming';
	limit: number = 9;
	page: number = 1;
	bookings: any;
	loaderId: string = 'upcoming-loader';
	multiIndusStatus: boolean = false;
	renderProfile: boolean = true;
	viewAllRoute: string = '/appointments';
	showLoadMore: boolean = false;
	//
	secId: any;
	section: any = { profile: null, appointments: null };

	// eslint-disable-next-line max-params
	constructor(@Self() public destroy: NgOnDestroy, public actRoute: ActivatedRoute, public router: Router, public utilServ: UtilServ, public apiServ: ApiServ, public loader: LoaderServ, public popupServ: PopupServ, public initServ: InitServ, public rcServ: RenderComponentServ, public secServ: SectionServ, private cDRef: ChangeDetectorRef, public apiRespCacheServ: ApiRespCacheServ) {
		// TODO implement cache service rendering issue
		// this.apiRespCacheServ.getBkngCustomzDataApi().subscribe(data => {
		// 	if(data){
		// 		console.log('resharing', data);
		// 		this.initServ.bkngCustomzData = data;
		// 		this.refreshComp(false);
		// 		this.cDRef.detectChanges();
		// 	}
		// });
		this.initServ.bookingSpots().then(() => {
			this.refreshComp(false);
			this.cDRef.detectChanges();
		});
		this.initServ.loadBkngCustData().then(() => {
			this.refreshComp(false);
			this.cDRef.detectChanges();
		});

		// Multi industry
		let multiIndus: any = this.utilServ.multiIndustriesStatus();
		this.multiIndusStatus = multiIndus?.multiIndus;
	}

	ngOnInit(): void {
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			let pageSett:any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
		// URL Fragment, based this active tab works, other wise default.
		let fragment = this.actRoute.snapshot.fragment;
		if (fragment) {
			this.activeTab(fragment);
		} else {
			this.userBookings();
		}
	}
	/**
	 * User bookings
	 */
	private userBookings(): void {
		this.bookings = null;
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		let queryParams = { status: this.active, limit: this.limit, page: this.page };
		this.apiServ.callApiWithPathQueryVars('GET', 'Bookings', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.getBookingsApiResp(res));
	}

	/**
	 * Method to get the bookings data
	 * @param res response
	 */
	private getBookingsApiResp(res:any): void{
		if(this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)){
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, this.bookings, this.limit);
			this.bookings = loaMoreData.items;
			this.showLoadMore = loaMoreData.showLoadMore;
		}
		this.loader.hide(this.loaderId);
	}
	/**
	 * Based on active tab get the user bookings.
	 * @param status upcoming/completed/cancelled/declined
	 */
	public activeTab(status: string, navigate: boolean = false): void {
		this.active = status;
		this.loaderId = `${status}-loader`;
		this.userBookings();
		if (navigate && this.router?.url) {
			let route = this.utilServ.getUrlWithoutFragment(this.router.url);
			this.router.navigateByUrl(`${route}#${status}`);
		}
		switch (status) {
			case 'completed':
				this.viewAllRoute = '/appointments/previous';
				break;
			case 'cancelled':
				this.viewAllRoute = '/appointments/canceled';
				break;
			case 'declined':
				this.viewAllRoute = '/appointments/declined-payments';
				break;
			default:
				this.viewAllRoute = '/appointments';
				break;
		}
	}
	/**
	 * Industries popup
	 */
	public industriesPopup(): void {
		this.popupServ.industriesPopup();
	}
	/**
	 * Refresh component
	 * @param status
	 */
	public refreshComp(status: boolean): void {
		this.renderProfile = false;
		if (status) { this.userBookings();}
		setTimeout(() => {
			this.renderProfile = true;
			this.loader.hide(this.loaderId);
		}, 0);
	}
}
