<div class="position-relative">
	<div [id]="section?.id" class="card mb-30 rounded-5 bg-transparent min-height--150" *ngIf="utilServ.appPermission('rating') && section && (isReviews && reviews && reviews.length > 0) && sectionStatus && sectionStatus?.section">
		<div class="tjs-review rounded-5 tjs-slider-arrow bk-slider-item" [style.background-image]="'url(' + initServ.img?.Quote + ')'">
			<div class="card-body">
				<!-- Title -->
				<div class="d-flex align-items-center justify-content-between">
					<h5 [id]="section?.title_id" *ngIf="(section?.title && sectionStatus?.title) || (section?.tooltip_text && sectionStatus?.tooltip)" class="fw-bold mb-0">
						<span *ngIf="section?.title && sectionStatus?.title" [innerHTML]="section?.title | safeHtml"></span>
						<bk-tooltip [id]="section?.tooltip_text_id" *ngIf="section?.tooltip_text && sectionStatus?.tooltip" customClass="{{section?.title_id}}-tooltip" [content]="section?.tooltip_text | translate"></bk-tooltip>
					</h5>
					<div class="d-flex ms-auto" *ngIf="reviews.length > 1">
						<span (click)="slider.prev()" class="tjs-pointer mx-10 text-dark bk-slider-btn slider-arrow fs-16 {{section?.title_id}}-tooltip"><i class="tjsicon-left-arrow"></i></span>
						<span (click)="slider.next()" class="tjs-pointer text-dark bk-slider-btn fs-16 slider-arrow {{section?.title_id}}-tooltip"><i class="tjsicon-right-arrow"></i></span>
					</div>
				</div>
				<!-- Slider -->
				<div class="sidebar-reviews text-center pt-40">
					<div [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'" class="keen-slider form-reviews-slider" #liveReviewSlide>
						<div class="keen-slider__slide" *ngFor="let review of reviews;trackBy: utilServ.trackByFnIndex">
							<div class="slide-item" *ngIf="showReview">
								<ng-container *ngTemplateOutlet="contentTemp; context:{review:review,isReadMore: true}"></ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Content template -->
<ng-template #contentTemp let-review="review" let-isReadMore="isReadMore">
	<!-- Description -->
	<div class="slide-content align-items-center justify-content-center" *ngIf="isReadMore && review?.review">
		<h5 class="mb-40 text-dark h5 bk-slider-item-desc">
			{{isReadMore ? (utilServ.reviewDesc(review.review)) : review.review}}
			<button type="button" class="btn btn-link read-more bk-slider-read-more fs-14 text-capitalize p-0" [id]="section?.read_more_id" *ngIf="isReadMore && utilServ.reviewCount(review.review)" (click)="openModal(review)">{{section?.read_more ? section?.read_more : initServ.appStr?.text?.readMore}}</button>
		</h5>
	</div>
	<!-- User name -->
	<div class="mb-5" *ngIf="custDetail != 'no_name' && (review?.review_fields?.first_name || review?.review_fields?.last_name)">
		<h5 class="text-capitalize fw-bold bk-slider-item-title">{{review?.review_fields?.first_name}} {{utilServ.lastInitial(custDetail, review?.review_fields?.last_name)}}</h5>
	</div>
	<!-- Rating -->
	<div class="d-flex justify-content-center mb-0">
		<bk-rating-display class="bk-slider-rating" [rating]="review?.rating" [readOnly]="true"></bk-rating-display>
	</div>
	<!-- Description -->
	<p class="mb-0 bk-slider-item-desc mt-20" *ngIf="!isReadMore && review?.review">{{review?.review}}</p>
</ng-template>
<!-- Modal -->
<div class="extend-content-popup" *ngIf="modalData">
	<div id="review_{{modalData?.id}}" class="modal fade pe-0" data-bs-keyboard="true">
		<div [id]="section?.id" class="tjs-popup-review modal-dialog modal-dialog-centered mw-100 mx-15">
			<div class="modal-content tjs-popup tjs-popup--sm border-0 mx-auto bk-slider-item">
				<div class="px-20 pt-15 pb-0 text-end">
					<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-hidden="true" (click)="modalData = null"><i class="tjsicon-close text-danger"></i></button>
				</div>
				<div  class="modal-body tjs-popup__content text-center mb-20 testimonial-modal-content">
					<ng-container *ngTemplateOutlet="contentTemp; context:{review:modalData}"></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>