import { Injectable } from '@angular/core';
import { takeUntil, Subject } from 'rxjs';
// Services
import { ApiServ, InitServ, UtilServ, CookieServ } from '../Services';
// Interfaces
import { CurrentUser, TypeSlugObj, ConstructCommonData, GetBkngFormData, GetGiftCardData, GetReferralData, UserInfo } from '../Interfaces/';
import { TEXT_REG_EXP } from '../Constants';
@Injectable({
	providedIn: 'root'
})
export class LeadsServ {
	// Variables
	currentUser: CurrentUser;
	timer: any;
	// Private variables
	private destroy = new Subject<void>();
	constructor(public apiServ: ApiServ, private initServ: InitServ, private utilServ: UtilServ, private cookieServ: CookieServ) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if (value) {
				this.currentUser = this.utilServ.appLocalStorage();
			}
		});
	}
	/**
	 * Adds contact information to lead and submits it to the API after a certain interval.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @param {TypeSlugObj} typeSlugObj - The object containing the type and slug information.
	 * @param {number | null} interval - Optional interval for submitting data (default: -1).
	 */
	public addContactToLead(formVal: any, typeSlugObj: TypeSlugObj, interval: number | null = -1, isBkngFormSubmit: boolean = false, captureMethod: string | null = ''): void {
		if(this.isValidUser(formVal, typeSlugObj.type)){
			// Clear any existing timeout set by this function
			window.clearTimeout(this.timer);
			// Set timeout to submit data after a certain interval
			this.timer = window.setTimeout(() => {
				// Construct common data object
				let data: any = this.constructCommonData(typeSlugObj);
				if (typeSlugObj?.slug === 'contact-us'){
					data['capture_method'] = captureMethod
				}
				// Augment data based on form type
				this.augmentDataBasedOnFormType(data, typeSlugObj, formVal, isBkngFormSubmit);
				// Submit data to API
				this.apiServ.callApi('POST', 'LeadContacts', data).pipe(takeUntil(this.destroy)).toPromise();
			}, this.getInterval(interval));
		}
	}
	/**
	 * Checks the validity of user information based on the provided form value and type.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @param {string} type - The type of form being validated.
	 * @returns {boolean} - A boolean indicating whether the user information is valid.
	 */
	private isValidUser(formVal: any, type: string): boolean {
		// Initialize variables to store user information
		let [userEmail, userPhone, recipientEmail, recipientPhone]: string[] = ['', '', '', ''];
		// Determine the type of form and extract relevant user information
		switch (type) {
			case 'booking':
				formVal = formVal?.value;
				userEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.customer?.email_id));
				userPhone = this.validateVal(formVal?.customer?.phone_number);
				break;
			case 'theme_builder_short_form':
			case 'popup_short_form':
				userEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.customer?.email_id));
				userPhone = this.validateVal(formVal?.customer?.phone_number);
				break;
			case 'giftcard':
				userEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.sender_email));
				recipientEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.recipient_email));
				break;
			case 'referral':
				recipientEmail = formVal?.referred_email;
				recipientPhone = formVal?.referred_phone;
				break;
			case 'sign_up':
				formVal = formVal?.value;
				userEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.email_id));
				break;
			case 'popup':
				userEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.value?.email));
				break;
			case 'contact':
				userEmail = this.validateVal(this.utilServ.getValidEmail(formVal?.email));
				userPhone = this.validateVal(formVal?.phone_number);
				break;
		}
		// Check if the extracted user information is available
		return this.isUserAvailable(userEmail, userPhone, recipientEmail, recipientPhone);
	}
	/**
	 * Checks if any user information is available.
	 * @param {string} userEmail - The email of the user.
	 * @param {string} userPhone - The phone number of the user.
	 * @param {string} recipientEmail - The email of the recipient.
	 * @param {string} recipientPhone - The phone number of the recipient.
	 * @returns {boolean} - A boolean indicating whether any user information is available.
	 */
	private isUserAvailable(userEmail: string, userPhone: string, recipientEmail: string, recipientPhone: string): boolean {
		// Check if any of the user information fields are non-empty
		if((userEmail && userEmail != '') || (userPhone && userPhone != '') || (recipientEmail && recipientEmail != '') || (recipientPhone && recipientPhone != '')){
			return true;
		}else{
			return false;
		}
	}
	/**
	 * Constructs common data object.
	 * @param {TypeSlugObj} typeSlugObj - The object containing the type and slug information.
	 * @returns {ConstructCommonData} - The constructed common data object.
	 */
	private constructCommonData(typeSlugObj: TypeSlugObj): ConstructCommonData {
		return {
			is_admin: false,
			form_type: typeSlugObj.type,
			slug: typeSlugObj.slug,
			session_id: this.cookieServ.getLeadsCookie('session_id'),
			referrer_source: this.getInfoFromCookies('referrerSource', true),
			referrer_path: this.getInfoFromCookies('referrerPath', true),
			referrer_device_info: this.getInfoFromCookies('referrerDeviceInfo'),
			utm_params: this.getInfoFromCookies('utmParams')
		};
	}
	/**
	 * Augments data based on form type.
	 * @param {any} data - The data object to be augmented.
	 * @param {TypeSlugObj} typeSlugObj - The object containing the type and slug information.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 */
	private augmentDataBasedOnFormType(data: any, typeSlugObj: TypeSlugObj, formVal: any, isBkngFormSubmit: boolean = false): void {
		switch (typeSlugObj.type) {
			case 'booking':
				formVal = formVal?.value;
				this.updateAreaParamType(formVal);
				Object.assign(data, this.getBkngFormData(formVal, isBkngFormSubmit));
				break;
			case 'theme_builder_short_form':
			case 'popup_short_form':
				this.updateAreaParamType(formVal);
				Object.assign(data, this.getBkngFormData(formVal, isBkngFormSubmit));
				break;
			case 'giftcard':
				Object.assign(data, this.getGiftCardData(formVal));
				break;
			case 'referral':
				Object.assign(data, this.getReferralData(formVal));
				break;
			case 'sign_up':
				Object.assign(data, this.getSignupData(formVal?.value));
				break;
			case 'popup':
				Object.assign(data, this.getPopupData(formVal?.value));
				break;
			case 'contact':
				Object.assign(data, this.getContactData(formVal));
				break;
		}
	}
	/**
	 * Get the interval for submitting data based on the form type and optional interval parameter.
	 * @param {string} type - The type of form.
	 * @param {number | null} interval - An optional parameter representing the interval for submitting data, with a default value of -1.
	 * @returns {number} - The interval in milliseconds.
	 */
	private getInterval(interval: number | null = -1): number {
		// Default time interval
		let timeInterval = 5000;
		// // Adjust time interval based on form type
		// if (['booking', 'theme_builder_short_form', 'popup_short_form'].includes(type)) {
		// 	timeInterval = 20000;
		// }
		// Override time interval if interval parameter is provided and valid
		if (interval !== null && interval > -1) {
			timeInterval = interval;
		}
		return timeInterval;
	}
	/**
	 * Get formatted data for booking form.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @returns {GetBkngFormData} - Formatted data for booking form.
	 */
	private getBkngFormData(formVal: any, isSubmit: boolean): GetBkngFormData {
		return {
			form_data: {
				industry_id: +this.checkAndGetVal(formVal?.industry_id),
				form_id: +this.checkAndGetVal(formVal?.form_id),
				location_type: this.checkAndGetVal(formVal?.location_type),
				zipcode: this.checkAndGetVal(formVal?.zipcode),
				address_id: +this.checkAndGetVal(formVal?.address_id),
				address: this.checkAndGetVal(formVal?.address),
				location_id: +this.checkAndGetVal(formVal?.location_id),
				base_location_id: +this.checkAndGetVal(formVal?.base_location_id),
				service_category: +this.checkAndGetVal(formVal?.service_category),
				frequency: +this.checkAndGetVal(formVal?.frequency),
				base_frequency_id: +this.checkAndGetVal(formVal?.base_frequency_id),
				pricing_parameter: this.checkAndGetVal(formVal?.pricing_parameter),
				items: this.checkAndGetVal(formVal?.items),
				area_parameter: this.checkAndGetVal(formVal?.area_parameter),
				extras: this.checkAndGetVal(formVal?.extras),
				is_partial_cleaning: this.checkAndGetVal(formVal?.is_partial_cleaning),
				partial_cleaning: this.checkAndGetVal(formVal?.partial_cleaning)
			},
			user_info: {
				first_name: this.validateVal(formVal?.customer?.first_name) || '',
				middle_name: '',
				last_name: this.validateVal(formVal?.customer?.last_name) || '',
				email: this.validateVal(this.utilServ.getValidEmail(formVal?.customer?.email_id)),
				phone_number: formVal?.customer?.phone_number ? this.validateVal(this.utilServ.phoneUnmask(formVal?.customer?.phone_number)) : ''
			},
			occurrence: this.checkAndGetVal(formVal?.occurrence),
			is_booked: isSubmit
		}
	}
	/**
	 * Checks and retrieves the value.
	 * Returns the provided value if it exists, otherwise returns null.
	 * @param {string} val - The value to be checked and retrieved.
	 * @returns {any} - The provided value if it exists, otherwise returns null.
	 */
	private checkAndGetVal(val: string): any | null {
		return this.validateVal(val) || null;
	}
	private validateVal(val: string): string {
		return TEXT_REG_EXP.test(val) ? val : '';
	}
	/**
	 * Get formatted data for a gift card form.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @returns {GetGiftCardData} - Formatted data for a gift card form.
	 */
	private getGiftCardData(formVal: any): GetGiftCardData {
		return {
			user_info: {
				first_name: this.validateVal(formVal?.sender_first_name) || '',
				middle_name: '',
				last_name: this.validateVal(formVal?.sender_last_name) || '',
				email: this.validateVal(this.utilServ.getValidEmail(formVal?.sender_email)),
				phone_number: '',
			},
			recipient_info: {
				first_name: this.validateVal(formVal?.recipient_name) || '',
				middle_name: '',
				last_name: '',
				email: this.validateVal(this.utilServ.getValidEmail(formVal?.recipient_email)),
				phone_number: ''
			}
		}
	}
	/**
	 * Get formatted data for a referral form.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @returns {GetReferralData} - Formatted data for a referral form.
	 */
	private getReferralData(formVal: any): GetReferralData {
		return {
			user_info: {
				first_name: this.validateVal(this.initServ?.userInfo?.first_name) || '',
				middle_name: '',
				last_name: this.validateVal(this.initServ?.userInfo?.last_name) || '',
				email: this.validateVal(this.utilServ.getValidEmail(this.initServ?.userInfo?.email_id)),
				phone_number: this.validateVal(this.initServ?.userInfo?.phone_number) ||'',
			},
			recipients: {
				emails: this.getUniqueItems(formVal?.referred_email),
				phone_numbers: this.getUniqueItems(formVal?.referred_phone)
			}
		}
	}
	/**
	 * Get formatted data for a sign-up form.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @returns {user_info: UserInfo} - Formatted data for a sign-up form.
	 */
	private getSignupData(formVal: any): {user_info: UserInfo} {
		return {
			user_info: {
				first_name: this.validateVal(formVal?.first_name) || '',
				middle_name: '',
				last_name: this.validateVal(formVal?.last_name) || '',
				email: this.validateVal(this.utilServ.getValidEmail(formVal?.email_id)),
				phone_number: ''
			}
		}
	}
	/**
	 * Get formatted data for a popup form.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @returns {user_info: UserInfo} - Formatted data for a popup form.
	 */
	private getPopupData(formVal: any): {user_info: UserInfo} {
		return {
			user_info: {
				first_name: this.validateVal(formVal?.name) || '',
				middle_name: '',
				last_name: '',
				email: this.validateVal(this.utilServ.getValidEmail(formVal?.email)),
				phone_number: ''
			}
		}
	}
	/**
	 * Get formatted data for a contact form.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 * @returns {user_info: UserInfo} - Formatted data for a contact form.
	 */
	private getContactData(formVal: any): {user_info: UserInfo} {
		return {
			user_info: {
				first_name: this.validateVal(formVal?.name) || '',
				middle_name: '',
				last_name: '',
				email: this.validateVal(this.utilServ.getValidEmail(formVal?.email)),
				phone_number: this.validateVal(formVal?.phone_number) || ''
			}
		}
	}
	/**
	 * Get unique items from a comma-separated string.
	 * @param {string} itemString - The comma-separated string of items.
	 * @returns {string[]} - A string with unique items separated by commas.
	 */
	private getUniqueItems(itemString: string): string[] {
		if(itemString){
			// Split the string into an array of items
			let items: string[] = itemString.split(",").map(item => item.trim());
			// Create a Set to store unique items
			let uniqueItems: Set<string> = new Set(items);
			// Convert the Set back to a string separated by commas and return
			return (Array.from(uniqueItems)).filter(item => this.validateVal(item));
		}
		return [];
	}
	/**
	 * Retrieves and parses information from a specified cookie.
	 * @param {string} cookieName - The name of the cookie to retrieve.
	 * @returns The parsed cookie information if available, otherwise an empty object.
	 */
	private getInfoFromCookies(cookieName: string, isString: boolean = false): any {
		let defVal: any = isString ? null : {};
		let info: string = this.cookieServ.getLeadsCookie(cookieName);
		try {
			return info ? (isString) ? info.toString() : JSON.parse(info) : defVal;
		} catch (e) {
			console.error(`Error parsing cookie: ${cookieName}`, e);
			return defVal;
		}
	}
	/**
	 * Update the type of the area parameter quantity in the form values object.
	 * @param {any} formVal - The form data containing user information. The type of this field is "any" because this functions calls from the multiple pages/forms and each form has different form.
	 */
	private updateAreaParamType(formValues: any): void {
		if (formValues?.area_parameter && formValues?.area_parameter?.quantity) {
			let quantity = +(formValues?.area_parameter?.quantity);
			formValues.area_parameter.quantity = +quantity
		}
	}
}
