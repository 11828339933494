import { Injectable } from '@angular/core';
import { CalCmnFuncServ } from './CalCmnFunc.service';

@Injectable({
	providedIn: 'root'
})
export class PricingParamCalServ {

   constructor(private cmnFunc: CalCmnFuncServ) {

	}
   /*****************Form 1 param pricing calculation functions*************************************/


	/**
	 * Function named "calcValuePricingParameter" that takes in two parameters: "BKFrmValue" and "settingsObj". It returns an object with properties "price" and "time".
	 * There is a conditional statement checking if the "pricingParameter" array has a length.
	 * If true, it calls the "loopOnPricingParam" function passing in "pricingParameter", "BKFrmValue", and "settingsObj".
	 * The returned object from this function is assigned to "paramObj".
	 * The "price" and "time" variables are then updated by adding the corresponding properties of "paramObj".
	 * Finally, an object is returned with the updated values of "price" and "time".
	 * @param BKFrmValue: form control values
	 * @param settingsObj: settings obj
	 * @returns object
	 */
	public calcValuePricingParameter(BKFrmValue: any, settingsObj: any): any {
		let price = 0;
		let time = 0;
		let pricingParameter = BKFrmValue?.pricing_parameter;
		// If array has length
		if(this.cmnFunc.checkArrLength(pricingParameter)){
			// Call loopOnPricingParam to loop on selected pricing parameters
			let paramObj = this.loopOnPricingParam(pricingParameter, BKFrmValue, settingsObj);
			price += paramObj.price;
			time += paramObj.time;
		}
		return {
			price,
			time,
		};
	}
	/**
	 * Function takes three parameters: "pricingParameter", "BKFrmValue", and "settingsObj".
	 * It loops through the "pricingParameter" array and checks if the "settingsObj" has a property with the id matching the current price parameter.
	 * If it does, it calls another function called "calcParamTimePrice" to calculate the pricing parameter's time and price.
	 * The calculated price and time are then added to the respective variables.
	 * Finally, the function returns an object with the calculated price and time.
	 * @param pricingParameter
	 * @param BKFrmValue
	 * @param settingsObj
	 * @returns
	 */
	private loopOnPricingParam(pricingParameter: any, BKFrmValue: any, settingsObj: any): any{
		let price = 0;
		let time = 0;
		// Loop on selected parameters
		for(let priceParam of pricingParameter){
			if(settingsObj && settingsObj[priceParam?.id]){
				// Call func to calculate pricing param time and price
				let paramObj = this.calcParamTimePrice(priceParam, BKFrmValue, settingsObj);
				price += paramObj.price;
				time += paramObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function takes three parameters: "priceParam", "BKFrmValue", and "settingsObj".
	 * The function then checks if the value of "priceParamListVal" exists at the specified index within the "settingsObj" object.
	 * If it does, it proceeds to perform calculations.
	 * "selectedParam" is assigned the value at the specified index within the "priceParamListVal.value" array.
	 * The function then calls another function named "priceTimeBasedOnLocType" twice, passing in "BKFrmValue", "selectedParam", and 'price' or 'time' as arguments.
	 * The returned values are then added to the "price" variable and the "time" variable, respectively.
	 * Next, the function converts the time value from seconds to minutes by dividing it by 60 and rounding up to the nearest whole number.
	 * Finally, the function returns an object containing the "price" and "time" variables.
	 * @param priceParam
	 * @param BKFrmValue
	 * @param settingsObj
	 * @returns
	 */
	private calcParamTimePrice(priceParam: any, BKFrmValue: any, settingsObj: any): any {
		let price = 0;
		let time = 0;
		let priceParamListVal = settingsObj?.[priceParam?.id];
		if(priceParamListVal?.value?.[priceParam?.quantity]){
			let selectedParam = priceParamListVal?.value[priceParam.quantity];
			// select price value on locatin type based
			let selectedParamPrice = this.cmnFunc.priceTimeBasedOnLocType(BKFrmValue, selectedParam, 'price');
			// select time value on location type based
			let selectedParamTime = this.cmnFunc.priceTimeBasedOnLocType(BKFrmValue, selectedParam, 'time');
			// price
			price += (+selectedParamPrice);
			// time
			let priceInSeconds = +selectedParamTime;
			// convert time to minutes
			let totalParamValueInMin = priceInSeconds / 60;
			totalParamValueInMin = +(Math.ceil(totalParamValueInMin));
			time += totalParamValueInMin;
		}
		return {
			price,
			time,
		}
	}

   /*****************Form 2 items/packages pricing calculation functions*************************************/

	/**
	 * Function calculates the total price and time of selected items based on the provided parameters.
	 * It takes in three parameters: BKFrmValue, settingsObj, and formLayout (which is optional and defaults to 'two').
	 * Inside the function, it initializes the price and time variables to 0.
	 * Then, it retrieves the items parameter from the BKFrmValue object.
	 * If there are items selected, it calls either the loopOnAddonItems or loopOnPackageItems function depending on the formLayout value.
	 * It adds the price and time from the returned object to the total price and time variables.
	 * Finally, it returns an object containing the final price and time.
	 * @returns object
	 */
	public calcValueItems(BKFrmValue: any, settingsObj: any, formLayout: string = 'two'): any {
		let price = 0;
		let time = 0;
		let items = BKFrmValue?.items;
		if(this.cmnFunc.checkArrLength(items)){
			// Call loopOnPackageItems func to loop on selected items
			let itemObj = (formLayout == 'three') ? this.loopOnAddonItems(items, settingsObj, BKFrmValue) :  this.loopOnPackageItems(items, settingsObj, BKFrmValue);
			price += itemObj.price;
			time += itemObj.time;
		}
		return {
			price,
			time,
		};
	}
	/**
	 * Function is called "loopOnPackageItems" and it takes three parameters: "items", "settingsObj", and "BKFrmValue".
	 * It returns an object with "price" and "time" properties.
	 * It loops through the "items" array and checks if the "packages" array of each item has a length greater than 0.
	 * If it does, it calls the "loopOnPackages" function with the current item, "settingsObj", and "BKFrmValue" as parameters.
	 * The function returns an object with the calculated price and time values, which are added to the corresponding variables.
	 * Finally, the function returns an object with the accumulated price and time values.
	 * @param items
	 * @param settingsObj
	 * @param BKFrmValue
	 * @returns
	 */
	private loopOnPackageItems(items: any, settingsObj: any, BKFrmValue: any): any {
		let price = 0;
		let time = 0;
		// Loop on selected items
		for(let item of items){
			if(this.cmnFunc.checkArrLength(item?.packages)){
				// Call loopOnPackages func to loop on selected item's packages
				let itemPckgObj = this.loopOnPackages(item, settingsObj, BKFrmValue);
				price += itemPckgObj.price;
				time += itemPckgObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * loopOnPackages function loops through the selected packages in the item.packages array.
	 * It checks if the package exists in the settingsObj.package object and if it does,
	 * it calls the calcItemPackageTimePrice function to calculate the time and price of the package.
	 * The calculated price and time are then added to the respective variables.
	 * Finally, the function returns an object with the total price and time.
	 * @param item
	 * @param settingsObj
	 * @param BKFrmValue
	 * @returns
	 */
	private loopOnPackages(item: any, settingsObj: any, BKFrmValue: any): any {
		let price = 0;
		let time = 0;
		// Loop on selected packages
		for(let selectPackage of item.packages){
			if(settingsObj?.package && settingsObj?.package[selectPackage?.id]){
				// Call func to calculate the time price of package
				let itemPckgObj = this.calcItemPackageTimePrice(selectPackage, settingsObj, BKFrmValue);
				price += itemPckgObj.price;
				time += itemPckgObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function calculates the price and time for a selected package based on certain parameters.
	 * It retrieves the selected package based on the provided selectPackage and settingsObj.
	 * If a quantity is provided in selectPackage, it uses that as the package quantity; otherwise, it sets the quantity to 1.
	 * It then selects the price value based on the location type specified in BKFrmValue.
	 * If the location type is 'ML', it selects the price from the selectedPackage.prices_ml;
	 * otherwise, it selects the price from selectedPackage.prices_sa. The same logic is applied for selecting the time value.
	 * Next, the function checks if the selected package has a manual price/length type using a custom function isParamQtyBsedPriceManual.
	 * If so, it calculates the price and time based on the quantity index.
	 * Otherwise, it calculates the price and time by multiplying the selected package price and time by the package quantity.
	 * After calculating the package price and time, the function calls another function calcPackageAddonPrice to calculate the price and time for package addons. The resulting price and time are added to the previously calculated package price and time.
	 * Finally, the function returns an object with the calculated price and time.
	 * @param selectPackage
	 * @param settingsObj
	 * @param BKFrmValue
	 * @returns
	 */
	private calcItemPackageTimePrice(selectPackage: any, settingsObj: any, BKFrmValue: any){
		let price = 0;
		let time = 0;
		let selectedPackage = settingsObj?.package[selectPackage?.id];
		let packageQuantity = selectPackage.quantity ? selectPackage.quantity : 1;
		// select price value on locatin type based
		let selectedPackagePrice = BKFrmValue.location_type == 'ML' ? selectedPackage.prices_ml : selectedPackage.prices_sa;
		// select time value on locatin type based
		let selectedPackageTime = BKFrmValue.location_type == 'ML' ? selectedPackage.times_ml : selectedPackage.times_sa;
		// check if selected package has manual price/length type
		if(this.cmnFunc.isParamQtyBsedPriceManual(selectedPackage)){
			let quantityIndex = (+packageQuantity) - 1;
			// price
			price += (+selectedPackagePrice[+quantityIndex]);
			// time
			time += (+selectedPackageTime[+quantityIndex]);
		}else{
			// price
			price += ((+selectedPackagePrice[0])*(+packageQuantity));
			// time
			time += ((+selectedPackageTime[0])*(+packageQuantity));
		}
		let packageAddonValObj = this.calcPackageAddonPrice(selectPackage, BKFrmValue, settingsObj?.package_addons);
		//price
		price += packageAddonValObj.price;
		//time
		time += packageAddonValObj.time;
		return {
			price,
			time,
		}
	}
	/**
	 * Function is used to calculate the package addon price based on the selected package, BKFrmValue, and settingsObj.
	 * If the selectedPackage has a non-empty package_addons array, it calls the loopOnPackageAddon function to loop through each addon and calculate the price and time.
	 * Finally, it returns an object with the calculated price rounded to two decimal places and the total time.
	 * @param selectedPackage : package
	 * @param BKFrmValue: form control values
	 * @param settingsObj: settings obj
	 * @returns object
	 */
	public calcPackageAddonPrice(selectedPackage: any, BKFrmValue: any, settingsObj: any): any {
		let price = 0;
		let time = 0;
		if(this.cmnFunc.checkArrLength(selectedPackage?.package_addons)){
			// Call loopOnPackageAddon func to loop on package's addons
			let pckgAddonObj = this.loopOnPackageAddon(selectedPackage, BKFrmValue, settingsObj);
			price += pckgAddonObj.price;
			time += pckgAddonObj.time
		}
		return {
			price : this.cmnFunc.roundToTwo(+price),
			time,
		};
	}
	/**
	 * Function is called "loopOnPackageAddon" and it takes three parameters: selectedPackage, BKFrmValue, and settingsObj.
	 * It loops through each selectPackageAddon in the selectedPackage.package_addons array.
	 * For each selectPackageAddon, it checks if there is a corresponding value in the settingsObj using the selectPackageAddon's id as the key.
	 * If there is a value, it calls another function calcPackageAddonTimePrice to calculate the price and time for that package addon.
	 * After calculating the price and time for each package addon, it returns an object with the final price and time values.
	 * @param selectedPackage
	 * @param BKFrmValue
	 * @param settingsObj
	 * @returns
	 */
	private loopOnPackageAddon(selectedPackage: any, BKFrmValue: any, settingsObj: any){
		let price = 0;
		let time = 0;
		// Loop on selected package addons
		for(let selectPackageAddon of selectedPackage.package_addons){
			if(settingsObj && settingsObj[selectPackageAddon?.id]){
				// call func to calculate price time for package addon
				let pckgAddonObj = this.calcPackageAddonTimePrice(selectPackageAddon, BKFrmValue, settingsObj);
				price += pckgAddonObj.price;
				time += pckgAddonObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function calculates the price and time for a selected package addon.
	 * It takes three parameters: selectPackageAddon, BKFrmValue, and settingsObj.
	 * It retrieves the selected package addon based on the id from the settingsObj.
	 * Next, it determines the price and time values based on the location type specified in the BKFrmValue.
	 * If the location type is 'ML', it selects the prices_ml and times_ml values from the selected package addon. Otherwise, it selects the prices_sa and times_sa values.
	 * The function checks if the selected package addon has a manual price/length type using the isParamQtyBsedPriceManual function.
	 * If it does, it calculates the price and time based on the quantity selected.
	 * It retrieves the price and time values from the respective arrays using the quantity as an index and adds them to the variables price and time.
	 * If the selected package addon does not have a manual price/length type, it calculates the price and time based on the quantity multiplied by the first price and time values from the arrays.
	 * Finally, the function returns an object with the calculated price and time values.
	 * @param selectPackageAddon
	 * @param BKFrmValue
	 * @param settingsObj
	 * @returns
	 */
	private calcPackageAddonTimePrice(selectPackageAddon: any, BKFrmValue: any, settingsObj: any){
		let price = 0;
		let time = 0;
		let selectedPackageAddon = settingsObj[selectPackageAddon?.id];
		// select price value on locatin type based
		let selectedPackageAddonPrice = BKFrmValue.location_type == 'ML' ? selectedPackageAddon.prices_ml : selectedPackageAddon.prices_sa;
		// select time value on locatin type based
		let selectedPackageAddonTime = BKFrmValue.location_type == 'ML' ? selectedPackageAddon.times_ml : selectedPackageAddon.times_sa;
		// check if selected package has manual price/length type
		if(this.cmnFunc.isParamQtyBsedPriceManual(selectedPackageAddon)){
			let quantityIndex = (+selectPackageAddon?.quantity) - 1;
			//price
			price += (+selectedPackageAddonPrice[+quantityIndex]);
			//time
			time += (+selectedPackageAddonTime[+quantityIndex]);
		}
		else{
			let quantity = +selectPackageAddon?.quantity;
			//price
			price += ((+selectedPackageAddonPrice[0])*(quantity));
			//time
			time += ((+selectedPackageAddonTime[0])*(quantity));
		}
		return {
			price,
			time,
		}
	}
	/*****************Form 3 items/addons pricing calculation functions*************************************/

	/**
	 * Function loops over selected items and calculates the total price and time based on the selected addons for each item.
	 * It uses the loopOnItemAddons function to calculate the price and time for each addon selected for the item.
	 * The final price and time are returned as an object.
	 * @param itemsParameter
	 * @param settingsObj
	 * @param BKFrmValue
	 * @returns
	 */
	private loopOnAddonItems(itemsParameter: any, settingsObj: any, BKFrmValue: any): any {
		let price = 0;
		let time = 0;
		// Loop for selected items
		for(let itemParam of itemsParameter){
			let quantity = itemParam.quantity ? itemParam.quantity : 1;
			if(this.cmnFunc.checkArrLength(itemParam?.addons)){
				// Call loopOnItemAddons func to loop on item's selected addons
				let addonPriceTimeObj = this.loopOnItemAddons(itemParam, settingsObj, BKFrmValue, quantity);
				price += addonPriceTimeObj.price;
				time += addonPriceTimeObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function loops over selected addons for a specific item and calculates the total price and time based on the selected addons.
	 * It uses the function "calcItemAddonTimePrice" to calculate the price and time for each addon.
	 * The final price and time are returned as an object with properties "price" and "time".
	 * @param itemParam
	 * @param settingsObj
	 * @param BKFrmValue
	 * @param quantity
	 * @returns
	 */
	private loopOnItemAddons(itemParam: any, settingsObj: any, BKFrmValue: any, quantity: any): any {
		let price = 0;
		let time = 0;
		// Loop for selected addons
		for(let selectAddon of itemParam.addons){
			if(settingsObj && settingsObj[selectAddon?.id]){
				let selectedAddon = settingsObj[selectAddon?.id];
				// Calculate price and time
				let priceTimeObj = this.calcItemAddonTimePrice(selectedAddon, BKFrmValue, itemParam, quantity);
				price += priceTimeObj.price;
				time +=  priceTimeObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function calculates the price and time for a selected addon based on its parameters, the quantity, and the location type.
	 * It checks if the selected addon has a price and time specified for the current item parameter.
	 * If so, it retrieves the addon price and time based on the location type.
	 * Next, it checks if the selected addon has a manual price/length type and if the quantity is 0.
	 * If both conditions are met, it calculates the price and time based on the quantity index.
	 * If the conditions are not met, it calculates the price and time based on the first index of the addon price and time arrays, multiplied by the quantity.
	 * Finally, the function returns an object containing the calculated price and time.
	 * @param selectedAddon
	 * @param BKFrmValue
	 * @param itemParam
	 * @param quantity
	 * @returns
	 */
	public calcItemAddonTimePrice(selectedAddon: any, BKFrmValue: any, itemParam: any, quantity: any): any{
		let price = 0;
		let time = 0;
		if(selectedAddon?.item_based_price_time && selectedAddon.item_based_price_time[+itemParam.id]){
			let selectedAddonPriceTime = selectedAddon.item_based_price_time[+itemParam.id];
			// addon price obj based on location type
			// Note, it knowingly send 'prices' and 'times' because its param is like prices_sa etc
			let addonPriceLocBased =  this.cmnFunc.priceTimeBasedOnLocType(BKFrmValue, selectedAddonPriceTime, 'prices');
			// addon time obj based on location type
			let addonTimeLocBased = this.cmnFunc.priceTimeBasedOnLocType(BKFrmValue, selectedAddonPriceTime, 'times');
			// check if selected addon has manual price/length type
			if(this.cmnFunc.isParamQtyBsedPriceManual(selectedAddon) && this.cmnFunc.isZeroIndexQty(addonPriceLocBased, quantity) ){
				let quantityIndex = (+quantity) - 1;
				//price
				price = (+addonPriceLocBased[+quantityIndex]);
				//time
				time = (+addonTimeLocBased[+quantityIndex]);
			}
			else{
				//price
				price = (+addonPriceLocBased[0])*(+quantity);
				//time
				time = (+addonTimeLocBased[0])*(+quantity);
			}
		}
		return {
			price,
			time,
		}
	}
	/*****************Form 4 area param pricing calculation functions*************************************/

	/**
	 * Function calculates the pricing and total time value based on the area parameter received as input.
	 * It first checks if the quantity is provided and sets it to 0 if not.
	 * Then, it retrieves the price and time values for the area parameter based on the location type using the priceTimeBasedOnLocType function.
	 * The pricing value is calculated by multiplying the price parameter value with the quantity.
	 * The time value is calculated by converting the price parameter time value to minutes and multiplying it with the quantity.
	 * Finally, the pricing value is rounded to two decimal places and the pricing and time values are returned as an object.
	 * @param BKFrmValue: form control values
	 * @param settingsObj: settings obj
	 * @returns object
	 */
	public calcValueAreaPricing(BKFrmValue: any, settingsObj: any): any {
		let pricingValue = 0;
		let totalTimeValue = 0;
		let areaParameter = BKFrmValue?.area_parameter;
		// set quantity
		let quantity = (areaParameter?.quantity) ? (areaParameter.quantity) : 0;
		if(settingsObj && settingsObj[0] && settingsObj[0]?.value[areaParameter?.id]){
			let priceParamValue = settingsObj[0].value[areaParameter.id];
			// area param price obj based on location type
			let priceParamPrice = this.cmnFunc.priceTimeBasedOnLocType(BKFrmValue, priceParamValue, 'price');
			// area param price obj based on location type
			let priceParamTime = this.cmnFunc.priceTimeBasedOnLocType(BKFrmValue, priceParamValue, 'time');
			//price
			pricingValue += ((+priceParamPrice)*quantity);
			//time
			let priceInSeconds = +priceParamTime;
			// convert seconds to minutes
			let totalParamValueInSeconds = +(priceInSeconds * quantity);
			let totalParamValueInMin = totalParamValueInSeconds / 60;
			totalParamValueInMin = +(Math.ceil(totalParamValueInMin));
			totalTimeValue = (+totalTimeValue) + totalParamValueInMin;

		}
		return {
			price : this.cmnFunc.roundToTwo(pricingValue),
			time : totalTimeValue
		};
	}
}
