<ng-container  *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType]" [ngSwitch]="pageSett[secId]?.variation_id">
	<!-- V1 -->
	<div [id]="secId" *ngSwitchCase="'bk_app_widgets_V1'" class="tjs-app-widget border-bottom border-top">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-xl-6 tjs-cta--v1 pt-50 pb-40 tjs-body-bg">
					<div class="text-center">
						<!-- BK icon -->
						<div class="mb-15">
							<img loading="auto" class="img-fluid tjs-bg-style" width="60" height="60" [src]="initServ?.img?.BKIcon" alt="BookingKoala"/>
						</div>
						<!-- Title -->
						<a target='_blank' [href]="poweredByLink" class="h4 fw-light mb-35 bk-title d-inline-block">
							{{initServ?.appStr?.text?.youArePoweredByBK | translate}}
							<span class="fw-bold d-block pt-5"> {{initServ?.appStr?.text?.anAwardedBookingPlatform | translate}}</span>
						</a>
						<!-- Online list -->
						<ng-container *ngTemplateOutlet="onlineListTemp; context:{div_class:'text-center px-sm-15',li_class: 'd-inline-block', a_class:'d-flex justify-content-center align-items-center tjs-appwidget-shadow rounded-circle', span_class:'fw-bold tjs-cta__link-content'}"></ng-container>
					</div>
				</div>
				<div class="col-12 col-xl-6 pt-50 bg-white">
					<div class="tjs-download--v2 h-100 w-100">
						<div class="tjs-download__width mw-100 d-sm-flex px-sm-15 mx-auto h-100">
							<div class="tjs-download__img align-self-end" [ngStyle]="{'background-image' : 'url('+initServ?.img?.ProvMobApp+')'}"></div>
							<div class="tjs-download__content pt-20 pt-sm-0 mb-50 mb-sm-0 align-self-center">
								<!-- Title -->
								<h6 class="fs-20 fw-bold mb-20 bk-title" translate>{{initServ?.appStr?.text?.downloadProviderApp}}</h6>
								<div class="tjs-download__checklist">
									<!-- list -->
									<ng-container *ngTemplateOutlet="listTemp"></ng-container>
								</div>
								<!-- Download  -->
								<ng-container *ngTemplateOutlet="provAppTemp"></ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- V2 -->
	<div [id]="secId" *ngSwitchCase="'bk_app_widgets_V2'" class="tjs-cta tjs-cta--v2 bg-white py-30">
		<div class="container">
			<div class="tjs-cta__content d-md-flex align-items-center">
				<!-- BK icon -->
				<div class="tjs-cta__logo text-center text-md-start">
					<img loading="auto" class="img-fluid bk-media" width="100" height="100" [src]="initServ?.img?.BKIcon" alt="BookingKoala"/>
				</div>
				<div class="ps-md-70 pt-25">
					<!-- Title -->
					<a target='_blank' [href]="poweredByLink" class="h2 fw-bolder text-center text-md-start mb-25 bk-title d-inline-block" translate>{{initServ?.appStr?.text?.youArePoweredByBK | translate}} {{initServ?.appStr?.text?.anAwardedBookingPlatform | translate}}</a>
					<!-- Online list -->
					<ng-container *ngTemplateOutlet="onlineListTemp; context:{li_class: 'mb-15 px-sm-15', a_class:'fw-bold text-uppercase'}"></ng-container>
				</div>
			</div>
		</div>
	</div>
	<!-- V3 -->
	<div [id]="secId" *ngSwitchCase="'bk_app_widgets_V3'"  class="tjs-cta tjs-cta--v3 tjs-download py-35 pb-md-0">
		<div class="container">
			<div class="row justify-content-between">
				<!-- Provider mobile app media -->
				<div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 mb-md-0 mb-30">
					<div class="tjs-download__img bk-media" [ngStyle]="{'background-image' : 'url('+initServ?.img?.ProvMobApp+')'}"></div>
				</div>
				<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-center">
					<div class="tjs-download__content">
						<!-- Title -->
						<h4 class="display-6 fw-bold mb-25 bk-title" translate>{{initServ?.appStr?.text?.downloadProviderApp}}</h4>
						<!-- list -->
						<ng-container *ngTemplateOutlet="listTemp"></ng-container>
					</div>
					<!-- Download  -->
					<ng-container *ngTemplateOutlet="provAppTemp"></ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<!-- List template -->
<ng-template #listTemp >
	<ul class="list-unstyled bk-list pb-5" *ngIf="checkList && checkList.length > 0">
		<li class="mb-15 d-flex align-items-center bk-item" *ngFor="let item of checkList;trackBy: utilServ.trackByFnIndex">
			<div class="me-10">
				<img loading="auto" [src]="initServ?.img?.ChecklistIcon" class="img-fluid bk-item-media" width="11" height="9" alt="Checklist">
			</div>
			<span class="bk-item-title">{{initServ?.appStr?.text[item] | translate}}</span>
		</li>
	</ul>
</ng-template>
<!-- Online list template -->
<ng-template #onlineListTemp let-div_class="div_class" let-li_class="li_class" let-a_class="a_class" let-span_class="span_class">
	<div class="tjs-cta__link text-center {{div_class}}" *ngIf="onlineList && onlineList.length > 0">
		<ul class="mb-0 list-unstyled d-sm-inline-flex bk-list">
			<li class="tjs-cta__link-item {{li_class}} bk-item" *ngFor="let item of onlineList;trackBy: utilServ.trackByFnIndex">
				<a href="#" class="{{a_class}}">
					<span class="{{span_class}} bk-item-title">{{initServ?.appStr?.text[item] | translate}}</span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>
<!-- Provider app download button template -->
<ng-template #provAppTemp >
	<div class="mb-md-0 mb-5">
		<a href="{{iosProvApp}}" target="_blank" rel="noreferrer">
			<img loading="auto" [src]="initServ?.img?.AppStore" class="img-fluid bk-media" height="37" width="100" alt="App store" />
		</a>
		<a href="{{androidProvApp}}" class="ms-10" target="_blank" rel="noreferrer">
			<img loading="auto" [src]="initServ?.img?.PlayStore" class="img-fluid bk-media" height="37" width="100" alt="Play store" />
		</a>
	</div>
</ng-template>
