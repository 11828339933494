import { Component, ViewEncapsulation } from '@angular/core';
import { EmbedFormBaseComponent } from 'src/app/ThemeElements/embed-forms/embed-form-base/embed-form-base.component';

@Component({
	selector: 'bk-embed-lead-form',
	template: `
		<div [id]="secId" class="bkt-section" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && embedFormUrl">
			<iframe #iframeRef id="bk_embed_form_{{secId}}" [src]='embedFormUrl' style="border: none;" height="1200" width="100%" scrolling="no"></iframe>
			<script [src]='embedJsUrl' defer></script>
		</div>
	`,
	encapsulation: ViewEncapsulation.None
})
export class EmbedLeadFormComponent extends EmbedFormBaseComponent  {}
