import { Component, Input, OnInit, Self, ViewEncapsulation } from '@angular/core';
// External lib
import { takeUntil } from 'rxjs';
// Interface
import { APIRes, Invoice, InvPaymentLog } from 'src/app/Interfaces';
// Services
import { ApiServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';
// Local interface
interface InvPaymentLogApiResp extends APIRes {
	data?: InvPaymentLog[];
}

@Component({
	selector: 'bk-inv-payment-logs',
	templateUrl: './InvPaymentLogs.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InvPaymentLogsComponent implements OnInit {

	@Input() invId: string | number | null = null;
	@Input() cusumData: any;
	@Input() invData?: Invoice;
	@Input() invSec:any;
	paymentLogs: InvPaymentLog[] = [];
	loaderId: string = 'inv-payment-logs';

	constructor(public utilServ: UtilServ, private loader: LoaderServ,private apiServ: ApiServ, @Self() public destroy: NgOnDestroy) { }

	ngOnInit() {
		if(this.invId){
			this.invPaymentLogsApi();
		}
	}

	/**
	 * Fetches payment logs for the invoice and displays a loading indicator while the request is in progress.
	 */
	private invPaymentLogsApi(): void {
		this.loader.show(this.loaderId);
		this.apiServ.callApiWithPathQueryVars('GET', 'InvPaymentLogs', [this.invId], {'paid': true}).pipe(takeUntil(this.destroy)).subscribe((resp:InvPaymentLogApiResp)=>this.handleInvPayApiResp(resp));
	}

	/**
	 * Handles the response from the invoice payment logs API call.
	 * If the response is valid, it updates the payment logs.
	 * Hides the loader once the response is processed.
	 *
	 * @param resp - The response from the API call containing the payment logs data.
	 */
	private handleInvPayApiResp(resp: InvPaymentLogApiResp): void {
		if(this.apiServ.checkAPIRes(resp) && resp?.data){
			this.paymentLogs = resp.data;
		}
		this.loader.hide(this.loaderId);
	}
}
