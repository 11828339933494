
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
// Api service
import { ApiServ, InitServ, UtilServ } from 'src/app/Services';

@Injectable({
	providedIn: 'root'
})
export class ApiRespCacheServ {

	private destroy = new Subject<void>();
	private apiLoadStatus: { bkngCustData: boolean, liveReviews: boolean } = {
		bkngCustData: false,
		liveReviews: false
	};
	private reviewsSubject = new BehaviorSubject<any>(null); // Initial value null
	private bkngCustomzSubject= new BehaviorSubject<any>(null); // Initial value null

	constructor(private apiServ: ApiServ, private initServ: InitServ, private utilServ: UtilServ) { }

	/**
	 * Fetches live reviews with a specified limit.
	 * Ensures the API is called only once by checking the loading status.
	 * Uses `shareReplay(1)` to cache the response and `takeUntil(this.destroy)` to handle unsubscription.
	 * Updates the reviewsSubject with the fetched data.
	 */
	public getLiveReviewsApi(limit: number): Observable<any> {
		if (!this.apiLoadStatus.liveReviews) {
			this.apiLoadStatus.liveReviews = true;
			this.apiServ.callApiWithQueryParams('GET', 'Reviews', { limit }).pipe(takeUntil(this.destroy), shareReplay(1)).subscribe((resp:any)=>{
				let data = this.apiServ.checkAPIRes(resp) && resp?.data?.length > 0 ? resp.data : null;
				this.reviewsSubject.next(data);
			});
		}
		return this.reviewsSubject.asObservable();
	}

	public getBkngCustomzDataApi(): Observable<any> {
		if (!this.apiLoadStatus.bkngCustData) {
			this.apiLoadStatus.bkngCustData = true;
			let queryParams: any = { theme_slug: this.initServ.theme, language: this.initServ.savedLng, mode: 'live' };
			if (this.initServ.theme) {
				queryParams['mode'] = 'preview';
			}
			this.apiServ.callApiWithQueryParams('GET', 'BkngSummayCustomization', queryParams).pipe(takeUntil(this.destroy), shareReplay(1)).toPromise().then((resp:any)=>{
				let data = this.apiServ.checkAPIRes(resp) && resp?.data ? this.utilServ.buildBkngCustomz(resp.data) : null;
				this.bkngCustomzSubject.next(data);
			});
		}
		// console.log('hghjkghkgdghfj', this.bkngCustomzSubject.asObservable());
		return this.bkngCustomzSubject.asObservable();
	}
}
