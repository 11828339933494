import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, InitServ, UtilServ, SectionServ, SocialServ, ApiServ, LoaderServ } from '../../../../Services';

@Component({
	selector: 'bk-facebook-share',
	templateUrl: './FacebookShare.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class FacebookShareComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() pageSett: any;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	socialKeys: { [key: string]: string } = {}; // App social keys
	fbAppId: string = '';
	userToken: any = null;
	currentUser: any = null;
	fbShareStatus: boolean = false;
	sectionStatus: boolean = false;
	siteUrl: string = '';
	couponText: any= "<div><h5>Dont Miss Out!</h5></div><div><h2 class='font-weight-light'>Get 15% Off</h2></div><span>Share via a facebook to receive a coupon code</span>";
	loaderId: string = 'bkng-fb-coupon';
	fbCode: any;

	constructor(public initServ: InitServ, public utilServ: UtilServ, public secServ: SectionServ, public socialServ: SocialServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private loader: LoaderServ, private cDRef: ChangeDetectorRef) {
		this.currentUser = this.utilServ.appLocalStorage();
		this.userToken = this.currentUser?.token;
	}

	async ngOnInit(): Promise<void> {
		if(this.section){
			this.sectionStatus = this.secServ.checkEleStatus(this.pageSett, this.section.id);
		}
		// Facebook app id
		this.socialKeys = await this.initServ.loadSocialKeys();
		this.setFBShareData();
	}

	private setFBShareData(): void {
		if(this.socialKeys?.Facebook && this.sectionStatus){
			this.fbAppId = this.socialKeys.Facebook;
			this.socialServ.fbInit(this.socialKeys.Facebook);
			this.fbShareStatus = this.canShareOnFacebook();
			// Fb coupon text
			if(this.admnStngs?.merchant_settings?.bookings?.facebook_coupon_share_popup){
				this.couponText = this.admnStngs.merchant_settings.bookings.facebook_coupon_share_popup;
			}
			// Site url
			this.siteUrl = `${window.location.protocol}//${window.location.host}`;
		}
	}

	/**
	 * Check the share facebook settings
	 * @returns
	 */
	private canShareOnFacebook(): boolean {
		if(this.utilServ.appPermission('fbSharing')){
			if(this.admnStngs.merchant_settings?.bookings?.enable_fb_share_coupon && this.fbAppId){
				if(this.admnStngs.merchant_settings.bookings?.enable_fb_share_coupon_for == 'both'){
					return true;
				} else if(this.admnStngs.merchant_settings.bookings?.enable_fb_share_coupon_for == 'backend' && (this.userToken)){
					return true;
				} else if(this.admnStngs.merchant_settings.bookings?.enable_fb_share_coupon_for == 'frontend' && !this.currentUser){
					return true;
				} else{
					return false;
				}
			}
		}
		return false;
	}
	/**
	 * Get coupon
	 */
	public getFbCoupon(): void {
		this.fbCode = null
		this.loader.show(this.loaderId);
		this.apiServ.callApi('GET', 'FacebookCoupon').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.fbCode = res.data.code;
			setTimeout(() => {
				this.utilServ.togglePopup('fbCoupon');
			}, 100);
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}
}
