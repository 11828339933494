import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef, Self } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
// Services
import { LoaderServ, NgOnDestroy } from '../../../Services';

@Component({
	selector: 'bk-app-loader',
	template: `
		<div *ngIf="(!loaderServ?.hideLoaderOnAppLoad && loaderServ?.loaderIds && loaderServ?.loaderIds[loaderId]) || loading" class="tjs-loader--v2" [ngClass]="{'tjs-loader--wrap':loaderServ?.loaderIds['main']}">
			<p *ngIf="loaderText" [innerHTML]="loaderText | safeHtml"></p>
			<div class="loader position-relative">
				<div class="dot dot1"></div>
				<div class="dot dot2"></div>
				<div class="dot dot3"></div>
			</div>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AppLoaderComponent implements OnInit {
	// Variables
	@Input() loaderId: string = 'main';
	@Input() loaderText: string = '';
	@Input() isCalender: boolean = false;
	@Input() loading: boolean = false;

	constructor(private cDRef: ChangeDetectorRef, public loaderServ: LoaderServ,  @Self() private destroy: NgOnDestroy) { }

	ngOnInit(): void {
		if(this.shouldInitializeLoader()) {
			this.loaderServ.loaderIds[this.loaderId] = false;
		}
		this.loaderServ.loadingData.pipe(takeUntil(this.destroy)).subscribe((val: any) => {
			this.loaderServ.loaderIds[val.id] = val.status;
			this.cDRef.detectChanges();
		});
		this.cDRef.detectChanges();
	}

	private shouldInitializeLoader(): boolean {
		return this.loaderServ.loaderIds && this.loaderId && !(Object.prototype.hasOwnProperty.call(this.loaderServ.loaderIds, this.loaderId))
	}
}
