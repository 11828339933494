import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// External lib
import { takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// Serviecs
import { NgOnDestroy, InitServ, UtilServ, ApiServ, LoaderServ, SocialServ, CacheService } from '../../Services';

@Component({
	selector: 'bk-share-review-popup',
	templateUrl: './ShareReviewPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ShareReviewPopupComponent implements OnInit {
	// Variables
	booking: any;
	rating: any;
	socialKeys: { [key: string]: string } = {}; // App social keys
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	custStoreSett: any;
	fbStatus: boolean = false;
	twitterStatus: boolean = false;
	loaderId: string = 'share-review-loader';
	userMeta: any;
	stopSendingRefCredits: boolean = false;
	siteUrl: string = '';
	fbAppId: string = '';
	//
	review: any;
	isReadmore: boolean = true;
	isBlockVisible: boolean = false;
	// popup section variables
	slug: string = 'share_review';
	secId: string = '';
	section: any = { title: null, review: null, referrals: null, share_review: null }
	// short codes
	fbShortCode: string = '{{.FBShareRewards}}';
	tweetShortCode: string = '{{.TwitterShareRewards}}';
	singleShortCode: string = '{{.FBTwitterShareRewards}}';

	constructor(public dialogRef: MatDialogRef<ShareReviewPopupComponent>, public socialServ: SocialServ, @Self() private destroy: NgOnDestroy, private toastr: ToastrService, public initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, private cDRef: ChangeDetectorRef, private cacheServ: CacheService) {
		dialogRef.disableClose = true;
		// Customer store settings
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.customers) {
			this.custStoreSett = this.admnStngs.merchant_settings?.customers;
		}
		if (this.custStoreSett && this.custStoreSett?.post_automatic_rating && (this.custStoreSett.post_automatic_rating).length > 0) {
			if ((this.custStoreSett.post_automatic_rating).includes('facebook')) {
				this.fbStatus = true;
			}
			if ((this.custStoreSett.post_automatic_rating).includes('twitter')) {
				this.twitterStatus = true;
			}
		}
		// Site url
		this.siteUrl = window.location.protocol + "//" + window.location.host;
	}

	async ngOnInit(): Promise<void> {
		// set the boolean flag status to show the block if site exist.
		this.isBlockVisible = this.blockVisible();
		if (this.booking) {
			// Popup visibility
			if (!this.popupVisible()) { this.dialogRef.close(); }
			// Customer meta data
			this.userMetaData();
			// Build FB script
			this.socialKeys = await this.initServ.loadSocialKeys();
			if (this.socialKeys?.Facebook) {
				this.fbAppId = this.socialKeys.Facebook;
				this.socialServ.fbInit(this.socialKeys.Facebook);
			}
			// Build Twitter script
			this.socialServ.twitterInit();
			// get the rating review and update the status of boolean variable
			this.review = this.rating?.review;
			this.isReadmore = this.review && this.utilServ.reviewCount(this.review);
			if (this.isReadmore) { this.getReview(); }
			// build popup section
			this.buildSectionData();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section and doesnot pass "dialog reference as its set to disableClose to `true`".
		// if it is passed then loader will set the `disableClose` property to false.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			this.cDRef.detectChanges();
		});
	}
	/**
	 * Method checks the customer's review based on the minimum length and display the the review
	 * * if review length is more then min length then pre-defined length of review
	 * * else exact review of actual length
	 * @param {boolean} [isClicked=false] - boolean flag to set the call on click event only.
		*/
	public getReview(isClicked: boolean = false): void {
		if (isClicked) { this.isReadmore = false; }
		this.review = (this.isReadmore) ? this.utilServ.reviewDesc(this.rating?.review) : this.rating?.review;
		this.cDRef.detectChanges();
	}
	/**
	 * Convert token into valid/readable string
	 * @param content actual content
	 * @returns Dynamic text string
	 */
	public tokenReplace(content: string, token: string, twtToken: string = this.tweetShortCode): any {
		if (content) {
			let str = this.utilServ.generateDynamicStr(content, token, this.utilServ.amountWithCurrency(this.custStoreSett?.fb_share_reward ?? this.custStoreSett?.fb_twitter_share_reward ?? this.custStoreSett?.twitter_share_reward));
			return this.utilServ.generateDynamicStr(content, twtToken, this.utilServ.amountWithCurrency(this.custStoreSett?.twitter_share_reward), str);
		}
		return '';
	}
	/**
	 * User meta data
	 */
	private userMetaData(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		let queryParams = { role: 'customer', app_name:'customer' };
		this.apiServ.callApiWithPathQueryVars('GET', 'UserMeta', [this.booking.uid], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
	}
	/**
	 * Check the popup visibility
	 * @returns
	 */
	private popupVisible(): boolean {
		return (this.isBlockVisible || this.fbStatus || this.twitterStatus);
	}
	/**
	 * Check share review sites(link added by customer store option) visibility
	 * @returns
	 */
	public blockVisible(): boolean {
		if (this.custStoreSett && this.custStoreSett?.share_review_to_sites && (this.custStoreSett.share_review_to_sites).length > 0) {
			for (let site of this.custStoreSett.share_review_to_sites) {
				if (site.status && this.locationLink(site.locations, site.site)) {
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Site link
	 * @param locations
	 * @param site
	 * @returns
	 */
	public locationLink(locations: any, site: any = ''): any {
		let locId: number = this.checkShareReviewForLoc();
		if (site && this.userMeta && this.userMeta.data && this.userMeta.data.sites && this.userMeta.data.sites[site]) {
			if (this.userMeta.data.sites[site] && (this.userMeta.data.sites[site]).length > 0 && (this.userMeta.data.sites[site]).includes(locId)) {
				return null;
			}
		}
		return locations[locId];
	}

	/**
	 * Checks if the review can be shared for a specific location based on the booking's industry and form data.
	 * @returns {number} The base location ID if the review can be shared, or 0 otherwise.
	 */
	private checkShareReviewForLoc(): number {
		// Ensure industries data exists in the application; if not, return 0.
		if (!this.utilServ.checkArrLength(this.initServ.appData.industries)) return 0;
		// Find the industry that matches the booking's industry ID and has forms.
		let industry: any = this.initServ.appData.industries.find((ind: any) => ind.id === this.booking.industry_id && this.utilServ.checkArrLength(ind.forms));
		// If the industry exists, check for the relevant form.
		if (industry) {
			// Verify if a form exists for the booking's form ID and its location type is not 'no_location'.
			let formExists: any = industry.forms.some((form: any) => form.form_id === this.booking.form_id && form.location_type !== 'no_location'
			);
			// If the form exists, return the base location ID.
			if (formExists) { return this.booking.base_location_id; }
		}
		// If no matching industry or form is found, return 0.
		return 0;
	}

	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any, type: string = ''): void {
		if (type == 'share') {
			if (this.apiServ.checkAPIRes(res)) {
				this.toastr.success(res.message);
			} else {
				if (res && res.message) {
					this.toastr.error(res.message);
				}
			}
		} else {
			if (this.apiServ.checkAPIRes(res)) {
				this.userMeta = res.data;
				if (this.userMeta?.data?.stop_sending_ref_credits && this.userMeta.data.stop_sending_ref_credits == 'yes') {
					this.stopSendingRefCredits = true;
				}
			}
		}
		if (!this.popupVisible()) { this.dialogRef.close(); }
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}
	/**
	 * Method to copy the rating view of the customer by dynamically creating the input element
	 * and calling copyToClipboard() method to copy the text and remove the input element from the body.
	 */
	public copyReview(): void {
		const input: HTMLInputElement = document.createElement('input');
		input.setAttribute('id', 'customerReview');
		input.value = this.rating?.review;
		document.body.appendChild(input);
		this.utilServ.copyToClipboard('customerReview', 'review');
		setTimeout(() => input.remove(), 100);
	}
}
