import { Component, Input, OnInit, Output, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
// Services
import { ApiServ, BkngFormServ, InitServ, NgOnDestroy, SectionServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-discounts',
	templateUrl: './Discounts.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountsComponent implements OnInit {
	// Variables
	@Input() bookingType: any;
	@Input() section: any;
	@Input() discountForm! : FormGroup;
	@Input() referralDiscount: any;
	@Input() prefilledData: any;
	@Input() settings: any;
	@Input() selectedServiceType: any;
	@Input() isMultiStepForm: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Output() couponApply : EventEmitter<any> = new EventEmitter();
	@Output() resetCoupon : EventEmitter<any> = new EventEmitter();
	@Output() checkPreChargeIfCoupon : EventEmitter<any> = new EventEmitter();
	@Output() giftCardApply: EventEmitter<any> = new EventEmitter();
	@Output() resetGiftCard: EventEmitter<any> = new EventEmitter();
	@Output() resetReferralUser: EventEmitter<any> = new EventEmitter();
	@Output() referralApply: EventEmitter<any> = new EventEmitter();
	@Output() resetReferral: EventEmitter<any> = new EventEmitter();

	sectionStatus: boolean = false;
	activeTab: string = 'coupon';
	couponData: any;
	coupon: any = {
		isCode: false,
		code: '',
		withGiftCard: 0,
		withReferral: 0,
		type: 'code',
		amount: 0,
		flag: false,
		successMsg: '',
		errorType:null,
		errorMsg:''
	}
	giftCardData: any;
	giftCard: any = {
		code: null,
		discount: 0,
		flag: false,
		successMsg: '',
		errorMsg: ''
	}
	referral: any = {
		display: false,
		total: 0,
		amount: null,
		flag: false,
		successMsg: '',
		errorMsg: ''
	}
	giftCardPerm: boolean = true;
	referralPerm: boolean = true;
	isDisabledCoupon: any;
	isDisabled: any;
	referralBalanceLabel: string = '';
	settingsParams: any = {
		frequencies: {}
	}
	codeValue: any;
	giftCardCode: any;
	referralValue: any;
	isChange: boolean = false;
	couponGroupValue: any;
	disabledPages: any;

	constructor(public initServ: InitServ, @Self() private destroy: NgOnDestroy, public utilServ: UtilServ, private secServ: SectionServ, private apiServ: ApiServ, private cDRef: ChangeDetectorRef, private bkngFormServ: BkngFormServ) {
		this.giftCardPerm = this.utilServ.appPermission('giftCards'); // App permission
		this.referralPerm = this.utilServ.appPermission('referrals'); // App permission
		if(this.initServ.siteData && this.initServ.siteData.disabled_pages && (this.initServ.siteData.disabled_pages).length > 0){
			this.disabledPages = this.initServ.siteData.disabled_pages;
		}
	}

	ngOnInit(): void {
		// Build coupon
		this.buildCoupon();
		// Prefilled giftcard
		let giftCardGroup: any = this.discountForm.controls['gift_card'];
		if(giftCardGroup.controls['code'].value){
			let giftCardCode = giftCardGroup.controls['code'].value;
			this.giftCard.code = giftCardCode;
			this.giftCardCode = this.giftCard.code;
			this.giftCard.discount = giftCardGroup.controls['discount'].value;
			this.prefilledGiftCard();
		}
		// Prefilled referral discount
		if(this.discountForm.controls['referral_amount'].value){
			this.referral.amount = this.discountForm.controls['referral_amount'].value;
			this.referralValue = this.referral.amount;
			this.prefilledReferral();
		}
		// Section status
		if(this.prefilledData && ((this.prefilledData.coupon && this.prefilledData.coupon.discount) || (this.prefilledData.gift_card && this.prefilledData.gift_card.discount) || (this.prefilledData.referral_amount) )){
			this.sectionStatus = true;
		} else {
			if(this.section && this.secServ.checkEleStatus(this.pageSett,this.section.id)){
				this.sectionStatus = true;
			}
		}
		// Disabled sections
		this.isDisabledCoupon = this.disabledParamScope('coupon');
		this.isDisabled = this.disabledParamScope();
		this.initServ.userRefBal.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.buildReferral(value);
			}
		});
		// Frequencies array convert into object
		if(this.settings && this.settings.frequencies && (this.settings.frequencies).length > 0){
			for(let freq of this.settings.frequencies){
				this.settingsParams.frequencies[freq.form_frequency_data.id] = freq;
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * ngOnChanges is not detect the changes, then ngDoCheck works
	 * Coupon apply and remove by event, build function is not working
	 */
	ngDoCheck(): void {
		if(!this.isChange){
			let cur = JSON.stringify(this.couponGroup.value);
			let prev = JSON.stringify(this.couponGroupValue);
			if(cur != prev){
				this.buildCoupon();
			}
		}
	}
	// convenience getter for easy access to form fields
	get couponGroup(): any{
		return <FormGroup>this.discountForm.controls['coupon'];
	}
	get giftCardGroup(): any {
		return <FormGroup>this.discountForm.controls['gift_card'];
	}
	/**
	 * Check the form parameters are disabled or not
	 * @param controlName : coupon
	 * @returns
	 */
	// eslint-disable-next-line complexity
	public disabledParamScope(controlName: string=''): string | null {
		if(this.bookingType == 'reschedule'){
			if(this.prefilledData && (this.prefilledData.status == 1 || this.prefilledData.status == 2 || this.prefilledData.status == 4 || this.isCustomerAllowedRes == false)){
				return "disabled";
			} else{
				if(controlName == 'coupon'){
					if(this.selectedServiceType && this.selectedServiceType.can_customer_edit == "yes"){
						return null;
					} else{
						return "disabled";
					}
				} else{
					return null;
				}
			}
		}
		return null;
	}

	// Coupon functions
	/**
	 * Build coupon
	 */
	private buildCoupon(): void {
		this.couponGroupValue = this.couponGroup.value;
		if(this.couponGroup.controls['code'].value && this.couponGroup.controls['id'].value){
			this.coupon.isCode = true;
			this.coupon.withGiftCard = this.couponGroup.controls['applicable_with_gift_card'].value;
			this.coupon.withReferral = this.couponGroup.controls['applicable_with_referral'].value;
			this.coupon.code = this.couponGroup.controls['code'].value;
			this.codeValue = this.coupon.code;
			this.prefilledCoupon();
		} else if(this.couponGroup.controls['discount'].value){
			if(this.couponGroup.controls['discount_type'].value == 'amount'){
				this.coupon.type = 'amount';
				let amount = this.couponGroup.controls['discount'].value;
				this.coupon.code = this.utilServ.currencyCodeForInput() + (+amount).toFixed(2)+ ' discount applied';
				this.codeValue = this.coupon.code;
				this.coupon.amount = amount;
				this.prefilledCoupon();
			} else{
				this.coupon.type = 'percentage';
				let amount = this.couponGroup.controls['discount'].value;
				this.coupon.code = amount+'% discount applied';
				this.codeValue = this.coupon.code;
				this.coupon.amount = amount;
				this.prefilledCoupon();
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Prefilled coupon
	 */
	private prefilledCoupon(): void {
		this.coupon.flag = true;
		this.coupon.successMsg = 'Discount successfully applied.';
		this.coupon.errorType = null
		this.coupon.errorMsg = '';
		this.couponApply.emit(false);
	}
	/**
	 * Get coupon code and get the api res
	 * @param code: coupon code
	 * @param msgType: min/max
	 */
	public applyCoupon(type: any, code: any, msgType: string =''): void {
		this.isChange = true;
		this.coupon.flag = true;
		this.coupon.successMsg = '';
		this.coupon.errorType = null
		this.coupon.errorMsg = '';
		if(type == 'code'){
			let extraData: any = { msgType : msgType};
			// Set query parameters
			let queryParams: any = {
				code: code,
				language: this.initServ.savedLng,
				user_id: this.utilServ.userId() ? this.utilServ.userId() : 0,
				bid: this.prefilledData ? this.prefilledData._id : 0,
				rec_id: (this.prefilledData && this.prefilledData.recurring_id) ? this.prefilledData.recurring_id : 0
			}
			// API call
			this.apiServ.callApiWithQueryParams('GET', 'Coupon', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'coupon', extraData));
		} else if(type == 'percentage'){
			if((+code) > 100){
				this.coupon.flag = false;
				this.coupon.errorMsg = 'Your coupon percentage exceed the maximum limit 100.';
				let obj = {
					msgType : msgType,
					showMsgOnCouponRemove : false
				}
				this.resetCoupon.emit(obj);
			} else{
				this.couponGroup.patchValue({
					discount_type: type,
					discount: code
				});
				this.coupon.successMsg = "Discount successfully applied.";
				this.couponApply.emit(true);
			}
			this.cDRef.detectChanges();
		} else{
			let serviceTotalAmount = +this.discountForm.controls['total_before_coupon_discount'].value;
			if((+code) > serviceTotalAmount){
				this.coupon.flag = false;
				this.coupon.errorMsg = 'Your coupon amount exceed the maximum limit.';
				let obj = {
					msgType : msgType,
					showMsgOnCouponRemove : false
				}
				this.resetCoupon.emit(obj);
			} else{
				this.couponGroup.patchValue({
					discount_type: type,
					discount: code
				});
				this.coupon.success = "Discount successfully applied.";
				this.couponApply.emit(true);
			}
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Get the apply coupon api success data
	 * @param res: api res
	 * @param msgType: min/max
	 */
	private applyCouponSuccess(res: any, msgType: any): void {
		this.couponData = res.data;
		if(this.couponData){
			let couponCheckRes = this.bkngFormServ.checkCoupon(this.couponData, this.discountForm.value, this.settingsParams, this.prefilledData);
			if(couponCheckRes){
				if(couponCheckRes[0]){
					this.coupon.withGiftCard = this.couponData.allow_with_gift_cards;
					this.coupon.withReferral = this.couponData.allow_with_referrals;
					let referralCreditFlag = this.referralCreditFlag();
					if((!this.coupon.withGiftCard && this.giftCard.flag) || (!this.coupon.withReferral && (this.referral.flag || referralCreditFlag))){
						this.applyCouponElsePart(couponCheckRes, msgType, true);
					}else{
						this.coupon.isCode = true;
						this.couponGroup.patchValue(this.bkngFormServ.fillCouponValues(this.couponData));
						this.coupon.successMsg = 'Discount successfully applied.';
						this.couponApply.emit(true);
						this.checkPreChargeIfCoupon.emit(this.couponData);
					}
				} else {
					this.applyCouponElsePart(couponCheckRes, msgType);
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Check if booking has referral credit applied or not
	 * @returns
	 */
	private referralCreditFlag(){
		if(this.discountForm.value['referral_discount'] && +this.discountForm.value['referral_discount'] > 0){
			return true;
		}
		return false;
	}
	private applyCouponElsePart(couponCheckRes: any, msgType: string, flag: boolean = false){
		this.coupon.errorType = null;
		let showMsgOnCouponRemove: boolean = (this.couponData.show_msg_on_remove_coupon && this.couponData.show_msg_on_remove_coupon == 'yes') ? true : false;
		this.coupon.errorMsg = couponCheckRes[1];
		if(couponCheckRes[2]){
			this.coupon.errorType = couponCheckRes[2];
			msgType = couponCheckRes[2];
		}
		let obj = {
			msgType : msgType,
			showMsgOnCouponRemove : showMsgOnCouponRemove
		}
		if(flag){
			this.coupon.errorMsg = this.msgForCouponNotCombined();
			obj = { msgType : msgType, showMsgOnCouponRemove : showMsgOnCouponRemove}
		}
		this.coupon.flag = false;
		this.coupon.withGiftCard = null;
		this.coupon.withReferral = null;
		this.coupon.isCode = false;
		this.resetCoupon.emit(obj);
		this.checkPreChargeIfCoupon.emit(null);
	}
	private msgForCouponNotCombined(){
		if(!this.coupon.withGiftCard && this.giftCard.flag){
			return 'Coupon code and gift card cannot be combined.';
		}else {
			return 'Coupon code and referral cannot be combined.';
		}
	}
	/**
	 * Remove coupon
	 * Reset the all values
	 */
	public removeCoupon(): void {
		this.isChange = true;
		this.coupon.flag = false;
		this.coupon.successMsg = '';
		this.coupon.errorType = null;
		this.coupon.errorMsg = '';
		this.coupon.withGiftCard = null;
		this.coupon.withReferral = null;
		this.coupon.isCode = false;
		// Coupon control value reset
		this.couponGroup.patchValue({
			code: null,
			discount: null,
			provider_discount: null,
			provider_discount_type: null,
			id: null,
			applicable_with_gift_card: null,
			applicable_with_referral: null,
			recurring: null,
			half_discount: null,
			apply_on_bookings: null,
			min_order: null,
			max_order: null,
			prepay_rec_booking: null,
			prepay_bookings_count: null
		});

		this.couponApply.emit(true);
		this.checkPreChargeIfCoupon.emit(null);
		this.cDRef.detectChanges();
	}

	// Gift card functions
	/**
	 * Prefilled gift card
	 */
	private prefilledGiftCard(): void {
		let giftCardGroup: any = this.discountForm.controls['gift_card'];
		this.giftCard.flag = true;
		this.giftCard.errorMsg = '';
		this.giftCard.successMsg = 'Discount successfully applied.';
		let value = { amount: giftCardGroup.value['discount'], status: false};
		this.giftCardApply.emit(value);
		this.cDRef.detectChanges();
	}
	/**
	 * Apply Gift card
	 * @param code: gift card code
	 * @param msgType:
	 */
	public applyGiftCard(code: any, msgType: any = null): any {
		if((<FormGroup>this.discountForm.controls['customer']).controls['email_id'].value){
			this.giftCard.flag = true;
			this.giftCard.successMsg = '';
			this.giftCard.errorMsg = '';
			if(this.coupon.isCode){
				if(this.coupon.withGiftCard){
					this.getGiftCardByCode(code, msgType);
				} else{
					this.giftCard.flag = false;
					this.giftCard.errorMsg = 'Coupon code and gift card cannot be combined.';
					this.resetGiftCard.emit(msgType);
					this.cDRef.detectChanges();
				}
			} else{
				this.getGiftCardByCode(code, msgType);
			}
		} else {
			this.giftCard.flag = false;
			this.giftCard.errorMsg = 'Gift card is not available for this customer.';
			this.resetGiftCard.emit(msgType);
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Get the gift card data based on code
	 * @param code: code
	 * @param msgType: amount
	 */
	public getGiftCardByCode(code: any, msgType: any): any {
		let extraData: any = {msgType: msgType};
		this.apiServ.callApiWithQueryParams('GET', 'GiftCard', {code: code}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'giftCard', extraData));
	}
	/**
	 * Get the apply gift card api success data
	 * @param giftCard: gift card
	 * @param msgType: amount
	 */
	// eslint-disable-next-line complexity
	private applyGiftCardSuccess(giftCard: any, msgType: any): void {
		if(giftCard.recipient_email == (<FormGroup>this.discountForm.controls['customer']).controls['email_id'].value){
			// Check used amount
			let usedAmount: any = 0;
			if(['add', 'draft'].includes(this.bookingType) && this.giftCard.code && giftCard.code == this.giftCard.code){
				usedAmount = +giftCard.used_amount;
			} else if(this.bookingType == 'reschedule' && this.prefilledData && this.prefilledData.gift_card.code && giftCard.code == this.prefilledData.gift_card.code){
				if(+this.prefilledData.gift_card.discount > (+giftCard.used_amount)){
					usedAmount = 0;
				} else {
					usedAmount = (+giftCard.used_amount) - (+this.prefilledData.gift_card.discount);
				}
			} else{
				usedAmount = +giftCard.used_amount;
			}
			// Remain amount
			let remainTotal: any = giftCard.amount - usedAmount;
			if(remainTotal > 0){
				this.giftCardGroup.patchValue({
					id: giftCard.id,
					code: giftCard.code,
					gift_card_total: giftCard.amount,
					previously_used: giftCard.used_amount,
					status: true,
				});
				this.giftCard.successMsg = 'Discount successfully applied.';
				let value = { amount: remainTotal, status: true};
				this.giftCardApply.emit(value);
			} else{
				this.giftCard.flag = false;
				this.giftCard.errorMsg = 'Gift card has already been redeemed.';
				this.resetGiftCard.emit(msgType);
			}
		} else{
			this.giftCard.flag = false;
			this.giftCard.errorMsg = 'Gift card is not available for this customer.';
			this.resetGiftCard.emit(msgType);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Remove gift card
	 * Reset the all values
	 */
	public removeGiftCard(): void {
		this.giftCard.flag = false;
		this.giftCard.successMsg = '';
		this.giftCard.errorMsg = '';

		this.giftCardGroup.patchValue({
			id: null,
			code: null,
			gift_card_total: null,
			previously_used: null,
			discount: null,
			status: null,
		});
		let value = { amount: null, status: true};
		this.giftCardApply.emit(value);
		this.cDRef.detectChanges();
	}

	// Referral functions
	/**
	 * Build the referral
	 * @param ref : referral object
	 */
	private buildReferral(ref: any): void {
		let customerId: any = this.discountForm.controls['uid'].value;
		this.resetReferralUser.emit(customerId);
		let balance: any = this.utilServ.roundToTwo(ref.balance);
		// Check if referral is already applied then we will not show the referral tab.
		if(!(this.discountForm.controls['referral_discount'].value && this.discountForm.controls['referral_discount'].value > 0)){
			this.referral.total = balance;
			if(['add', 'draft'].includes(this.bookingType)){
				if(+this.discountForm.controls['referral_amount'].value){
					this.referral.total = this.utilServ.roundToTwo(balance - (+this.discountForm.controls['referral_amount'].value));
				}
			} else {
				if(this.prefilledData && this.prefilledData.referral_amount && !this.discountForm.controls['referral_amount'].value){
					this.referral.total = this.utilServ.roundToTwo(balance + this.prefilledData.referral_amount);
				}
			}
		}
		if(this.referral.total > 0){
			this.referral.display = true;
		} else{
			this.referral.display = false;
		}
		// Replace referral token with relevant value
		this.setReferralBalanceLabel();
		this.cDRef.detectChanges();
	}
	/**
	 * Set the referral balance label.
	 */
	public setReferralBalanceLabel(){
		// Replace referral token with relevant value
		if(this.section && this.section.referral_balance_label){
			this.referralBalanceLabel = this.utilServ.generateDynamicStr(this.section.referral_balance_label, '{{.ReferrarBalance}}', this.utilServ.amountWithCurrency(this.referral.total));
		}
	}
	/**
	 * Prefilled referral
	 */
	private prefilledReferral(): void {
		this.referral.flag = true;
		this.referral.errorMsg = '';
		this.referral.successMsg = 'Discount successfully applied.';
		this.referralApply.emit();
		this.cDRef.detectChanges();
	}
	/**
	 * Apply referral amount
	 * @param amount: referral amount
	 */
	public applyReferral(amount: any): void {
		this.referral.flag = false;
		this.referral.successMsg = '';
		this.referral.errorMsg = '';
		let newVal : any = Number(amount);
		if(!isNaN(+newVal)){
			// Check referral with coupon code
			if(this.coupon.isCode){
				if(this.coupon.withReferral){
					this.checkReferralAmount(amount);
				} else{
					this.referral.flag = false;
					this.referral.errorMsg = 'Coupon code and referral cannot be combined.'
					this.resetReferral.emit();
					this.cDRef.detectChanges();
				}
			} else{
				setTimeout(() => {
					this.checkReferralAmount(amount);
				},0)
			}
		}else{
			this.referral.flag = false;
			this.referral.errorMsg = 'Your referral amount is not valid';
			this.resetReferral.emit();
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Check the referral amount with service toltal and referral total(prefilled data)
	 * @param amount: referral amount
	 */
	private checkReferralAmount(amount: any): any {
		this.referral.flag = true;
		this.referral.successMsg = '';
		let serviceTotalAmount = +this.discountForm.controls['final_amount'].value;
		// Referral amount check with service toltal and referral total(prefilled data)
		if((+amount) > serviceTotalAmount){
			this.referral.flag = false;
			this.referral.errorMsg = 'Your referral amount exceed the maximum limit.';
			this.resetReferral.emit();
		} else if((+amount) > this.referral.total){
			this.referral.flag = false;
			this.referral.errorMsg = 'Your referral amount exceed the total referral amount.';
			this.resetReferral.emit();
		} else{
			this.discountForm.controls['referral_amount'].setValue(amount);
			this.referral.successMsg = 'Discount successfully applied.';
			this.referral.total = this.referral.total - (+this.discountForm.controls['referral_amount'].value);
			this.referral.total = this.utilServ.roundToTwo(this.referral.total);
			// Replace referral token with relevant value
			this.setReferralBalanceLabel();
			this.referralApply.emit();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Remove referral
	 */
	public removeReferral(): void {
		this.referral.flag = false;
		this.referral.successMsg = '';
		this.referral.errorMsg = '';
		this.referral.total = this.referral.total + (+this.discountForm.controls['referral_amount'].value);
		this.referral.total = this.utilServ.roundToTwo(this.referral.total);
		// Replace referral token with relevant value
		this.setReferralBalanceLabel();
		this.discountForm.controls['referral_amount'].setValue(null);
		this.referralApply.emit(null);
		this.cDRef.detectChanges();
	}

	/**
	 * On result callback method
	 * @param res api
	 * @param type coupon
	 * @param extraData
	 * API response handler
	 */
	private onResultCallback(res:any, type: string, extraData: any): void {
		switch(type){
			case 'coupon':
				if(this.apiServ.checkAPIRes(res)){
					this.applyCouponSuccess(res, extraData.msgType);
				} else {
					this.coupon.flag = false;
					this.coupon.withGiftCard = null;
					this.coupon.withReferral = null;
					this.coupon.isCode = false;
					this.coupon.errorType = null;
					this.coupon.errorMsg = res.data;
					let obj = {
						msgType : extraData.msgType,
						showMsgOnCouponRemove : false
					}
					this.resetCoupon.emit(obj);
					this.checkPreChargeIfCoupon.emit(null);
				}
			break;
			case 'giftCard':
				if(this.apiServ.checkAPIRes(res)){
					this.giftCardData = res.data;
					this.applyGiftCardSuccess(this.giftCardData, extraData.msgType);
				} else {
					this.giftCard.flag = false;
					this.giftCard.errorMsg = res.message;
					this.resetGiftCard.emit(extraData.msgType);
				}
			break;
		}
		this.cDRef.detectChanges();
	}
}