import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
// External library
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, UtilServ, ApiServ, LoaderServ, InitServ, PopupServ, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-notifications',
	templateUrl: './Notifications.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class NotificationsComponent implements OnInit {
	// Variables
	sectionPerm: any = { email: true, sms: true, app: true }
	loaderId: string = 'notif-loader';
	loaderIds: any = {
		email: 'email-notif-loader',
		sms: 'sms-notif-loader',
		// app: 'app-notif-loader'
	}
	notifData: any = { email: null, sms: null, app: null };
	notif: any;
	notifStatus: any = {
		email: {
			booking_reminder: true,
			rate_us: true,
			booking_modified: true,
			referral_accepted: true,
			booking_cancellation: true,
			fees_charged: true,
			booking_charged: true,
			create_invoice: true,
			update_invoice: true
		},
		sms: {
			booking_reminder: true,
			sms_notifications: true,
			create_invoice: true,
			update_invoice: true
		}
	}
	currentUser: any;
	// customer section build
	secId: any;
	section: any = { card: null, email_title: null, sms_title: null, notifications: null };
	secBuildData: any[] = [
		{
			slug: "bk_email_notification",
			notifications: [
				{ slug: "booking_reminder", title: "Booking reminder", type: "email", tooltip_slug: "bkng_reminder", status_slug: "booking_reminder" },
				{ slug: "feedback_email", title: "Feedback/Surveys emails", type: "email", tooltip_slug: "feedback_email", status_slug: "rate_us" },
				{ slug: "booking_modified", title: "Modified bookings", type: "email", tooltip_slug: "bkng_modified", status_slug: "booking_modified" },
				{ slug: "referral_accepted", title: "Referral accepted", type: "email", tooltip_slug: "referral_accepted", status_slug: "referral_accepted" },
				{ slug: "booking_cancellation", title: "Cancellation", type: "email", tooltip_slug: "bkng_cancellation", status_slug: "booking_cancellation" },
				{ slug: "charged_fee", title: "Charged fees", type: "email", tooltip_slug: "charged_fee", status_slug: "fees_charged" },
				{ slug: "create_invoice", title: "New invoice", type: "email", tooltip_slug: "create_invoice", status_slug: "create_invoice" },
				{ slug: "update_invoice", title: "Update invoice", type: "email", tooltip_slug: "update_invoice", status_slug: "update_invoice" }
			]
		},
		{
			slug: "bk_sms_notification",
			notifications: [
				{ slug: "booking_reminder", title: "Booking reminder", type: "sms", tooltip_slug: "bkng_sms_reminder", status_slug: "booking_reminder" },
				{ slug: "sms_notifications", title: "SMS notifications", type: "sms", tooltip_slug: "sms_notifications", status_slug: "sms_notifications" },
				{ slug: "create_invoice", title: "New invoice", type: "sms", tooltip_slug: "sms_create_invoice", status_slug: "create_invoice" },
				{ slug: "update_invoice", title: "Update invoice", type: "sms", tooltip_slug: "sms_update_invoice", status_slug: "update_invoice" }
			]
		}
	];

	// eslint-disable-next-line max-params
	constructor(private cDRef: ChangeDetectorRef, public utilServ: UtilServ, private router: Router, private apiServ: ApiServ, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, public initServ: InitServ, private popupServ: PopupServ, private toastr: ToastrService, public rcServ: RenderComponentServ, public secServ: SectionServ) {
		// Section permissions
		this.sectionPerm.email = this.utilServ.appPermission('emailNotif');
		this.sectionPerm.sms = this.utilServ.appPermission('smsNotif');
		this.sectionPerm.app = this.utilServ.appPermission('appNotif');
	}

	ngOnInit(): void {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// Called api's
		if (this.sectionPerm.email || this.sectionPerm.sms || this.sectionPerm.app) {
			this.getUserNotif();
			if (this.sectionPerm.email) {
				this.appNotif('email');
			}
			if (this.sectionPerm.sms) {
				this.appNotif('sms');
			}
		} else {
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
		}
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			let pageSett: any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
		// build notification section settings fields
		this.buildData();
	}
	/**
	 * User notifications
	 */
	private getUserNotif(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		this.apiServ.callApiWithPathVariables('GET', 'UserNotif', [this.utilServ.userId()]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'user'));
	}
	/**
	 * App notifications
	 * @param type
	 */
	private appNotif(type: string): void {
		let apiName: string = '';
		switch (type) {
			case 'email':
				apiName = 'EmailNotif';
				break;
			case 'sms':
				apiName = 'SmsNotif';
				break;
		}
		this.apiServ.setLoaderId(this.loaderIds[type]);
		this.loader.show(this.loaderIds[type]);
		this.apiServ.callApiWithQueryParams('GET', apiName, { type: 'customers' }).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, type));
	}
	/**
	 * Confirmation popup for enabled/disabled notification
	 * @param slug notification slug
	 * @param status true/false
	 * @param type email/sms/app
	 */
	public confirmPopup(slug: string, status: boolean, type: string = 'email'): void {
		let data: any = { slug: slug, status: status, type: type };
		let popupType: string = status ? 'disable_notification' : 'enable_notification';
		this.popupServ.confirmPopup(popupType).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res, data));
	}
	/**
	 * On popup result callback method
	 * @param res popup res
	 * @param data use for api
	 * Popup response handler
	 */
	public popupCallback(res: any, data: any = null): void {
		if (res) {
			this.loader.show(this.loaderId);
			let sendData: any = { customer_id: +this.utilServ.userId(), field_key: data.slug, field_value: !data.status, type: data.type };
			this.apiServ.callApiWithPathVariables('PUT', 'UserNotif', [this.utilServ.userId()], sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'status', data));
		}
	}
	/**
	 * Build the notification status true/false
	 * @param slug notification slug
	 * @param type email/sms/app
	 * @returns
	 */
	public buildNotifStatus(type: string = 'email'): any {
		if (this.notifData && this.notifData[type] && (this.notifData[type]).length > 0) {
			for (let notif of this.notifData[type]) {
				if (this.notifStatus[type][notif.slug]) {
					if (notif.status) {
						this.notifStatus[type][notif.slug] = true;
					} else {
						this.notifStatus[type][notif.slug] = false;
					}
				}
			}
		}
	}
	/**
	 * On result callback method
	 * @param res
	 * @param type user/status/default
	 * API response handler
	 */
	// eslint-disable-next-line complexity
	private onResultCallback(res: any, type: string, extraData: any = null): void {
		switch (type) {
			case 'user':
				if (this.apiServ.checkAPIRes(res) && res.data) {
					this.notif = res.data;
					// set default notification status for invoice
					this.setDefaultNotif('email');
					this.setDefaultNotif('sms');
				}
				this.loader.hide(this.loaderId);
				break;
			case 'status':
				this.toastr.clear();
				if (this.apiServ.checkAPIRes(res)) {
					this.toastr.success(res.message);
					this.getUserNotif();
				} else {
					if (res && res.message) {
						this.toastr.error(res.message);
					}
					this.loader.hide(this.loaderId);
				}
				if (extraData && extraData.slug && extraData.slug == 'sms_notifications') {
					if (this.currentUser && this.currentUser.role == 'customer') {
						// Store the current loggedIn user all information
						this.initServ.loggedInUser(this.currentUser.id);
					}
				}
				break;
			default:
				if (this.apiServ.checkAPIRes(res)) {
					this.notifData[type] = res.data;
				}
				this.buildNotifStatus(type);
				this.loader.hide(this.loaderIds[type]);
				break;
		}
		this.cDRef.detectChanges();
	}
	/**
	 * The function builds data by assigning IDs and tooltip content to notifications based on their
	 * tooltip slugs.
	 */
	private buildData(): void {
		if (this.secBuildData && this.secBuildData.length > 0) {
			for (let data of this.secBuildData) {
				// set 'data_section' based on slug type.
				data.section = (data.slug == 'bk_email_notification') ? this.section.email_title : this.section.sms_title;
				// set tooltip content & its id to notification fields.
				for (let notification of data.notifications) {
					notification.id = this.section.notifications?.[`${notification.tooltip_slug}_id`];
					notification.tooltip_content = this.section.notifications?.[notification.tooltip_slug]?.tooltip;
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * The function checks various conditions based on the `notification` object and returns a boolean value.
	 * @param {any} notif - An object having the notification's slug, tooltip, title, status_slug & type
	 * @returns a boolean value.
	 */
	public checkNotifFields(notif:any): any{
		if(notif.slug == 'charged_fee' || notif.slug == 'feedback_email' || notif.slug == 'referral_accepted'){
			if(notif.slug == 'feedback_email'){
				return this.utilServ.appPermission('rating') && this.notifStatus[notif.type][notif.status_slug];
			}
			else if(notif.slug == 'referral_accepted'){
				return (this.utilServ.appPermission('referrals') && this.notifStatus[notif.type][notif.status_slug]);
			}
			else {
				return (this.notifStatus[notif.type]['booking_charged'] || this.notifStatus[notif.type]['fees_charged']);
			}
		} else{
			return this.notifStatus[notif.type][notif.status_slug];
		}
	}

	/**
	 * Set the default status for notifications for invoice in emails and sms.
	 * @param type
	 */
	public setDefaultNotif(type:string){
		if(typeof(this.notif[type].create_invoice) == 'undefined'){
			this.notif[type].create_invoice = true;
		}
		if(typeof(this.notif[type].update_invoice) == 'undefined'){
			this.notif[type].update_invoice = true;
		}
	}
}
