/* eslint-disable max-depth */
import { Component, OnInit, ViewEncapsulation, Input, Self, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// Services
import { ApiServ, BkngFormServ, InitServ, LoaderServ, NgOnDestroy, RenderComponentServ, SectionServ, UtilServ, LeadsServ } from '../../Services';
import { AddBkngServ } from '../../AddBookings';
// Constants
import { EMAIL_REG_EXP } from '../../Constants';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';

@Component({
	selector: 'bk-bkng-short-form',
	templateUrl: './BkngShortForm.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BkngShortFormComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	@Input() dialogRef: any;
	pageSett: any;
	secSett: any;
	parentPageSett: any;
	formMetaData: any;
	formSett: any;
	sectionData: any;
	bkngPageSett: any;
	// Section fields
	section: any = {
		title:null,
		sub_title:null,
		cookie_content: null,
		media:null,
		button:null,
		continue_btn:null,
		back_btn:null,
		find_avail_btn:null,
		email_placeholder:null,
		industry:null,
		location: null,
		service: null,
		frequency: null,
		pricing_params: null,
		extras: null,
		packages: null,
		addons: null,
		area_params: null,
		email: null,
		summary: null,
		hourly_params: null
	}
	loaderId: string = 'short-form';
	industries: any;
	multiIndusStatus: boolean = false;
	industrySlug : any;
	selectedIndustryId: any;
	industryData:any;
	selectedFormId: any;
	selectedCombination: any;
	emailAddrForm!: FormGroup;
	userInfo: any;
	stepperLoader: string = 'step-form';
	isPrevStepExist: boolean = true;
	isFormChange: boolean = true;
	// For v3 and v4
	customization: any = {
		section: null,
		form: null,
		json: null
	}
	isShortFormBuild: boolean = false; // For v3 and v4
	bkngCustData: any;
	sectionIds: any = {
		title:'',
		sub_title:'',
		label:''
	}
	extraStatus: boolean = false;
	mlDesignId: string = 'bk_ml_loc_v1';
	reBuildFormSelection: boolean = true;

	constructor(private utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, private addBkngServ: AddBkngServ, public secServ: SectionServ, public rcServ: RenderComponentServ, public frmBldr: FormBuilder, public initServ: InitServ, private customValidators: CustomValidators, private bkngFormServ: BkngFormServ, public router: Router, private cDRef: ChangeDetectorRef, private leadsServ: LeadsServ) {
		this.userInfo = this.initServ.userInfo;
		this.initServ.loadBkngCustData();
		// Multi industry
		let multiIndus: any = this.utilServ.multiIndustriesStatus();
		this.multiIndusStatus = multiIndus?.multiIndus;
		this.industries = multiIndus?.industries;
		if(this.industries && (this.industries).length > 0){
			this.selectedIndustryId = this.industries[0].id;
			this.industrySlug = this.industries[0].industry_slug;
		}
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		this.buildFormSections();
	}

	/**
	 * Build the form sections
	 */
	// eslint-disable-next-line complexity
	private buildFormSections(): void {
		if(this.secId && this.sectionData){
			this.parentPageSett = this.sectionData.settings;
			this.pageSett = this.sectionData.section_settings; // page settings
			this.secSett = this.pageSett[this.secId]; // section settings
			// Popup position
			if(this.popupId && this.dialogRef){
				this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.sectionData.settings, this.dialogRef);
			}
			if(this.secSett && this.secSett.form_settings){
				this.formSett = this.secSett.form_settings; // Form settings
			}
			if(this.sectionData.meta_data){
				this.formSett = this.sectionData.meta_data; // Form meta data
			}
			// Set the multi step header and footer status
			let headerOnMulti: boolean = false;
			let footerOnMulti: boolean = false;
			if(this.formSett){
				if(this.formSett.hide_header_on_multi_step && this.formSett.hide_header_on_multi_step == 'no'){
					headerOnMulti = true;
				}
				if(this.formSett.hide_footer_on_multi_step && this.formSett.hide_footer_on_multi_step == 'no'){
					footerOnMulti = true;
				}
			}
			this.utilServ.setMultiStepHeaderFooter(headerOnMulti, footerOnMulti);
			// Set the section all settings under the section service
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			if(!this.initServ.apiLoadStatus['bkngCustData'] && this.secSett.variation_id == 'bk_short_form_V3' && this.secSett.variation_id == 'bk_short_form_V4'){
				this.initServ.loadBkngCustData().then(() => {
					this.bkngCustData = this.initServ.bkngCustData; // Booking custom data
					this.buildSectionFields(this.sectionData, this.secId);
				});
			}else{
				this.bkngCustData = this.initServ.bkngCustData; // Booking custom data
				this.buildSectionFields(this.sectionData, this.secId);
			}
			if(this.secSett){
				switch(this.secSett.variation_id){
					case 'bk_short_form_V2':
						this.emailAddrForm = this.frmBldr.group({
							email_id: [(this.userInfo ? this.userInfo.email_id : null), [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
						});
					break;
					case 'bk_short_form_V3':
					case 'bk_short_form_V4':
						// Get the shor form json data
						this.getShortFormJson();
						// Industry form settings
						this.getIndustryFormSett();
					break;
				}
			}
		}
	}
	/**
	 * Build the blog sections fields
	 */
	// eslint-disable-next-line complexity
	private buildSectionFields(secData:any,secId: any, type: any='section'): void{
		let secElem : any = null;
		if(secData.sections){
			secElem = secData.sections[secId];
		}
		let sections: any = {};
		if(secElem){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "media":
							sections[key] =  this.secServ.buildMedia(secElem[key]);
							break;
						case 'button':
						case 'continue_btn':
						case 'back_btn':
						case 'find_avail_btn':
							sections[key] = this.secServ.buildButton(secElem[key]);
							break
						case "industry":
						case "card_charged_after":
						case "location":
						case "service":
						case "pricing_params":
						case "packages":
						case "addons":
						case "area_params":
						case "frequency":
						case "extras":
						case "hourly_params":
						case "summary":
							sections[key] = this.secServ.buildGroup(secElem[key]);
							break;
						case "email":
							sections[key] = this.secServ.buildGroup(secElem[key]);
							if(secData.section_settings[secElem[key]]?.elements?.email_errors){
								sections[key]['email_errors'] = this.secServ.buildGroup(secData.section_settings[secElem[key]]?.elements?.email_errors);
							}
							break;
						case "cookie_content":
						case "title":
						case "sub_title":
						case "email_placeholder":
							sections[key] = this.secServ.buildText(secElem[key]);
							break;
						default:
							sections[key] = this.secServ.checkElementStatus(secElem[key]) && secElem[key];
							break;
					}
				}
			}
			this.setDataBasedOnVariation(secData, type, sections);
		}
	}
	private setDataBasedOnVariation(secData: any, type:any, sections: any): void {
		switch(this.secSett.variation_id){
			case 'bk_short_form_V3':
			case 'bk_short_form_V4':
				this.customization[type]=sections;
				if(type == 'section'){
					this.setSectionTypeData(sections);
				}
				if(type == 'form'){
					this.setFormTypeData(secData, sections);
				}
			break;
			default:
				this.section = sections;
				break;
		}
	}
	private setSectionTypeData(sections: any): void {
		if(sections && sections.industry){
			this.sectionIds['title']=sections?.industry?.title_id;
			this.sectionIds['sub_title']=sections?.industry?.sub_title_id;
			this.sectionIds['label']=sections?.industry?.industry_id;
		}
	}
	private setFormTypeData(secData: any, sections: any): void {
		this.bkngPageSett = secData.section_settings;
		this.setMlDesignId(sections);
		if(sections && sections?.extras && sections?.extras.id){
			this.extraStatus = this.secServ.checkEleStatus(this.bkngPageSett, sections?.extras.id);
		}
		this.setFormSummaryData();
		this.buildSectionForV3V4();
	}
	private setMlDesignId(sections: any): void {
		if(sections && sections.location && sections.location?.id && this.bkngPageSett && this.bkngPageSett[sections.location?.id] && this.bkngPageSett[sections.location?.id]['design_id']){
			this.mlDesignId = this.bkngPageSett[sections.location?.id]['design_id'];
		}
	}
	private setFormSummaryData(): void {
		if(this.selectedIndustryId && this.selectedFormId){
			let indFormId: any = this.selectedIndustryId + '_' + this.selectedFormId;
			if(this.bkngCustData && indFormId && this.utilServ.objHasProp(this.bkngCustData, indFormId) && this.bkngCustData[indFormId]){
				this.customization['form']['summary']= this.bkngCustData[indFormId];
			}
		}
	}
	/**
	 * Check the user email exist
	 * @param control: email control
	 */
	public async emailExistControl(control: any) {
		if(control && control.value && (!control.errors?.required && !control.errors?.pattern)){
			let email: any = await this.customValidators.emailExist(control.value, this.utilServ.userId());
			// control.setErrors(email);
			if(!email){
				// Add email to Mail chimp and campaign contact list
				let data: any = {
					email_id: this.emailAddrForm.controls['email_id'].value,
					industry_id: this.selectedIndustryId
				}
				this.addBkngServ.addEmailToMailChimp(null, data);
				this.addBkngServ.addContactToCampaign(null, data);
				let leadsData: any = {
					industry_id: this.selectedIndustryId,
					customer: {
						email_id: this.emailAddrForm.controls['email_id'].value
					}
				}
				this.leadsServ.addContactToLead(leadsData, this.getShortFormType(), 2000);
			}
		}
	}
	/**
	 * Retrieves the type and slug for a short form based on the current context.
	 * If the popupId exists, it returns type 'popup_short_form', otherwise 'theme_builder_short_form'.
	 * Slug is generated by appending the section_id to the type.
	 * @returns {{type: string, slug: string}} - An object containing the type and slug for the short form.
	 */
	public getShortFormType(): {type: string, slug: string} {
		let type: string = this.popupId ? 'popup_short_form' : 'theme_builder_short_form';
		return {
			type: type,
			slug: type+'_'+this.secSett?.section_id
		}
	}
	/**
	 * Submit email form
	 */
	public submitForm(): void {
		this.loader.show(this.stepperLoader);
		setTimeout(()=>{
			this.emailAddrForm.controls['email_id'].markAsTouched();
			if(this.emailAddrForm.controls['email_id'].valid){
				if(this.multiIndusStatus){
					let formStepEl = document.querySelectorAll('.step');
					if(formStepEl && formStepEl.length > 0){
						formStepEl.forEach(element => element.classList.remove('active-step'));
					}
					let stepTwoEl = document.getElementById('email-step-two');
					if(stepTwoEl){
						stepTwoEl.classList.add('active-step');
					}
				} else{
					this.bkngFormServ.setSelectedEmailForm = this.emailAddrForm.controls['email_id'].value;
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']])
				}
			}
			this.loader.hide(this.stepperLoader);
		},500);
	}
	/**
	 * Back to email section
	 */
	public backToEmail(): void {
		this.loader.show(this.stepperLoader);
		let formStepEl = document.querySelectorAll('.step');
		if(formStepEl && formStepEl.length > 0){
			formStepEl.forEach(element => element.classList.remove('active-step'));
		}
		let stepOneEl = document.getElementById('email-step-one');
		if(stepOneEl){
			stepOneEl.classList.add('active-step');
		}
		this.loader.hide(this.stepperLoader);
	}
	/**
	 * Industry change
	 * @param event
	 */
	public industryChange(event: any): void {
		let industrySlug = event.target.value;
		this.industrySlug = industrySlug;
		this.selectedIndustryId = null;
		this.selectedFormId = null;
		if(this.industries && (this.industries).length > 0){
			for(let industry of this.industries){
				if(industry.industry_slug == industrySlug){
					this.selectedIndustryId = industry.id;
					if(this.secSett.variation_id == 'bk_short_form_V3' || this.secSett.variation_id == 'bk_short_form_V4'){
						// Industry form settings
						this.getIndustryFormSett();
					}
					break;
				}
			}
		}
	}
	/**
	 * GO to booking form
	 */
	public goToBooknow(): void{
		this.bkngFormServ.setSelectedEmailForm = this.emailAddrForm.controls['email_id'].value;
		if(this.industrySlug){
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']+'/'+this.industrySlug])
		} else{
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']]);
		}
	}
	/**
	 * Get the industry form settings
	 */
	private getIndustryFormSett(): void {
		this.reBuildFormSelection = false;
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		this.apiServ.callApiWithPathVariables('GET', 'IndustryFormSett', [this.selectedIndustryId]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.industryFormSettRes(res));
	}
	private industryFormSettRes(res: any): any {
		if(this.apiServ.checkAPIRes(res)){
			if(res.data){
				this.industryData = res.data;
				this.bkngFormServ.setIndustryFormSett = this.industryData;
				let sett: any = this.addBkngServ.getCombinationForm();
				this.selectedFormId = sett.formId;
				this.selectedCombination = sett.combination;
				this.isPrevStepExist = (!this.multiIndusStatus && !((this.industryData && this.industryData.combined_combination  && (this.industryData.combined_combination).length > 0) || (this.industryData && this.industryData.single_combination  && (this.industryData.single_combination).length > 0)));
				// Get the single industry and form based customization
				this.getSingleFormCustomization();
			}
			this.reBuildFormSelection = true;
		}
	}
	// Variation 3 functions
	/**
	 * build the section data based on type
	 * @param secData: json and form data
	 * @param type: json/form
	 */
	private setSectionData(secData: any, type: string): void {
		if(secData && secData.sections && (Object.keys(secData.sections)).length > 0){
			let sections = Object.keys(secData.sections);
			let secId = sections[0];
			secData = this.setSummaryData(secData, secId);
			let pageSett = secData.section_settings; // page settings
			// Set the section all settings under the section service
			this.secServ.setServData(pageSett, secData.content);
			this.buildSectionFields(secData, secId, type);
		}
	}
	setSummaryData(secData: any, secId: any): any {
		if(this.selectedIndustryId && this.selectedFormId){
			let id: any = this.selectedIndustryId+'_'+this.selectedFormId;
			if(this.initServ.bkngSummData && this.initServ.bkngSummData[id]){
				let summData: any = this.initServ.bkngSummData[id];
				let summSecId: any = Object.keys(this.initServ.bkngSummData[id].sections)[0];
				secData.sections[secId]['summary'] = summSecId;
				secData.section_settings[summSecId] = {
					type: "form_group",
					status: true,
					mobile_status: true,
					elements: summData.sections[summSecId]
				}
				secData.section_settings = {
					...summData.section_settings,
					...secData.section_settings
				}
				secData.content = {
					...summData.content,
					...secData.content
				}
				secData.style = {
					...summData.style,
					...secData.style
				}
				if(secData.section_settings[summSecId].elements.total){
					secData.section_settings[secData.section_settings[summSecId].elements.total].type = 'text';
					this.pageSett[secData.section_settings[summSecId].elements.total] = secData.section_settings[secData.section_settings[summSecId].elements.total];
				}
				if(secData.section_settings[summSecId].elements.first_apt_total){
					secData.section_settings[secData.section_settings[summSecId].elements.first_apt_total].type = 'text';
				}
				if(secData.section_settings[summSecId].elements.total_before_tax){
					secData.section_settings[secData.section_settings[summSecId].elements.total_before_tax].type = 'text';
					this.pageSett[secData.section_settings[summSecId].elements.total_before_tax] = secData.section_settings[secData.section_settings[summSecId].elements.total_before_tax];
				}
			}
		}
		return secData;
	}
	/**
	 * Get the short form json data
	 */
	private getShortFormJson(): void {
		this.loader.show(this.loaderId);
		const header = {'auth' : 'false'};
		this.apiServ.callApi('GET', 'ShortFormJson', null, header).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.shortFormJsonRes(res));
	}
	private shortFormJsonRes(res: any): void {
		if(this.apiServ.checkAPIRes(res)){
			if(res.data && this.secSett && this.secSett.variation_id && res.data[this.secSett.variation_id]){
				let shortFormJson = res.data[this.secSett.variation_id];
				this.setSectionData(shortFormJson, 'json');
			}
		}
	}
	/**
	 * Get the single industry and form based customization
	 */
	private getSingleFormCustomization(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		// Query params
		let queryParams: any = {language:this.initServ.savedLng, industry: this.selectedIndustryId, form: this.selectedFormId, mode: 'live', theme_slug: this.initServ.theme};
		if(this.initServ.theme){
			queryParams['mode']='preview';
		}
		this.apiServ.callApiWithQueryParams('GET', 'BkngFormCustomization', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.bkngFormCustomizationRes(res));
	}
	private bkngFormCustomizationRes(res: any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.setSectionData(res.data, 'form');
		}
	}
	/**
	 * Next step
	 * @param event
	 */
	public nextStep(event: any): void {
		this.loader.show(this.stepperLoader);
		if(event){
			let formStepEl = document.querySelectorAll('.step');
			if(formStepEl && formStepEl.length > 0){
				formStepEl.forEach(element => element.classList.remove('active-step'));
			}
			let par = event.target.parentNode.parentNode;
			let el = par.nextElementSibling;
			el.className += ' active-step';
		}
		this.bkngFormServ.setCombinationData = this.selectedCombination;
		this.utilServ.scrollToSpecificEle(this.secId);
		this.loader.hide(this.stepperLoader);
	}
	/**
	 * Previous step
	 * @param event
	 */
	public prevStep(event: any): void {
		this.loader.show(this.stepperLoader);
		if(event){
			let formStepEl = document.querySelectorAll('.step');
			if(formStepEl && formStepEl.length > 0){
				formStepEl.forEach(element => element.classList.remove('active-step'));
			}
			let par = event.target.parentNode.parentNode;
			let el = par.previousElementSibling;
			el.className += ' active-step';
		}
		this.loader.hide(this.stepperLoader);
	}
	/**
	 * Combination option change build the page again
	 * @param option combination option
	 * @param type group/single group: Combine a form and combination && single: Combine 2 forms
	 */
	public combineOptionChange(option: any, type:string = 'group'): void {
		this.isFormChange = false;
		this.bkngFormServ.resetShortFormData();
		this.utilServ.resetWidgetFormData();
		// this.show Pre-qualifier = true;
		if(type == 'group'){
			this.selectedCombination = null;
		}
		if(type == 'group' && option.combination_id) {
			if(this.industryData.single_combination && (this.industryData.single_combination).length > 0){
				for(let combination of this.industryData.single_combination){
					if(combination.id == option.combination_id){
						this.selectedCombination = combination;
						this.selectedFormId = this.selectedCombination.prequalifier_data[0].form_id;
					}
				}
			}
		} else{
			this.selectedFormId = option.form_id;
		}
		setTimeout(() => {
			// Get the single industry and form based customization
			this.getSingleFormCustomization();
			this.isFormChange = true;
		}, 100);
	}
	/**
	 * Checked the combination default check is case of group only
	 * group: Combine a form and combination
	 * @param option combination option
	 * @returns
	 */
	public combineDefaultChecked(option: any): boolean | null {
		if(option.combination_id){
			if(this.industryData.single_combination && (this.industryData.single_combination).length > 0){
				for(let combination of this.industryData.single_combination){
					if(combination.id == option.combination_id){
						// eslint-disable-next-line max-depth
						if((combination.form_ids).includes(+this.selectedFormId)){
							return true;
						}
					}
				}
			}
		} else if(+this.selectedFormId == +option.form_id){
			return true;
		}
		return null;
	}
	/**
	 * Build the section data for variation 3 and variation 4
	 */
	// eslint-disable-next-line complexity
	private buildSectionForV3V4(): void {
		let jsonData: any = this.customization['json'];
		let formData: any = this.customization['form'];
		let replaceKeys: any = ['media', 'title', 'sub_title', 'industry', 'continue_btn', 'back_btn'];
		jsonData = {
			...jsonData,
			...formData
		}
		let sectionData: any = this.customization['section'];
		// Short form popup case if sub title field is not exist in section data, do not replace the string data from json
		if(this.popupId){
			if(this.sectionData && this.sectionData.sections && this.sectionData.sections[this.secId] && !this.utilServ.objHasProp(this.sectionData.sections[this.secId], 'sub_title')){
				replaceKeys = replaceKeys.filter(function(item: any) {
					return item !== 'sub_title';
				})
			}
		}
		if(replaceKeys && replaceKeys.length > 0){
			for(let key of replaceKeys){
				jsonData[key] = sectionData[key];
			}
		}
		this.section = jsonData;
		if(this.section){
			if(this.section.email){
				this.section.email['title']='We are almost done!';
			}
			if(!this.section.industry){
				this.section.industry = this.customization['json']['industry'];
			}
			if(!this.section.continue_btn){
				this.section.continue_btn = this.customization['json']['continue_btn'];
			}
			if(!this.section.back_btn){
				this.section.back_btn = this.customization['json']['back_btn'];
			}
		}
		this.isShortFormBuild = true;
		this.cDRef.detectChanges();
	}
}
