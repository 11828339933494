import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, from, switchMap, of } from 'rxjs';
import { getSessionHeaders, generateSessionHeaders } from 'iron-crypto-pkg';
import { ALLOW_OLD_VERSION } from 'src/app/Constants';
import { InitServ } from 'src/app/Services';
import { environment as env } from 'src/environments/environment';
import { CacheService } from 'src/app/Cache/cache.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(private initServ: InitServ, private cacheService: CacheService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let currentUser: any;
		const auth: string | null = request.headers.get('auth') ? request.headers.get('auth') : 'true';

		try {
			let userJson: any = localStorage.getItem("currentUser");
			currentUser = userJson !== null ? JSON.parse(userJson) : null;
		} catch { /* empty */ }

		request = this.constructRequestHeaders(auth, request, currentUser);

		if (auth !== 'false') {
			return this.refreshHeaders(request, next);
		} else {
			return this.handleCache(request, next);
		}
	}

	/**
	* Constructs request headers based on authentication status and current user data.
	*/
	private constructRequestHeaders(auth: string | null, request: HttpRequest<any>, currentUser: any) {
		if (auth === 'false') {
			return request.clone({
				headers: request.headers.delete('auth')
			});
		} else if (currentUser?.token && currentUser?.Ip) {
				return request.clone({
					setHeaders: {
						Authorization: `Bearer ${currentUser.token}`,
						Ip: currentUser.Ip
					}
				});
		} else if (this.initServ?.ipAddress) {
			return request.clone({
				setHeaders: {
					Ip: this.initServ.ipAddress
				}
			});
		}
		return request;
	}

	/**
	* Handles caching logic and retrieves data from IndexedDB if available.
	*/
	private handleCache(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.cacheService.isCacheable(request.urlWithParams)) {
			return from(this.cacheService.get(request.urlWithParams)).pipe(
				switchMap((cachedResponse: HttpResponse<any> | null) => {
					if (cachedResponse) {
						return of(cachedResponse);
					} else {
						return next.handle(request).pipe(
							switchMap(response => {
								if (response instanceof HttpResponse) {
									this.cacheService.set(request.urlWithParams, response);
								}
								return of(response);
							})
						);
					}
				})
			);
		} else {
			return next.handle(request);
		}
	}

	/**
	* Refresh headers before making requests to ensure session authentication.
	*/
	private refreshHeaders(request: HttpRequest<any>, next: HttpHandler) {
		return from(this.getSessionHeaders()).pipe(
			switchMap((headers: any) => this.updateHeadersAndRetry(request, next, headers))
		);
	}

	/**
	* Updates request headers with session authentication and retries the request.
	*/
	private updateHeadersAndRetry(request: HttpRequest<any>, next: HttpHandler, headers: any): Observable<HttpEvent<any>> {
		if (this.initServ.csrfToken) {
			headers['X-CSRF-Token'] = this.initServ.csrfToken;
		}
		if (window.self !== window.top) {
			headers['X-Frame-Request'] = 'true';
		}
		request = request.clone({
			setHeaders: headers
		});

		return this.handleCache(request, next);
	}

	/**
	* Generates session authentication headers.
	*/
	private async getSessionHeaders() {
		if (ALLOW_OLD_VERSION) {
			return await getSessionHeaders(
				env.arjun + env.bhishma + env.chanakya + env.drona,
				env.astonmartin + env.bentley
			);
		} else {
			let params = {
				domainName: this.initServ.getDomainName(),
				timeDiff: this.initServ.timestampTimeDiff,
				appName: `customer-32-${this.initServ.timestampTimeDiff}`
			};
			return await generateSessionHeaders(
				env.audi + env.buick + env.chevrolet + env.dodge,
				env.abhimanyu + env.bharata + env.chandra + env.devika,
				params
			);
		}
	}
}