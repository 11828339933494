<ng-container *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && settings">
	<div [id]="secId" class="tjs-blog py-80">
		<!-- Search form -->
		<div class="container">
			<div class="col-12 col-md-12 col-lg-4 ms-auto mb-25 ps-lg-20 ps-0 pe-0">
				<form [formGroup]="searchForm" (ngSubmit)="submitForm()">
					<div class="tjs-search-group flex-nowrap position-relative">
						<input type="text" class="form-control border-0 pe-60 fs-18" placeholder="{{initServ?.appStr?.text?.SearchByTitle | translate}}" formControlName="search">
						<button class="btn bg-white d-flex align-items-center h-100 position-absolute top-0 end-0 ps-20 pe-15" type="submit"><i class="tjsicon-search"></i></button>
					</div>
				</form>
			</div>
		</div>
		<!-- Blog banner -->
		<div class="mb-70" [id]="section?.single_post" *ngIf="section?.single_post && blogs && blogs.length > 0">
			<div class="container">
				<!-- Image -->
				<div class="tjs-blog__banner-img mb-25">
					<img loading="auto" class="img-fluid tjs-object--cover" [src]="blogs[0]?.image_url ? (utilServ.getImgUrl(blogs[0]?.image_url)) : (initServ?.img?.Empty)" width="1280" height="768" alt="Blog Image">
				</div>
				<!-- Content -->
				<ng-container *ngTemplateOutlet="blogContentTemp; context:{blog: blogs[0]}"></ng-container>
			</div>
		</div>
		<!-- Blog grid -->
		<div class="tjs-blog__grid">
			<div class="container position-relative">
				<ng-container *ngIf="blogs && blogs.length > 0; else noBlog">
					<div class="row">
						<!-- Blogs -->
						<div *ngIf="section?.blog_list" [id]="section?.blog_list" class="col-12 col-sm-12 col-md-12 col-lg-8">
							<div class="row">
								<div class="col-12 col-sm-12 col-md-6" *ngFor="let blog of blogs;trackBy: secServ.trackByFnIndex">
									<div class="mb-40">
										<!-- Image -->
										<div class="tjs-blog-img mb-20">
											<a [routerLink]="['/',slug,blog?.seo?.url]">
												<img loading="auto" class="img-fluid tjs-object--cover" [src]="blog?.image_url ? (utilServ.getImgUrl(blog?.image_url)) : (initServ?.img?.Empty)" width="640" height="480" alt="blog">
											</a>
										</div>
										<!-- Content -->
										<ng-container *ngTemplateOutlet="blogContentTemp; context:{blog: blog, isComment: true}"></ng-container>
									</div>
								</div>
							</div>
						</div>
						<!-- Side bar -->
						<div class="col-12 col-sm-12 col-md-12 col-lg-4">
							<bk-blog-sidebar [settings]="settings" [slug]="slug" [section]="section" [blogs]="recentBlogs" [pageSett]="pageSett" ></bk-blog-sidebar>
						</div>
					</div>
					<!-- Pagination -->
					<bk-pagination *ngIf="totalRecords>limit" [total]="totalRecords" [limit]="limit" [page]="page" (callback)="pageChange($event)"></bk-pagination>
				</ng-container>
				<ng-template #noBlog>
					<div class="tjs-blog__not-found text-center tjs-py-140">
						<div class="mb-30"><i class="tjsicon-information text-danger"></i></div>
						<h3 class="fw-bold" translate>{{initServ?.appStr?.text?.noBlogFound}}</h3>
					</div>
				</ng-template>
				<!-- Loader -->
				<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
			</div>
		</div>
	</div>
</ng-container>

<!-- Blog content template -->
<ng-template #blogContentTemp  let-blog="blog" let-isComment="isComment" >
	<div>
		<div class="tjs-blog__meta mb-5" *ngIf="(blog?.date_time && !settings?.hide_date) || (blog?.author_data?.name) || (blog?.comment_count && isComment)">
			<ul class="list-unstyled d-flex flex-wrap mb-0">
				<!-- Date -->
				<li class="me-15 mb-5" *ngIf="blog?.date_time && !settings?.hide_date">
					<div class="d-flex align-items-center fs-14">
						<i class="tjsicon-calendar-1 pe-10 bk-item-desc"></i>
						<bk-date-display class="d-inline-block bk-item-desc" [date]="blog?.date_time" [isLocal]="true"></bk-date-display>
					</div>
				</li>
				<!-- Name -->
				<li class="me-15 mb-5" *ngIf="blog?.author_data?.name">
					<div class="d-flex align-items-center fs-14">
						<i class="tjsicon-user-2 pe-10 bk-item-desc"></i>
						<a class="text-capitalize text-body bk-item-desc" [routerLink]="['/author',slug, blog?.author_data?.slug]">{{blog?.author_data?.name}}</a>
					</div>
				</li>
				<!-- Comment -->
				<li class="me-15 mb-5" *ngIf="blog?.comment_count && isComment">
					<div class="d-flex align-items-center fs-14">
						<i class="tjsicon-chat pe-10 bk-item-desc"></i>
						<span class="bk-item-desc">{{blog?.comment_count}} {{initServ?.appStr?.text?.comment | translate}}</span>
					</div>
				</li>
			</ul>
		</div>
		<!-- Title -->
		<div *ngIf="blog?.title">
			<h3 class="fw-bold mb-0">
				<a [routerLink]="['/',slug,blog?.seo?.url]" [innerHTML]="blog?.title | safeHtml" class="text-dark bk-item-title tjs-title-hover"></a>
			</h3>
		</div>
	</div>
</ng-template>
