import { Component, Input, OnInit, ViewEncapsulation, Self} from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, SectionServ, RenderComponentServ, ApiServ, InitServ, UtilServ, LoaderServ, LeadsServ } from '../../../Services';
// Constants
import { EMAIL_REG_EXP, TEXT_REG_EXP } from '../../../Constants';


@Component({
	selector: 'bk-contact-us-popup',
	templateUrl: './ContactUsPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ContactUsPopupComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: any;
	popupData: any;
	pageSett: any;
	// Section fields
	section: any = {
		map: null,
		form: null,
		media: null
	}
	contactUsForm: FormGroup;
	loaderId: string = 'contact-us-popup';
	// loadMap: boolean = false;
	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<ContactUsPopupComponent>,public secServ: SectionServ, public rcServ: RenderComponentServ, private frmBldr: FormBuilder, private apiServ: ApiServ, private toastr: ToastrService, @Self() private destroy: NgOnDestroy, private utilServ: UtilServ, public initServ: InitServ, private loader: LoaderServ, private leadsServ: LeadsServ) {
		if(this.utilServ){
			this.utilServ.loadGoogleApiScript();
		}
		this.contactUsForm = this.frmBldr.group({
			name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			email: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
			message: [null]
		});
	}

	ngOnInit(): void {
		if(this.secId && this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.popupData = this.rcServ.popupData[this.popupId];
			this.buildSection();
		}
		// setTimeout(() => {
		// 	this.loadMap= true;
		// }, 1000);
	}
	ngAfterViewInit() {
		// this.loadMap= true;
		if(this.pageSett[this.secId].variation_id == 'bk_contact_us_V1' && this.section?.map){
			this.setMap();
		}
	}
	/**
	 * Sets the map URL based on page settings and section configuration.
	 */
	private setMap(): void {
		// Initialize map URL with default coordinates
		let url: any = "//maps.google.com/maps?q=7&z=15&output=embed";
		// Check if page settings and section map configuration exist
		if(this.pageSett && this.section && this.section.map && this.section.map.address_id && this.pageSett[this.section.map.address_id] && this.pageSett[this.section.map.address_id].coordinates && this.pageSett[this.section.map.address_id].coordinates.lat && this.pageSett[this.section.map.address_id].coordinates.lng){
			// If coordinates are available, update map URL with the coordinates
			url = "//maps.google.com/maps?q="+this.pageSett[this.section.map.address_id].coordinates.lat+","+this.pageSett[this.section.map.address_id].coordinates.lng+"&z=10&output=embed";
		}
		// Set the map canvas source with the updated URL
		this.setGmapCanvasSrc(url);
	}
	/**
	 * Sets the source URL for the Google Maps canvas.
	 * @param url The URL to set as the source for the Google Maps canvas.
	 */
	private setGmapCanvasSrc(url: string): void {
		// Get the Google Maps canvas element by its ID
		let elem: any = document.getElementById('gmap_canvas');
		// Check if the canvas element exists
		if (elem) {
			// If the canvas element exists, set its source attribute to the provided URL
			elem.setAttribute('src', url);
		}
	}

	// convenience getter for easy access to form fields
	get f() : { [key: string]: AbstractControl } {
		return this.contactUsForm.controls;
	}
	/**
	 * Builds the section based on popup data and section settings.
	 */
	private buildSection(): void {
		// Set page settings from popup data
		this.pageSett = this.popupData.section_settings;
		// Position the popup based on variation ID, popup settings, and dialog reference
		this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.popupData.settings, this.dialogRef);
		// Set service data for the section
		this.secServ.setServData(this.pageSett, this.popupData.content);
		// Initialize section element
		let secElem : any = null;
		// Check if popup data contains sections
		if(this.popupData.sections){
			// Get the section element by section ID
			secElem = this.popupData.sections[this.secId];
		}
		// Check if section element exists and if status type is defined in page settings
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			this.processElems(secElem);
		}
		this.setMsgValidations();
	}
	/**
	 * Processes elements of the section based on provided section element data.
	 * @param secElem The section element data to be processed.
	 */
	private processElems(secElem: any): void {
		// Iterate through each key in the section
		for (let key in this.section) {
			// Check if the section element contains data for the current key
			if (secElem[key]) {
				// Process the section element based on its type
				switch (key) {
					case "form":
						// Build a form element
						this.section[key] = this.secServ.buildForm(secElem[key]);
						break;
					case "media":
						// Build a media element
						this.section[key] = this.secServ.buildMedia(secElem[key]);
						break;
					case "map":
						// Build a group element (possibly a map)
						this.section[key] = this.secServ.buildGroup(secElem[key]);
						break;
					default:
						// Build a text element
						this.section[key] = this.secServ.buildText(secElem[key]);
						break;
				}
			}
		}
	}

	/**
	 * Sets validation rules for the message field in the contact us form based on section settings.
	 */
	private setMsgValidations(): void {
		// Check if the variation ID of the current section matches a specific value
		if (this.pageSett[this.secId].variation_id == 'bk_contact_us_V2') {
			// If the variation ID matches, set the 'message' field in the contact us form to be required
			// this.contactUsForm.controls['message'].setValidators([Validators.required]);
		} else {
			// If the variation ID does not match, clear any validators set for the 'message' field
			this.contactUsForm.controls['message'].clearValidators();
		}

		// Update the validity of the 'message' field in the contact us form
		this.contactUsForm.controls['message'].updateValueAndValidity();
	}

	/**
	 * Handles the focus out event for the details section.
	 * This function checks the validation status of the email form control.
	 * If the email is valid, it adds contact information to a lead.
	 */
	public detailsFocusOut(interval: number | null = null){
		if(!this.f['email']?.errors?.required && !this.f['email']?.errors?.pattern){
			let sendData: any = this.contactUsForm.value;
			sendData['email'] = (sendData['email']).toLowerCase();
			this.leadsServ.addContactToLead(this.contactUsForm, {type: 'popup', slug: `popup_${this.popupId}`}, interval);
		}
	}
	/**
	 * Submit form
	 */
	public submitForm(): void {
		if(this.contactUsForm.valid){
			this.loader.show(this.loaderId);
			this.detailsFocusOut(1);
			let sendData: any = this.contactUsForm.value;
			sendData['email'] = (sendData['email']).toLowerCase();
			// Active theme
			let theme: string = this.initServ.theme ? this.initServ.theme : (this.initServ.appAdmnStngs && this.initServ.appAdmnStngs.active_theme) ? this.initServ.appAdmnStngs.active_theme : '';
			this.apiServ.callApiWithQueryParams('POST', 'FormData', {theme_slug:theme}, sendData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		} else {
			for(let i in this.contactUsForm.controls) {
				this.contactUsForm.controls[i].markAsTouched();
			}
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.contactUsForm.patchValue({name: null,email: null,message: null});
			this.contactUsForm.markAsUntouched();
			this.toastr.success(res.message);
		} else {
			if(res && res.message){
				this.toastr.error(res.message);
			}
		}
		this.loader.hide(this.loaderId);
		this.dialogRef.close();
	}
}
