import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable,forkJoin } from 'rxjs';
// External library
import { TranslateLoader } from '@ngx-translate/core';
// Services
import { APIURL, InitServ } from './index';
// Constants
import { API_NAMES } from '../Constants';

export class CustomTranslationLoader implements TranslateLoader {

	constructor(private http: HttpClient,private APIURL: APIURL, private initServ: InitServ) {}
	/**
	 * Get the translation language.
	 * @param lang : code
	 * @returns : Observable
	 */
	public getTranslation(lang: string): Observable<any> {
		const header = new HttpHeaders({'auth' : 'false'});
		const localEnJson = this.APIURL.assetsBase+API_NAMES.EnglishJson;
		if(this.translateFileExist(lang)){
			return forkJoin([this.callAPI('default',lang),this.callAPI('checklist',lang), this.callInvAPI(lang)]).pipe(map((result: any) =>{
				let defaultStr = result[0];
				let ckStr = result?.[1];
				let invStr = result?.[2];
				if(ckStr && (Object.keys(ckStr).length > 0)){
					defaultStr = {...defaultStr,...ckStr};
				}
				if(invStr && (Object.keys(invStr).length > 0)){
					defaultStr = {...defaultStr,...invStr};
				}
				return this.process(defaultStr);
			}),catchError(()=> this.http.get(localEnJson, {headers: header}).pipe(map((result: object) => this.process(result)))));
		} else {
			return this.http.get(localEnJson, {headers: header}).pipe(map((result: object) => this.process(result)));
		}
	}

	/**
	 * Called api: checklist/default
	 * @param type
	 * @returns api res
	 */
	private callAPI(type: string = 'default',lang:string = 'en'): Observable<any> {
		const domainName: any = this.initServ?.appAdmnStngs?.merchant_settings?.store?.domain_name ? this.initServ?.appAdmnStngs?.merchant_settings?.store?.domain_name : this.APIURL.domainName;
		const header = new HttpHeaders({'auth' : 'false'});
		const rendNo = Math.floor(Math.random() * 1000000000);
		let apiURL = this.APIURL.fileCdn + '/translation_files/' + domainName+"/"+lang+'.json?v='+rendNo;
		if(type == 'checklist'){
			apiURL = this.APIURL.fileCdn + '/translation_files/' + domainName+'/checklist/'+lang+'.json?v='+rendNo;
			return this.http.get(apiURL, {headers: header}).pipe(map((result: object) => result),catchError(async ()=> ({})))
		} else {
			const localEnJson = this.APIURL.assetsBase+API_NAMES.EnglishJson;
			return this.http.get(apiURL, {headers: header}).pipe(map((result: object) => result),
			catchError(() => this.http.get(localEnJson, {headers: header}).pipe(map((result: object) => result))))
		}
	}

	private callInvAPI(lang:string = 'en'): Observable<any> {
		let apiURL = this.APIURL.invoiceUrl + 'translations?code='+lang;
		return this.http.get(apiURL).pipe(map((result: any) => {
			let res: any = result?.response;
			if(res && res.api_status == 1 && res?.data?.translation){
				return res?.data?.translation;
			}
			return null;
		}),catchError(async ()=> ({})));
	}

	/**
	 * Translate file exist or not
	 * @param lang : code
	 * @returns boolean
	 */
	private translateFileExist(lang: string): boolean{
		if(this.initServ.appLanguages && this.initServ.appLanguages.length > 0){
			for(let appLang of this.initServ.appLanguages){
				if(appLang.code == lang && appLang?.translated){
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * If value is empty then show the default string.
	 * @param object
	 * @returns
	 */
	private process(object: any):any{
		return Object.keys(object)
			// eslint-disable-next-line no-prototype-builtins
			.filter((key: any) => object.hasOwnProperty(key) && object[key] !== '' && object[key] !== null)
			.reduce((result: any, key:string) => (result[key] = typeof object[key] === 'object' ? this.process(object[key]) : object[key], result), {});
	}
}
