import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
// External library
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
// Module
import { GlobalDefaultModule } from '../Global';
// Services
import { SectionServ } from '../Services';
// Components
import { AboutUsComponent,BannerAreaComponent,ClientReviewsComponent,ContactUsComponent,TextContentComponent,CallToActionComponent,GridContentComponent,ReferralComponent,ServicesComponent,FeaturesComponent,OpeningHoursComponent,TeamMembersComponent,FAQComponent,LocationsComponent,PartnersComponent,AppWidgetsComponent,BenefitsComponent,JoinOurTeamComponent, JobDetailsComponent, RowComponent,EmbedFormComponent,EmbedVideoComponent,DiscountBarComponent,HtmlCodeComponent, ReviewsComponent,BlogGridSectionComponent,BlogListingComponent, BlogSidebarComponent,BlogDetailsComponent,GridServicesComponent,GalleryComponent,ServiceGridComponent,ThankYouComponent, CommentsComponent, CommentReplyFormComponent, ReferralPopupComponent, ResetPasswordComponent, AuthorBlogComponent, EmbedHiringFormComponent, EmbedLeadFormComponent, EmbedCampaignFormComponent, EmbedFormBaseComponent } from './index';
// Elements components
import { TextComponent,BulletListComponent,ImageComponent,ButtonComponent } from './Elements';
// Booking Short form
import { ShortFormModule } from '../ShortForm/ShortForm.module';
// Add booking module
import { AddBookingsModule } from '../AddBookings/AddBookings.module';
// Popups
import { WelcomePopupComponent, DiscountPopupComponent, SubscribePopupComponent, ContactUsPopupComponent, TextPopupComponent, VideoPopupComponent, ApplyNowPopupComponent, ShortFormPopupComponent, ThemePopupsComponent } from './Popups';
// Dynamic loade another module components
import { loadCompDirective } from './loadComp.directive';


@NgModule({
	declarations: [AboutUsComponent, BannerAreaComponent, ClientReviewsComponent, ContactUsComponent, TextContentComponent, CallToActionComponent, GridContentComponent, ReferralComponent, ServicesComponent, FeaturesComponent, OpeningHoursComponent, TeamMembersComponent, FAQComponent, LocationsComponent, PartnersComponent, AppWidgetsComponent, HtmlCodeComponent, ReviewsComponent, BenefitsComponent, JoinOurTeamComponent,JobDetailsComponent, TextComponent, BulletListComponent, ImageComponent, ButtonComponent, RowComponent, EmbedFormComponent, BlogGridSectionComponent,BlogListingComponent, BlogSidebarComponent, GridServicesComponent, EmbedVideoComponent, GalleryComponent, ServiceGridComponent, ThankYouComponent, DiscountBarComponent, BlogDetailsComponent,AuthorBlogComponent, CommentsComponent, CommentReplyFormComponent, ReferralPopupComponent, ResetPasswordComponent, WelcomePopupComponent, DiscountPopupComponent, SubscribePopupComponent, ContactUsPopupComponent, TextPopupComponent, VideoPopupComponent, ApplyNowPopupComponent, ShortFormPopupComponent, ThemePopupsComponent, loadCompDirective, EmbedHiringFormComponent, EmbedLeadFormComponent, EmbedCampaignFormComponent, EmbedFormBaseComponent],
	imports: [
		CommonModule,FormsModule,ReactiveFormsModule,RouterModule,GlobalDefaultModule,TranslateModule, TextMaskModule, ShortFormModule, MatDialogModule,AddBookingsModule
	],
	exports: [AboutUsComponent, BannerAreaComponent, ClientReviewsComponent, ContactUsComponent, TextContentComponent, CallToActionComponent, GridContentComponent, ReferralComponent, ServicesComponent, FeaturesComponent, OpeningHoursComponent, TeamMembersComponent, FAQComponent, LocationsComponent, PartnersComponent, AppWidgetsComponent, HtmlCodeComponent, ReviewsComponent, BenefitsComponent, JoinOurTeamComponent,JobDetailsComponent, TextComponent, BulletListComponent, ImageComponent, ButtonComponent, RowComponent, EmbedFormComponent, BlogGridSectionComponent,BlogListingComponent, GridServicesComponent, EmbedVideoComponent, GalleryComponent, ServiceGridComponent, ThankYouComponent, DiscountBarComponent, BlogDetailsComponent, AuthorBlogComponent, WelcomePopupComponent, DiscountPopupComponent, SubscribePopupComponent, ContactUsPopupComponent, TextPopupComponent, VideoPopupComponent, ApplyNowPopupComponent, ShortFormPopupComponent, ThemePopupsComponent, EmbedHiringFormComponent, EmbedLeadFormComponent, EmbedCampaignFormComponent, EmbedFormBaseComponent],
	entryComponents: [AboutUsComponent, BannerAreaComponent, ClientReviewsComponent, ContactUsComponent, TextContentComponent, CallToActionComponent, GridContentComponent, ReferralComponent, ServicesComponent, FeaturesComponent, OpeningHoursComponent, TeamMembersComponent, FAQComponent, LocationsComponent, PartnersComponent, AppWidgetsComponent, HtmlCodeComponent, ReviewsComponent, BenefitsComponent, JoinOurTeamComponent,JobDetailsComponent,TextComponent, BulletListComponent, ImageComponent, ButtonComponent, RowComponent, EmbedFormComponent, BlogGridSectionComponent,BlogListingComponent, GridServicesComponent, EmbedVideoComponent, GalleryComponent, ServiceGridComponent, ThankYouComponent, DiscountBarComponent, BlogDetailsComponent,AuthorBlogComponent, WelcomePopupComponent, DiscountPopupComponent, SubscribePopupComponent, ContactUsPopupComponent, TextPopupComponent, VideoPopupComponent, ApplyNowPopupComponent, ShortFormPopupComponent, ThemePopupsComponent, EmbedHiringFormComponent, EmbedLeadFormComponent, EmbedCampaignFormComponent],
	providers: [SectionServ]
})
export class ThemeElementsModule { }
