import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, ApiServ, InitServ, UtilServ, LoaderServ } from '../../../Services';

@Component({
	selector: 'bk-blog-listing',
	templateUrl: './BlogListing.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BlogListingComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		single_post: null,
		blog_list:null,
		description:null,
		recent_posts:null,
		social_list:null,
		subscribe:null
	}
	searchForm: FormGroup;
	blogs: any;
	settings: any;
	limit: number = 30;
	page: number = 1;
	totalRecords: number = 0;
	slug: string = 'blog';
	sectionData: any;
	recentBlogs: any;
	loaderId: string = 'blog-listing-loader';

	constructor(private actRoute: ActivatedRoute, public rcServ: RenderComponentServ, public secServ: SectionServ, private frmBldr: FormBuilder, private apiServ: ApiServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, public utilServ: UtilServ, private loader: LoaderServ) {
		this.blogSettings();
		// Search form
		this.searchForm = this.frmBldr.group({
			search: ['', [Validators.required]]
		});
	}

	ngOnInit(): void {
		// Page slug
		this.slug = this.actRoute.snapshot.params['slug'] ? this.actRoute.snapshot.params['slug'] : 'blog';
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.buildSectionFields(secData);
		this.getBlogs();
	}
	/**
	 * Build the blog sections fields
	 */
	private buildSectionFields(secData:any): void{
		let secElem : any = null;
		if(secData.sections){
			secElem = secData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "description":
						case "recent_posts":
						case "social_list":
						case "subscribe":
							this.section[key] =  this.secServ.buildGroup(secElem[key]);
							if(key == 'recent_posts' && this.section['recent_posts']){
								this.getBlogs('recent_posts');
							}
							break;
						default:
							this.section[key] = this.secServ.checkElementStatus(secElem[key]) && secElem[key];
							break;
					}
				}
			}
		}
	}
	/**
	 * Get the blogs from content management app
	 */
	private getBlogs(type: string='blogs'): void{
		this.loader.show(this.loaderId);
		let queryParams = {};
		if(type == 'recent_posts'){
			queryParams = {limit: 4, content: 'no',status:'publish', language:this.initServ.savedLng }
		} else {
			let search = this.searchForm.controls['search'].value;
			queryParams = {status:'publish', limit:this.limit, page:this.page, sort:'yes', s:search, language:this.initServ.savedLng};
		}
		this.apiServ.callApiWithQueryParams('GET', 'Blogs', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, type));
	}
	/**
	 * Get the blog settings, which is get by content management app
	 */
	private blogSettings(): void{
		this.apiServ.callApi('GET', 'BlogsSett').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'sett'));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		if(this.apiServ.checkAPIRes(res)){
			switch(type){
				case 'section':
					if(res.data){
						let secData: any = res.data;
						if(secData){
							this.rcServ.setPageData = JSON.parse(JSON.stringify(secData));
							this.buildSection(secData);
						}
					}
				break;
				case 'blogs':
					if (this.page == 1) { this.totalRecords = res.total_record; }
					this.blogs = res.data;
					this.loader.hide(this.loaderId);
				break;
				case 'recent_posts':
					this.recentBlogs = res.data;
					break;
				case 'sett':
					this.settings = res.data;
				break;
			}
		}
	}
	/**
	 * On search form submit, get the blogs depend on search.
	 */
	public submitForm(): void{
		if(this.searchForm.controls['search'].value){
			this.getBlogs();
		}
	}
	/**
	 * Get the page number, get the blogs based on page number
	 */
	public pageChange(event: any): void{
		this.page = event;
		this.getBlogs();
	}
}
