<div [id]="secId" class="py-30 py-md-60">
	<div class="container">
		<bk-profile-card type="giftCard" [section]="section?.profile"></bk-profile-card>
		<!-- RECIEVED GIFT CARDS -->
		<div class="position-relative">
			<div [id]="section?.received_list?.id" class="card mb-30 bk-section-card" *ngIf="(received && received?.data && (received?.data).length > 0); else noReceived">
				<div class="card-body">
					<!-- Title -->
					<ng-container *ngTemplateOutlet="titleTemp; context:{title: section?.received_list?.title}"></ng-container>
					<div class="card shadow-sm">
						<div class="card-body p-0">
							<table class="table bk-table tjs-table tjs-table--hover tjs-table--responsive tjs-table--border__x">
								<!-- Head -->
								<ng-container *ngTemplateOutlet="tableHeadTemp; context:{data: received?.headers}"></ng-container>
								<!-- Body -->
								<tbody>
									<tr *ngFor="let giftCard of received?.data; trackBy: utilServ.trackByFnIndex">
										<td [attr.data-label]="section?.received_list?.table?.name">
											<small class="text-capitalize text-dark fw-bold td-text--color td-text--fs">{{giftCard?.sender_first_name}} {{giftCard?.sender_last_name}}</small>
										</td>
										<td [attr.data-label]="section?.received_list?.table?.gc_email">
											<a class="fs-14 text-body td-text--color td-text--fs" href="mailto:{{giftCard?.sender_email}}">{{giftCard?.sender_email}}</a>
										</td>
										<td [attr.data-label]="section?.received_list?.table?.gc_code">
											<small class="td-text--fs">{{giftCard?.code}}</small>
										</td>
										<td [attr.data-label]="section?.received_list?.table?.amount" class="text-end">
											<small class="td-text--fs" *ngIf="(giftCard?.amount && giftCard?.amount > 0); else noAmount">
												<bk-amount-display [amount]="giftCard?.used_amount" [isZero]="true"></bk-amount-display> / <bk-amount-display [amount]="giftCard?.amount" [isZero]="true"></bk-amount-display>
											</small>
											<ng-template #noAmount>
												<small class="td-text--fs"><bk-amount-display [isZero]="true"></bk-amount-display> / <bk-amount-display [isZero]="true"></bk-amount-display></small>
											</ng-template>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<bk-prev-next-pagination [secId]="section?.received_list?.pagination_id" [totalRecords]="received?.totalRecords" [currentPage]="received?.page" [limit]="received?.limit" (callback)="pageChange($event)"></bk-prev-next-pagination>
			</div>
			<!-- Empty list -->
			<ng-template #noReceived>
				<ng-container *ngTemplateOutlet="error; context:{data:initServ?.appStr?.errorMsg?.noReceivedGiftCard, cardId: section?.received_list?.id, titleId: section?.received_list?.title_id}"></ng-container>
			</ng-template>
			<!-- Loader -->
			<bk-app-loader [loaderId]="receivedLoaderId"></bk-app-loader>
		</div>
		<!-- SENT GIFT CARDS -->
		<div class="position-relative">
			<div [id]="section?.sent_list?.id" class="card bk-section-card" *ngIf="(sent && sent?.data && (sent?.data).length > 0); else noSent">
				<div class="card-body card-body">
					<!-- Title -->
					<ng-container *ngTemplateOutlet="titleTemp; context:{title: section?.sent_list?.title}"></ng-container>
					<div class="card shadow-sm">
						<div class="card-body p-0">
							<table class="table bk-table tjs-table tjs-table--hover tjs-table--responsive tjs-table--border__x">
								<!-- Head -->
								<ng-container *ngTemplateOutlet="tableHeadTemp; context:{data: sent?.headers}"></ng-container>
								<!-- Body -->
								<tbody>
									<tr *ngFor="let giftCard of sent?.data;trackBy: utilServ.trackByFnIndex">
										<td [attr.data-label]="section?.sent_list?.table?.name">
											<small class="text-capitalize fw-bold text-dark td-text--color td-text--fs">{{giftCard?.recipient_name}}</small>
										</td>
										<td [attr.data-label]="section?.sent_list?.table?.gc_email">
											<a class="fs-14 text-body td-text--color td-text--fs" href="mailto:{{giftCard?.recipient_email}}">{{giftCard?.recipient_email}}</a>
										</td>
										<td [attr.data-label]="section?.sent_list?.table?.date">
											<ng-container *ngIf="giftCard?.schedule_on_date; else createdOn">
												<bk-date-display class="d-block fs-14 td-text--fs" [date]="giftCard?.schedule_on_date" [isStr]="true" [isLocal]="true"></bk-date-display>
											</ng-container>
											<ng-template #createdOn>
												<bk-date-display class="d-block fs-14 td-text--fs" [date]="giftCard?.created_on"></bk-date-display>
											</ng-template>
										</td>
										<td [attr.data-label]="section?.sent_list?.table?.amount" [class.text-end]="!isEdit">
											<div>
												<bk-amount-display *ngIf="giftCard?.amount && giftCard?.amount > 0" class="d-block" customClass="fs-14 td-text--fs" [amount]="giftCard?.amount"></bk-amount-display>
												<div *ngIf="giftCard?.is_refunded" class="tjs-tooltip-wrap summary--tooltip tjs-tooltip__partial-refund" [class.mt-5]="giftCard?.refund[0]?.type != 'full'">
													<small class="badge bg-danger px-10 py-5 fs-12 tjs-pointer td-badge">
														{{(giftCard?.refund[0]?.type == 'full' ? 'Full refund' : (giftCard?.refund && (giftCard?.refund).length > 1) ? 'Multiple partial refunds' : 'Partial refund') | translate}}
													</small>
													<!-- Gift card refund -->
													<div *ngIf="(giftCard?.refund && (giftCard?.refund).length > 0 && (giftCard?.refund).length == 1) else multi" class="tjs-tooltip tjs-tooltip--lg tjs-tooltip--top tjs-scrollbar mb-0-imp tjs-overflow-scroll tjs-maxheight-250">
														<p class="pb-10 mb-10 border-bottom fw-bold fs-15">
															<span class="tjs-capitalize">{{(giftCard?.refund[0]?.type == 'full' ? 'Full refund' : 'Partial refund') | translate}}</span>
															<span class="mt-5 d-block fw-normal" *ngIf="giftCard?.refund[0]?.refunded_on">
																<bk-date-display class="d-inline-block me-5" [date]="giftCard?.refund[0]?.refunded_on"></bk-date-display> <bk-time-display [time]="giftCard?.refund[0]?.refunded_on" [ml5Status]="true"></bk-time-display>
															</span>
														</p>
														<p class="d-flex justify-content-between">
															<span>{{'Amount'| translate}}</span>
															<span class="tjs-summary__more-detail">
																<bk-amount-display [amount]="giftCard?.refund[0]?.amount" [isZero]="true"></bk-amount-display>
															</span>
														</p>
														<div *ngIf="giftCard?.refund[0]?.reason">
															<p class="fw-bold mb-5">{{'Reason' | translate}}:</p>
															<div>{{giftCard?.refund[0]?.reason | translate}}</div>
														</div>
													</div>
													<ng-template #multi>
														<div *ngIf="giftCard?.refund && (giftCard?.refund).length > 0 && (giftCard?.refund).length > 1" class="tjs-tooltip tjs-tooltip--left tjs-tooltip--xl px-0 tjs-scrollbar tjs-overflow-scroll tjs-maxheight-250">
															<p class="border-bottom pb-10 mb-0" translate>It seems that there are multiple refunds performed on this gift card. All refunds are listed below, please open the accordion to see the refund reason.</p>
															<div class="accordion tjs-accordion tjs-accordion__add tjs-accordion--right bg-transparent border-0" *ngFor="let refund of giftCard?.refund; let i = index;trackBy: utilServ.trackByFnIndex">
																<div class="accordion-item bg-transparent border-0">
																	<div [attr.aria-controls]="'refund_'+giftCard?.id+'_'+i" aria-expanded="true" class="accordion-header collapsed accordion-button tjs-pointer py-10 ps-0" [attr.data-bs-target]="'#refund_'+giftCard?.id+'_'+i" data-bs-toggle="collapse">
																		<p class="tjs-customizer--title fw-bold mb-0 text-body">
																			<bk-amount-display customClass="fs-15" [amount]="refund?.amount" [isZero]="true"></bk-amount-display>
																			<small class="fs-13" *ngIf="refund?.refunded_on"><small class="mx-5">{{'on'| translate}}</small>
																			<bk-date-display class="d-inline-block me-5" [date]="refund?.refunded_on"></bk-date-display> <bk-time-display [time]="refund?.refunded_on" [ml5Status]="true"></bk-time-display> </small>
																		</p>
																	</div>
																	<div class="accordion-collapse collapse" id="refund_{{giftCard?.id}}_{{i}}">
																		<div *ngIf="refund?.reason" class="mt-10">
																			<p class="fw-bold mb-5">{{'Reason' | translate}}:</p>
																			<div>{{refund?.reason | translate}}</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</ng-template>
												</div>
											</div>
										</td>
										<td [attr.data-label]="section?.sent_list?.table?.action" *ngIf="isEdit" class="text-end">
											<div class="d-flex align-items-center justify-content-end flex-wrap">
												<a *ngIf="giftCard?.used_amount == 0 && giftCard?.amount && giftCard?.amount > 0; else elseTooltip" class="text-decoration-none fs-14 text-body d-flex align-items-center btn btn-link px-0 action-btn text-nowrap" [routerLink]="['/gift-cards/edit/'+giftCard?.id]" translate>
													<i class="tjsicon-pencil me-sm-10 me-5"></i>Edit
												</a>
												<ng-template #elseTooltip>
													<bk-tooltip customClass="action-btn tooltip--color" [content]="initServ.appStr?.tTip?.noGiftCardUpdate | translate"></bk-tooltip>
												</ng-template>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<bk-prev-next-pagination [secId]="section?.sent_list?.pagination_id" [totalRecords]="sent?.totalRecords" [currentPage]="sent?.page" [limit]="sent?.limit" (callback)="pageChange($event, 'sent')"></bk-prev-next-pagination>
			</div>
			<!-- Empty List -->
			<ng-template #noSent>
				<ng-container *ngTemplateOutlet="error; context:{data:initServ?.appStr?.errorMsg?.noSentGiftCard, cardId: section?.sent_list?.id, titleId: section?.sent_list?.title_id}"></ng-container>
			</ng-template>
			<!-- Loader -->
			<bk-app-loader [loaderId]="sentLoaderId"></bk-app-loader>
		</div>
	</div>
</div>
<!-- ##################### TEMPLATES #################### -->
<!-- No data -->
<ng-template #error let-data="data" let-cardId="cardId" let-titleId="titleId">
	<div [id]="cardId" class="card" [ngClass]="{'mb-30': !(received && received?.data && (received?.data).length > 0)}">
		<div [id]="titleId" class="card-body card-body">
			<h4 class="mb-0 fw-bold bk-section-title">{{data | translate}}</h4>
		</div>
	</div>
</ng-template>
<!-- Title template -->
<ng-template #titleTemp let-title="title">
	<div class="tjs-card-title d-sm-flex justify-content-between align-items-center bk-section-border">
		<div [id]="title?.id" class="d-flex align-items-center me-sm-30">
			<h4 class="mb-0 card-title fw-bold bk-section-title" [id]="title?.card_title_id" [innerHTML]="(title?.card_title) | safeHtml"></h4>
			<bk-tooltip class="align-self-end" customClass="tjs-tooltip-icon tjs-tooltip-icon--lg bk-section-tooltip" [id]="title?.tooltip_id" [content]="title?.tooltip"></bk-tooltip>
		</div>
	</div>
</ng-template>
<!-- Table heading -->
<ng-template #tableHeadTemp let-data="data">
	<thead>
		<tr *ngIf="data && data.length > 0">
			<th *ngFor="let head of data;let last = last;trackBy: utilServ.trackByFnIndex" [ngClass]="{'text-end': last}">{{head}}</th>
		</tr>
	</thead>
</ng-template>
