<div class="position-relative" *ngIf="settings && settings.form_data && settings.form_data.mode && settings.form_data.status; else underMaintenance">
	<form class="bk-form" [formGroup]="BKFrm">
		<ng-container *ngIf="!isStepOneValid">
			<!-- Step 1 -->
			<div class="form-step" [ngClass]="{'active-step' : (locationLayout != 'no_location')}" *ngIf="locationLayout != 'no_location'">
				<div>
					<ng-container *ngTemplateOutlet="locationTemp"></ng-container>
					<!-- Buttons -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{type:'zipcode'}"></ng-container>
				</div>
			</div>
			<!-- Step 2 -->
			<div class="form-step" [ngClass]="{'active-step' : (locationLayout == 'no_location')}">
				<ng-container *ngTemplateOutlet="formParamsTemp"></ng-container>
				<div class="sf-v4--footer flex-column flex-md-row col-md-11 mx-auto" *ngIf="variationId == 'bk_short_form_V4' else v3Params">
					<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
					<!-- Buttons -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{type:'pricingparam', isPrev: true}"></ng-container>
				</div>
				<ng-template #v3Params>
					<div>
						<hr />
						<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
						<!-- Buttons -->
						<ng-container *ngTemplateOutlet="btnTemp; context:{type:'pricingparam', isPrev: true}"></ng-container>
					</div>
				</ng-template>
			</div>
			<!-- Step 3 -->
			<div class="form-step" *ngIf="formId == 1 && shortFormExcludes && (shortFormExcludes).length > 0">
				<ng-container *ngTemplateOutlet="excludeTemp"></ng-container>
				<div class="sf-v4--footer flex-column flex-md-row col-md-11 mx-auto" *ngIf="variationId == 'bk_short_form_V4' else v3Excludes">
					<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
					<!-- Buttons -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{type:'exclude'}"></ng-container>
				</div>
				<ng-template #v3Excludes>
					<div>
						<hr />
						<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
						<!-- Buttons -->
						<ng-container *ngTemplateOutlet="btnTemp; context:{type:'exclude'}"></ng-container>
					</div>
				</ng-template>
			</div>
			<!-- Step 4 -->
			<div class="form-step" *ngIf="settings && settings?.extras && (settings?.extras).length > 0 && isExtraVisible && extraStatus">
				<ng-container *ngTemplateOutlet="extraTemp"></ng-container>
				<div class="sf-v4--footer flex-column flex-md-row col-md-11 mx-auto" *ngIf="variationId == 'bk_short_form_V4' else v3Extra">
					<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
					<!-- Buttons -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{type:'extra'}"></ng-container>
				</div>
				<ng-template #v3Extra>
					<div>
						<hr />
						<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
						<!-- Buttons -->
						<ng-container *ngTemplateOutlet="btnTemp; context:{type:'extra'}"></ng-container>
					</div>
				</ng-template>
			</div>
			<!-- Step 5 -->
			<div class="form-step" *ngIf="bkngCustSecServ.stepWiseCustGrp.step_one && (bkngCustSecServ.stepWiseCustGrp.step_one).length > 0 && bkngCustSecServ.buildCustomFields">
				<ng-container *ngTemplateOutlet="customFieldsTemp"></ng-container>
				<div class="sf-v4--footer flex-column flex-md-row col-md-11 mx-auto" *ngIf="variationId == 'bk_short_form_V4' else v3Custom">
					<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
					<!-- Buttons -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{type:'customfields'}"></ng-container>
				</div>
				<ng-template #v3Custom>
					<div>
						<hr />
						<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
						<!-- Buttons -->
						<ng-container *ngTemplateOutlet="btnTemp; context:{type:'customfields'}"></ng-container>
					</div>
				</ng-template>
			</div>
			<!-- Step 6 -->
			<div class="form-step" *ngIf="((settings && settings?.form_data && settings?.form_data?.enable_email_on_first_step && settings?.form_data?.enable_email_on_first_step != 'no') || !settings?.form_data?.enable_email_on_first_step)">
				<ng-container *ngTemplateOutlet="emailTemp"></ng-container>
				<div class="sf-v4--footer flex-column flex-md-row col-md-11 mx-auto" *ngIf="variationId == 'bk_short_form_V4' else v3Email">
					<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
					<!-- Buttons -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{type:'email', isLast: true}"></ng-container>
				</div>
				<ng-template #v3Email>
					<div>
						<hr />
						<ng-container *ngTemplateOutlet="summaryTemp"></ng-container>
						<!-- Buttons -->
						<ng-container *ngTemplateOutlet="btnTemp; context:{type:'email', isLast: true}"></ng-container>
					</div>
				</ng-template>
			</div>
		</ng-container>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="shortFormLoaderId"></bk-app-loader>
</div>
<ng-template #underMaintenance>
	<div class="w-100 text-center">
		<h6 class="mb-0" *ngIf="settings">{{initServ?.appStr?.text?.undergoingMaintenance | translate}}</h6>
	</div>
</ng-template>

<!-- Templates -->

<!-- Button template -->
<ng-template #btnTemp let-type="type" let-isLast="isLast" let-isPrev="isPrev">
	<div class="d-flex flex-column flex-md-row footer-btns" [ngClass]="{'justify-content-center' : secSett?.variation_id == 'bk_short_form_V4'}">
		<!-- Back -->
		<ng-container *ngIf="locationLayout != 'no_location' && type == 'zipcode'; else backBtn">
			<ng-container *ngIf="!isPrevStepExist">
				<bk-navigate class="order-2 order-md-0" *ngIf="section?.back_btn && section?.back_btn?.content" [secSett]="section?.back_btn" customClass="btn btn-light py-15 border-transparent" [innerHTML]="section?.back_btn?.content" essentialClass="me-md-20 me-0" (callback)="backToPrev()"></bk-navigate>
			</ng-container>
		</ng-container>
		<ng-template #backBtn>
			<bk-navigate class="order-2 order-md-0" *ngIf="section?.back_btn && section?.back_btn?.content" [secSett]="section?.back_btn" customClass="btn btn-light py-15 border-transparent" essentialClass="me-md-20 me-0" [innerHTML]="section?.back_btn?.content" [isBtnEvent]="true" (callback)="(locationLayout == 'no_location' && isPrev) ? backToPrev() : prevStep($event)"></bk-navigate>
		</ng-template>
		<!-- Next buttons -->
		<ng-container *ngIf="isLast; else nextBtn">
			<!-- Find Availability -->
			<bk-navigate *ngIf="section?.find_avail_btn && section?.find_avail_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" [innerHTML]="section?.find_avail_btn?.content" essentialClass="mb-15 mb-md-0" [isBtnEvent]="true" (callback)="nextStep($event, type)"></bk-navigate>
		</ng-container>
		<ng-template #nextBtn>
			<!-- Next -->
			<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" essentialClass="mb-15 mb-md-0" [innerHTML]="section?.continue_btn?.content" [isBtnEvent]="true" (callback)="nextStep($event, type)"></bk-navigate>
		</ng-template>
	</div>
</ng-template>
<!-- Location template -->
<ng-template #locationTemp>
	<bk-short-form-loc *ngIf="locationLayout == 'name_based'; else zipcode"
	[variationId]="secSett?.variation_id"
	[bookingType]="bookingType"
	[section]="section?.location"
	[locationForm]="BKFrm"
	[settings]="settings"
	[sectionIds]="sectionIds"
	(setLocation)="setLocation($event)">
	</bk-short-form-loc>
	<!-- Zipcode based -->
	<ng-template #zipcode>
		<bk-short-from-zipcode #locationComp
			[sectionIds]="sectionIds"
			[variationId]="secSett?.variation_id"
			[bookingType]="bookingType"
			[section]="section?.location"
			[zipcodeForm]="BKFrm"
			[settings]="settings"
			[mlDesignId]="mlDesignId"
			[isShortForm]="true"
			(locationChange)="resetFormControl('location')"
			(locationFocusOut)="checkZipcodeAval($event)"
			(merchantLocFocusOut)="checkMerchantZipcodeAval($event)">
		</bk-short-from-zipcode>
	</ng-template>
</ng-template>
<!-- Form Params -->
<ng-template #formParamsTemp>
	<ng-container [ngSwitch]="formId">
		<ng-container *ngSwitchCase="1">
			<bk-short-form-pricing-params
				[sectionIds]="sectionIds"
				[isShortForm]="true"
				[bookingType]="bookingType"
				[variationId]="secSett?.variation_id"
				[parentSection]="section"
				[section]="section?.pricing_params"
				[pricingParamForm]="BKFrm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				[prefilledData]="prefilledData"
				(pricingParamChange)="resetFormControl('pricing-param')"
				(serviceChange)="serviceCatChange($event, true)"
				(hourlyServiceChange)="resetFormControl('hourly-service')"
				(frequencyChange)="frequencyChange($event, true)"
				(getExcludes)="shortFormExcludes = $event"
				[pageSett]="bkngPageSett"
				(pricingParamsStatus)="buildFormParamsStatus.pricingParams = $event"
			>
			</bk-short-form-pricing-params>
		</ng-container>
		<ng-container *ngSwitchCase="2">
			<bk-short-form-items-with-pckgs
				[sectionIds]="sectionIds"
				[parentSection]="section"
				[variationId]="secSett?.variation_id"
				[section]="section?.packages"
				[itemsForm]="BKFrm"
				[settings]="settings"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[prefilledItems]="prefilledFormParams.items"
				(itemChange)="resetFormControl('items')"
				(itemParamChange)="resetFormControl('items-params')"
				(packageChange)="resetFormControl('package')"
				(serviceChange)="serviceCatChange($event, true)"
				(hourlyServiceChange)="resetFormControl('hourly-service')"
				(frequencyChange)="frequencyChange($event, true)"
				(prefilledItemsChange)="prefilledFormParams.items = $event"
				(itemPckgStatus)="buildFormParamsStatus.itemPackages = $event"
			>
			</bk-short-form-items-with-pckgs>
			<!-- Validation -->
			<ng-container *ngIf="(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes')">
				<div class="tjs-error bk-error mb-20" *ngIf="isNoItemSelected || (isNoPackageSelected && !validPckgStatus)" translate>
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="isNoItemSelected">Please select at least one item.</ng-container>
					<ng-container *ngIf="isNoPackageSelected && !validPckgStatus">Please select at least one package.</ng-container>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="3">
			<bk-short-form-items-with-addons
				[parentSection]="section"
				[variationId]="secSett?.variation_id"
				[section]="section?.addons"
				[bookingType]="bookingType"
				[itemsForm]="BKFrm"
				[settings]="settings"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				(itemChange)="resetFormControl('items')"
				(itemParamChange)="resetFormControl('items-params')"
				[prefilledItems]="prefilledFormParams.items"
				(serviceChange)="serviceCatChange($event, true)"
				(hourlyServiceChange)="resetFormControl('hourly-service')"
				(frequencyChange)="frequencyChange($event, true)"
				[sectionIds]="sectionIds"
				(itemAddonStatus)="buildFormParamsStatus.itemsAddons = $event"
			>
			</bk-short-form-items-with-addons>
			<ng-container *ngIf="(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes')">
				<div class="tjs-error bk-error mb-20" *ngIf="isNoItemSelected || (isNoAddonSelected && (BKFrm?.value['items']).length == 0)" translate>
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="isNoItemSelected">Please select at least one item.</ng-container>
					<ng-container *ngIf="isNoAddonSelected && (BKFrm?.value['items']).length == 0">Please select at least one addon.</ng-container>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="4">
			<bk-short-form-pricing-parameter-with-area *ngIf="BKFrm.value['area_parameter']" #areaParamComp
				[parentSection]="section"
				[variationId]="secSett?.variation_id"
				[bookingType]="bookingType"
				[section]="section?.area_params"
				[areaParamForm]="BKFrm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				(areaUnitChange)="resetFormControl('area-params')"
				[prefilledAreaParam]="prefilledFormParams.area_param"
				(serviceChange)="serviceCatChange($event, true)"
				(hourlyServiceChange)="resetFormControl('hourly-service')"
				(frequencyChange)="frequencyChange($event, true)"
				[sectionIds]="sectionIds"
				(areaParamsStatus)="buildFormParamsStatus.areaParams = $event"
			>
			</bk-short-form-pricing-parameter-with-area>
		</ng-container>
		<ng-container *ngSwitchCase="5">
			<div [ngClass]="secSett?.variation_id == 'bk_short_form_V3' ? 'area_version_3' : 'area_version_4 row justify-content-center'" [id]="section?.service?.id" *ngIf="settings && section">
				<div [ngClass]="{'col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8' : secSett?.variation_id == 'bk_short_form_V4'}">
					<!-- Title -->
					<div class="mb-30" *ngIf="section?.hourly_params?.title">
						<h4 class="mb-0 bk-title" [ngClass]="secSett?.variation_id == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="section?.title"  [innerHTML]="section?.hourly_params?.title | safeHtml"></h4>
					</div>
					<div [ngClass]="{'combine-component row justify-content-center' : variationId == 'bk_short_form_V4'}">
						<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4', 'position-absolute':!(services && services.length > 1)}">
							<!-- Service category -->
							<bk-short-form-service-cat
								[sectionIds]="sectionIds"
								[bookingType]="bookingType"
								[variationId]="secSett?.variation_id"
								[section]="section?.service"
								[serviceForm]="BKFrm"
								[selectedLocation]="selectedLocation"
								[selectedServiceType]="selectedServiceType"
								[selectedFrequency]="selectedFrequency"
								[settings]="settings"
								[isShortForm]="true"
								(serviceChange)="serviceCatChange($event, true)"
								(hourlyServiceChange)="resetFormControl('hourly-service')"
								(getServices)="services=$event"
							>
							</bk-short-form-service-cat>
						</div>
						<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4'}">
							<!-- Frequency -->
							<bk-short-form-frequency
								[sectionIds]="sectionIds"
								[variationId]="secSett?.variation_id"
								[bookingType]="bookingType"
								[section]="section?.frequency"
								[frequencyForm]="BKFrm"
								[selectedLocation]="selectedLocation"
								[selectedServiceType]="selectedServiceType"
								[selectedFrequency]="selectedFrequency"
								[settings]="settings"
								(frequencyChange)="frequencyChange($event, true)" >
							</bk-short-form-frequency>
						</div>
					</div>
					<bk-short-frm-hourly
						[sectionIds]="sectionIds"
						[bookingType]="bookingType"
						[variationId]="secSett?.variation_id"
						[section]="section?.service"
						[serviceForm]="BKFrm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(serviceChange)="serviceCatChange($event, true)"
						(hourlyServiceChange)="resetFormControl('hourly-service')">
					</bk-short-frm-hourly>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-template>
<!-- Excludes template -->
<ng-template #excludeTemp>
	<bk-short-frm-excludes
		[excludes]="shortFormExcludes"
		[bookingType]="bookingType"
		[variationId]="secSett?.variation_id"
		[section]="section?.pricing_params"
		[excludesForm]="BKFrm"
		[sectionIds]="sectionIds"
		(excludeChange)="resetFormControl('exclude')"
	></bk-short-frm-excludes>
</ng-template>
<!-- Extra template -->
<ng-template #extraTemp>
	<bk-short-frm-extras
		[variationId]="secSett?.variation_id"
		[bookingType]="bookingType"
		[section]="section?.extras"
		[extrasForm]="BKFrm"
		[settings]="settings"
		[selectedServiceType]="selectedServiceType"
		[selectedFrequency]="selectedFrequency"
		[selectedLocation]="selectedLocation"
		[sectionIds]="sectionIds"
		[pageSett]="bkngPageSett"
		[isShortForm]="true"
		(isExtraVisible)="isExtraVisible = $event"
		(extraChange)="resetFormControl('extras')">
	</bk-short-frm-extras>
</ng-template>
<!-- Custom fields template -->
<ng-template #customFieldsTemp>
	<ng-container *ngIf="bkngCustSecServ.stepWiseCustGrp.step_one && (bkngCustSecServ.stepWiseCustGrp.step_one).length > 0 && bkngCustSecServ.buildCustomFields">
		<ng-container *ngFor="let customField of bkngCustSecServ.stepWiseCustGrp.step_one">
			<!-- <bk-custom-fields  -->
			<bk-short-frm-custom-fields
				[isShortForm]="true"
				[variationId]="secSett?.variation_id"
				[style.order]="sectionOrder['custom_fields_'+customField._id]"
				[customFieldGroup]="customField"
				[bookingType]="bookingType"
				[BKFrm]="BKFrm"
				[prefilledData]="prefilledData"
				stepType="all"
				[selectedServiceType]="selectedServiceType"
				[buildCustomFields]="bkngCustSecServ.buildCustomFields"
				[sectionIds]="sectionIds"
				(customFieldChange)="resetFormControl('custom-fields', $event)"
			></bk-short-frm-custom-fields>
		</ng-container>
	</ng-container>



	<!-- <bk-short-frm-custom-fields
		[isShortForm]="true"
		[variationId]="secSett?.variation_id"
		[bookingType]="bookingType"
		[customFieldsForm]="BKFrm"
		stepType="step_one"
		(stepOneCustomSectionsIds)="stepOneCustomSectionIds = $event"
		(stepTwoCustomSectionsIds)="stepTwoCustomSectionIds = $event"
		(customFieldCheckboxBuild)="customFieldCheckboxData = $event"
		[buildCustomFields]="buildCustomFields"
		[sectionIds]="sectionIds"
		>
	</bk-short-frm-custom-fields> -->
</ng-template>
<!-- Email template -->
<ng-template #emailTemp>
	<bk-short-frm-customer-email [style.order]="sectionOrder.email" #emailComp
		[variationId]="secSett?.variation_id"
		[settings]="settings"
		[currentUser]="currentUser"
		[customerForm]="BKFrm"
		[section]="section?.email"
		[formLayout]="formLayout"
		[sectionIds]="sectionIds"
		(custmrChng)="custmrChng($event)"
		[pageSett]="bkngPageSett"
	>
	</bk-short-frm-customer-email>
</ng-template>
<!-- Template for layout 3 summary -->
<ng-template #summaryTemp>
	<bk-short-frm-summary *ngIf="settings"
		[variationId]="secSett?.variation_id"
		[bookingType]="bookingType"
		[isStepOneValid]="isStepOneValid"
		[prefilledData]="prefilledData"
		[BKFrm]="BKFrm"
		[parentSection]="section"
		[section]="section?.summary"
		[pageSett]="pageSett"
		[settings]="settings"
		[selectedServiceType]="selectedServiceType"
		[selectedFrequency]="selectedFrequency"
		[selectedPricingParams]="formId == 1 && BKFrm.value['pricing_parameter']"
		[selectedItems]="(formId == 2 || formId == 3) && BKFrm.value['items']"
		[selectedAreaParams]="formId == 4 && BKFrm.value['area_parameter']"
		[selectedExtras]="BKFrm.value['extras']"
		[selectedExcludes]="formId == 1 && BKFrm?.value['is_partial_cleaning'] && BKFrm.value['partial_cleaning']"
		[adjustedTimeHours]="priceLocalVar?.adjustedTimeHours"
		[adjustedTimeMin]="priceLocalVar?.adjustedTimeMin"
		[bookingTimeHours]="priceLocalVar?.bookingTimeHours"
		[bookingTimeMin]="priceLocalVar?.bookingTimeMin"
		[selectedAddr]="BKFrm?.value['address']"
		[isServiceFeeTaxable]="priceLocalVar?.isServiceFeeTaxable"
		[displayServicePrice]="priceLocalVar?.displayServicePrice"
		[excludeExpeditedAmountValue]="priceLocalVar?.excludeExpeditedAmountValue"
		[displayFrequencyDiscount]="priceLocalVar?.displayFrequencyDiscount"
		[displayCouponDiscount]="priceLocalVar?.displayCouponDiscount"
		[displayDiscountedAmount]="priceLocalVar?.displayDiscountedAmount"
		[bookingTaxRate]="priceLocalVar?.bookingTaxRate"
		[exemptSalesTaxValue]="priceLocalVar?.exemptSalesTaxValue"
		[bookingTaxType]="priceLocalVar?.bookingTaxType"
		[displaySaleTax]="priceLocalVar?.displaySaleTax"
		[displayTipsAmount]="priceLocalVar?.displayTipsAmount"
		[excludeServiceFeeValue]="priceLocalVar?.excludeServiceFeeValue"
		[displayServiceFee]="priceLocalVar?.displayServiceFee"
		[displayTotal]="priceLocalVar?.displayTotal"
		[firstRecAptFinalAmount]="priceLocalVar?.firstRecAptFinalAmount"
		[afterNthAptFinalAmount]="priceLocalVar?.afterNthAptFinalAmount"
		[displaySpotDiscount]="priceLocalVar?.displaySpotDiscount"
		[displayGiftCardAmount]="priceLocalVar?.displayGiftCardAmount"
		[displayReferralDiscount]="priceLocalVar?.displayReferralDiscount"
		[displayFinalAmount]="priceLocalVar?.displayFinalAmount"
		[isStepOneAvail]="isStepOneAvail"
		[isStepAvailFormFive]="isStepAvailFormFive"
		[sectionIds]="sectionIds"
	></bk-short-frm-summary>
</ng-template>
