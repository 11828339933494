<div [id]="secId" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" class="tjs-session-page py-100 tjs-bg-style position-relative" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
	<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="tjs-session__wrapper">
		<div class="container">
			<div class="tjs-section__title text-center mb-50" *ngIf="section?.title?.content || section?.sub_title?.content">
				<!-- Title -->
				<div class="tjs-section__heading mb-15" *ngIf="section?.title?.content">
					<h4 [id]="section?.title?.id" class="fw-bold display-6 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h4>
				</div>
				<!-- Sub title -->
				<div class="tjs-section__description" *ngIf="section?.sub_title?.content">
					<p [id]="section?.sub_title?.id" class="bk-sub-title" [innerHTML]="section?.sub_title?.content | safeHtml"></p>
				</div>
			</div>
			<div class="row justify-content-center">
				<!-- Social -->
				<ng-container *ngIf="!prvdrAccessToken && ((socialKeys?.Facebook || socialKeys?.Google) && section?.social)">
					<div class="col-12 col-sm-12 col-md-5">
						<div class="d-flex justify-content-center justify-content-md-end text-center text-md-start">
							<bk-session-social [data]="section?.social" openFrom="login"></bk-session-social>
						</div>
					</div>
					<div class="col-12 py-25 py-md-0 col-md-1">
						<p class="tjs-session__border-style d-flex align-items-center justify-content-center h-100 w-100 position-relative mb-0">
						</p>
					</div>
				</ng-container>
				<!-- Login form -->
				<div class="col-12 col-md-6 col-lg-5" *ngIf="section?.form">
					<form [id]="section?.form?.id" class="tjs-session__form bk-form" [formGroup]="loginForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()">
						<!-- Title -->
						<span [id]="section?.form?.title_id" class="d-inline-block mb-20" *ngIf="section?.form?.title" [innerHTML]="section?.form?.title | safeHtml"></span>
						<!-- Email -->
						<div class="mb-30" *ngIf="section?.form?.email">
							<div class="tjs-form-icon">
								<input [id]="section?.form?.email_id" class="form-control bk-input ps-50" [ngClass]="{'is-invalid': f['userName']?.touched && f['userName']?.errors}" [placeholder]="section?.form?.email?.placeholder" formControlName="userName" type="text">
								<span class="tjsicon-email tjs-icon"></span>
							</div>
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'userName'}"></ng-container>
						</div>
						<!-- Password -->
						<div class="mb-30" *ngIf="section?.form?.password">
							<div class="tjs-form-icon">
								<input [id]="section?.form?.password_id" class="form-control bk-input ps-50" type="password" [ngClass]="{'is-invalid': f['password']?.touched && f['password']?.errors}" [placeholder]="section?.form?.password?.placeholder" formControlName="password" autocomplete>
								<span class="tjsicon-key tjs-icon"></span>
							</div>
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'password'}"></ng-container>
						</div>
						<div class="d-flex justify-content-between mb-25 align-items-start">
							<!-- Submit button -->
							<bk-navigate *ngIf="section?.form?.button" [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [innerHTML]="section?.form?.button?.content" customClass="btn btn-primary border-transparent" essentialClass="bk-form-btn" [isSubmit]="true"></bk-navigate>
							<!-- Forgot password -->
							<a class="text-body" [id]="section?.form?.forgot_link_id" *ngIf="section?.form?.forgot_link" [routerLink]="prvdrAccessToken ? ['/'+initServ.appDynamicRoutes['reset-password'], userAccessToken] : ['/'+initServ.appDynamicRoutes['forgot-password']]" [queryParams]="utilServ.embedStatus ? { embed: 'true'} : prvdrAccessToken ? {type: 'provider'} : {}" [innerHTML]="section?.form?.forgot_link | safeHtml"></a>
						</div>
						<!-- Signup link -->
						<div>
							<span *ngIf="section?.form?.sign_up_link" [id]="section?.form?.sign_up_link_id" class="me-5" [innerHTML]="section?.form?.sign_up_link | safeHtml"></span>
							<a *ngIf="section?.form?.sign_up_redirect_link" [id]="section?.form?.sign_up_redirect_link_id" [routerLink]="['/'+initServ.appDynamicRoutes['signup']]" [queryParams]="utilServ.embedStatus ? { embed: 'true'} : {}" [innerHTML]="section?.form?.sign_up_redirect_link | translate | safeHtml"></a>
						</div>
					</form>
					<!-- Loader -->
					<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Form error template-->
<ng-template #errorTemp let-controlName="controlName">
	<ng-container *ngIf="f[controlName]?.touched && (f[controlName]?.errors?.required || f[controlName]?.errors?.pattern || f[controlName]?.errors?.minlength)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="f[controlName]?.errors?.required"> {{initServ.appStr?.errorMsg?.empty | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.pattern"> {{initServ.appStr?.errorMsg?.validEmail | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.minlength"> {{initServ.appStr?.errorMsg?.validPassword | translate}} </ng-container>
		</div>
	</ng-container>
</ng-template>
