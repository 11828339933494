import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
// External lib
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
// import { MatTooltipModule } from '@angular/material/tooltip';
// Services
import { PaymentGatewayServ } from '../../Services';
// Pipe
import { SafeHtmlPipe, CustomSearchPipe, FmtDatePipe, BkngCustomzLabelPipe } from '../../Pipes';
// Directive
import { GooglePlacesDirective, NumericDirective, TrimSpaceDirective, DebounceClickDirective } from 'src/app/Directives';
// Components
import { AppLoaderComponent, DateDisplayComponent, RatingDisplayComponent, TooltipComponent, PaginationComponent, UserImgComponent, BKNavigateComponent, AmountDisplayComponent, PaymentGatewayComponent, PhoneNumberComponent, TimeDisplayComponent, PopupHeaderComponent, CountdownComponent, SearchProviderComponent, BkngDiscountBarComponent, DatePickerComponent, TimePickerComponent, PrevNextPaginationComponent, CustomFieldsDisplayComponent, BKBrandingComponent, PhantomFieldComponent } from './index';

@NgModule({
	imports: [CommonModule, RouterModule, TranslateModule, TextMaskModule, MatDialogModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, NgxMatTimepickerModule],
	declarations: [
		CustomSearchPipe, SafeHtmlPipe, AppLoaderComponent, RatingDisplayComponent, TooltipComponent, DateDisplayComponent, PaginationComponent, UserImgComponent, BKNavigateComponent, AmountDisplayComponent, PaymentGatewayComponent, PhoneNumberComponent, TimeDisplayComponent, PopupHeaderComponent, CountdownComponent, SearchProviderComponent, BkngDiscountBarComponent, GooglePlacesDirective, NumericDirective, PrevNextPaginationComponent, CustomFieldsDisplayComponent, BKBrandingComponent, TrimSpaceDirective, DatePickerComponent,TimePickerComponent, FmtDatePipe, PhantomFieldComponent, DebounceClickDirective, BkngCustomzLabelPipe
	],
	exports: [
		CustomSearchPipe, SafeHtmlPipe, AppLoaderComponent, RatingDisplayComponent, TooltipComponent, DateDisplayComponent, PaginationComponent, UserImgComponent, BKNavigateComponent, AmountDisplayComponent, PaymentGatewayComponent, PhoneNumberComponent, TimeDisplayComponent, PopupHeaderComponent, CountdownComponent, SearchProviderComponent, BkngDiscountBarComponent, GooglePlacesDirective, NumericDirective, PrevNextPaginationComponent, CustomFieldsDisplayComponent, BKBrandingComponent, TrimSpaceDirective, DatePickerComponent, TimePickerComponent, FmtDatePipe, PhantomFieldComponent, DebounceClickDirective, BkngCustomzLabelPipe
	],
	providers: [PaymentGatewayServ]
})
export class GlobalDefaultModule { }
