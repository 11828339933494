import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from 'idb';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CacheService {
	private dbPromise: Promise<IDBPDatabase>;

	constructor() {
		this.dbPromise = openDB('api-cache', 1, {
			upgrade(db) {
				if (!db.objectStoreNames.contains('responses')) {
					// Fix: Explicitly set the keyPath to "url"
					db.createObjectStore('responses', { keyPath: 'url' });
				}
			}
		});
	}

	private cacheableUrls: RegExp[] = [
		new RegExp(`${env.baseUrl}/api/v\\d+/appload-customer`),
		new RegExp(`${env.baseUrl}/api/v\\d+/admin-settings-customer`),
		new RegExp(`${env.baseUrl}/bktheme/v\\d+/site/data\\?theme_slug=([a-zA-Z0-9_-]*)`),
		new RegExp(`${env.baseUrl}/bktheme/v\\d+/site/design-settings`),
		new RegExp(`${env.baseUrl}/api/v\\d+/addons`),
		new RegExp(`${env.baseUrl}/api/v\\d+/all-booking-spots-new\\?customer=(true|false)`),
		new RegExp(`${env.baseUrl}/api/v\\d+/all-booking-spots\\?customer=(true|false)`),
		new RegExp(`${env.baseUrl}/api/v\\d+/industry/\\d+/form-settings`),
		new RegExp(`${env.baseUrl}/api/v\\d+/merchant/form-settings/\\d+/\\d+/settings`)
		// new RegExp(`${env.baseUrl}/api/v\\d+/get-tax-rate\\?location_id=\\d+&postal_code=\\d+&industry_id=\\d+`),
	];

	/**
	* Determines if a URL should be cached.
	*/
	public isCacheable(url: string): boolean {
		return this.cacheableUrls.some(pattern => pattern.test(url));
	}

	/**
	 * Retrieves a cached response from IndexedDB.
	 * @param url API request URL.
	 * @returns Cached HttpResponse or null if not found.
	 */
	async get(url: string): Promise<HttpResponse<any> | null> {
		const db = await this.dbPromise;
		const cached = await db.get('responses', url);

		if (!cached) return null;

		// Check expiration
		const now = Date.now();
		if (cached.expiry && cached.expiry < now) {
			await db.delete('responses', url);
			return null;
		}

		// Convert stored headers back to HttpHeaders
		const headers = new HttpHeaders(cached.headers || {});

		return new HttpResponse({
			body: cached.body,
			status: cached.status,
			headers: headers,
		});
	}

	/**
	 * Saves an API response in IndexedDB.
	 * @param url API request URL.
	 * @param response API response to be cached.
	 * @param ttl Time-To-Live (Expiration in milliseconds, default 5 minutes) //86400000
	 */
	async set(url: string, response: HttpResponse<any>, ttl: number = 1800000): Promise<void> {
		const db = await this.dbPromise;
		const expiry = Date.now() + ttl; // Set expiration default: 5 minutes

		// Convert headers to a serializable object
		const headersObj = response.headers
			? Object.fromEntries(response.headers.keys().map(key => [key, response.headers.get(key)]))
			: {};

		await db.put('responses', {
			url, // Fix: Ensure "url" is part of the stored object (matches keyPath)
			body: response.body,
			status: response.status,
			headers: headersObj,
			expiry,
		});
	}

	/**
	 * Clears all cached responses from IndexedDB.
	 */
	async clear(): Promise<void> {
		const db = await this.dbPromise;
		await db.clear('responses');
	}

	/**
	 * Removes a specific API response from IndexedDB cache.
	 * @param url API request URL to remove from cache.
	 */
	async remove(url: string): Promise<void> {
		const db = await this.dbPromise;
		await db.delete('responses', url);
	}
}
