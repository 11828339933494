import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
// Interface
import { BkngPrice, BkngPriceFields } from 'src/app/Interfaces';
import { BkngInvDetailsApiResp, BkngInvDetailsPrice, BkngsSummCalFields, Invoice } from 'src/app/Interfaces/Invoice';
// Service
import { ApiServ, InitServ, UtilServ } from 'src/app/Services';

@Injectable({
	providedIn: 'root'
})
export class InvServ {
	// Private variables
	private destroy = new Subject<void>();
	admnStngs: any;
	bkngAmtData?: BkngInvDetailsPrice;
	hideSidebar: boolean = false;
	bkngsTipAmt: number = 0;
	priceParams: string[] = ['service_total', 'expedited_amount', 'frequency_discount', 'exempt_extras_price', 'spot_discount', 'coupon_discount', 'service_fee', 'discounted_total', 'adjusted_amount', 'sales_tax', 'tip', 'parking', 'sub_total', 'gift_card_discount', 'referral_discount', 'priceable_custom_sections', 'total_before_tax'];
	bkngsSummCalFields: BkngsSummCalFields = {
		bkng_id: {},
		summary: this.bkngPriceFields
	}
	get bkngPriceFields():BkngPriceFields{
		return {
			service_total: 0,
			expedited_amount: 0,
			frequency_discount: 0,
			exempt_extras_price: 0,
			spot_discount: 0,
			coupon_discount: 0,
			service_fee: 0,
			discounted_total: 0,
			adjusted_amount: 0,
			sales_tax: 0,
			tip: 0,
			parking: 0,
			sub_total:0,
			gift_card_discount: 0,
			referral_discount: 0,
			priceable_custom_sections :0,
			total_before_tax: 0
		}
	}
	formParamsName: {[key: string]: string} = {
		booking_id: 'Booking ID',
		service_date: 'Service date',
		industry: 'Industry',
		frequency: 'Frequency',
		service: 'Service category',
		pricing_parameter: 'Pricing parameter',
		excludes: 'Excludes',
		items: 'Items',
		packages: 'Packages',
		extras: 'Extras',
		custom_section: 'Custom section',
		total: 'Total',
		service_total: 'Service total',
		referral_discount: 'Referral discount',
		expedited_amount: 'Expedited amount',
		frequency_discount: 'Frequency discount',
		exempt_extras_price: 'Exempted extras',
		spot_discount: 'Spot discount',
		coupon_discount: 'Coupon discount',
		service_fee: 'Service fee',
		discounted_total: 'Discounted total',
		adjusted_amount: 'Adjusted amount',
		sales_tax: 'Sales tax',
		tip: 'Tip',
		parking: 'Parking',
		gift_card_discount: 'Gift card discount',
		sub_total: 'Sub total',
		priceable_custom_sections: 'Priceable custom sections',
		total_before_tax:'Total before tax'
	}

	// eslint-disable-next-line max-params
	constructor(private initServ: InitServ,private utilServ: UtilServ, private frmBldr: FormBuilder, public router: Router, private apiServ: ApiServ) {
		this.admnStngs = this.initServ.appAdmnStngs;
	}

	public getStoreLogo(): any {
		if(this.admnStngs?.merchant_settings?.store?.store_logo){
			return this.admnStngs.merchant_settings.store.store_logo;
		}
		return null;
	}

	public generateImgTag(logo: { id: string, width: string }, imgBase: string, src: string): string {
		return `<img loading='lazy' id=${logo?.id} width=${logo?.width} height='auto' src=${imgBase}${src} alt='logo' />`;
	}

	/**
	 * Check if this.admnStngs.merchant_settings.store[storeField] exists,
	 * if not, set val to an empty string
	 * Generate a dynamic string using a utility function (generateDynamicStr)
	 * Replace occurrences of '{{.${shortCode}}}' in content with val
	 * @param content: content
	 * @param shortCode: shortcode
	 * @param storeField: store fields
	 * @returns string
	 */
	public tokenReplace(content: string, shortCode: string, storeField: string): string {
		if(content) {
			let val: string = (this.initServ.appAdmnStngs?.merchant_settings?.store?.[storeField]) ?? '';
			return this.utilServ.generateDynamicStr(content, '{{.'+shortCode+'}}', val)
		}
		return '';
	}

	/**
	 * Get the invoice remaining total in case of custom invoice
	 * @param invData: Invoice data
	 * @returns invoice total
	 */
	public calInvRemainingTotal(invData: any): any {
		// Initialize remaining with the total amount from invData if available
		let remaining: number = invData?.total_amount;
		// Check if paid_amount exists in invData
		if(invData?.paid_amount){
			// Subtract tip amount if present
			if(invData?.tip?.pay){
				remaining = this.utilServ.roundToTwo(remaining - invData.tip.pay);
			}
			return this.utilServ.roundToTwo(remaining - invData.paid_amount);
		}
		return remaining;
	}

	/**
	 * Calculate the invoice total amount
	 * @param invData: invoice data
	 * @returns invoice total
	 */
	public calInvTotal(invData: any): any {
		let invTotal: number = invData?.total_amount;
		if(invData?.paid_amount && invData?.tip?.pay){
			invTotal = this.utilServ.roundToTwo(invTotal - invData.tip.pay);
		}
		return invTotal;
	}

	/**
	 * Build form and load uniquesLoc fucntion in case of stripe
	 */
	public buildInvPaymentForm(): any {
		return this.frmBldr.group({
			location_id: [null],
			pay_with_cc: [],
			payment_method: ['existing_card'],
			card_last4_digits:['']
		});
	}

	/**
	 * Invoice amount payload
	 * @param invData: invoice data
	 * @returns payload
	 */
	public invAmtPayload(invData: Invoice): Invoice {
		let payload: Invoice = {
			inv_id: invData?.inv_id,
			inv_num: invData?.inv_num,
			payment_type: invData?.payment_type,
			no_of_payments :invData?.no_of_payments,
			paid_amount: invData?.paid_amount,
			has_tip: invData?.has_tip
		}
		payload = this.invSettPayload(invData, payload)
		return payload;
	}

	/**
	 * Sets the payload for invoice data.
	 * If the invoice type is 'custom', it calculates the invoice
	 * total and adds it to the payload. Otherwise, it generates
	 * the payload for booking data. It also includes tip payload
	 * and partial payment flag if applicable.
	 * @param invData The invoice data.
	 * @param payload The invoice payload.
	 * @returns The updated invoice payload.
	 */
	private invSettPayload(invData: Invoice, payload: Invoice): Invoice {
		if(invData?.inv_type == 'custom'){
			payload['inv_amount'] = this.calInvTotal(invData);
		} else {
			payload = this.bkngPayload(invData, payload);
		}
		payload = this.tipPayload(invData, payload);
		if(invData?.settings?.partial_pay){
			payload['partial_pay'] =  invData.settings.partial_pay;
		}
		return payload;
	}

	/**
	 * Tip payload data
	 * @param invData: Invoice data
	 * @param payload: payload
	 * @returns payload
	 */
	public tipPayload(invData: Invoice, payload: Invoice): Invoice {
		if(invData?.tip?.pay && !invData?.no_of_payments){
			payload['tip'] = invData.tip;
			payload['tip_provider_ids'] = invData.settings?.tip_settings?.prov_ids;
		}
		return payload;
	}

	/**
	 * Booking payload data
	 * @param invData: Invoice data
	 * @param payload: Payload data
	 * @returns payload
	 */
	public bkngPayload(invData: Invoice, payload: Invoice): any {
		if(this.isObjExist(invData?.bookings_tip) && !invData?.no_of_payments){
			payload['bookings_tip'] = invData.bookings_tip;
		}
		if(this.utilServ.checkArrLength(invData?.booking_ids)){
			payload['booking_ids'] = invData.booking_ids;
		}
		return payload;
	}

	/**
	 * Checks if an object exists and is not empty.
	 * Returns true if the object exists and has properties,
	 * otherwise returns false.
	 * @param obj The object to check.
	 * @returns True if the object exists and is not empty, false otherwise.
	 */
	public isObjExist(obj: any): boolean {
		return (obj && Object.keys(obj)?.length !== 0);
	}

	/**
	 * Checks if 3D Secure is enabled.
	 * Returns true if 3D Secure is enabled and the payment gateway
	 * is not 'authorizedotnet', otherwise returns false.
	 * @returns True if 3D Secure is enabled and the payment gateway is not 'authorizedotnet', false otherwise.
	 */
	public isThreeDS(): boolean {
		return (this.initServ.threeDSecure && this.initServ.paymentGateway !== 'authorizedotnet');
	}

	/**
	 * Invoice charge payload
	 * @param invData: Invoice data
	 * @param formVal: Invoice payment form values
	 * @param chargedAmt: Changed amount data in case of 3DS
	 * @returns payload
	 */
	public invChargePayload(invData: any, formVal: any, chargedAmt: any): any {
		// Create an object to store payment-related data
		let chargePayload: any = {
			inv_id: invData?.inv_id,
			inv_num: invData?.inv_num,
			payment_type: invData?.payment_type,
			payment_method: formVal.payment_method,
			inv_amount: this.calInvTotal(invData),
			uid: invData?.uid,
			charged_amount: chargedAmt?.amount, // In case of 3DS
			location_id: +formVal.location_id,
			has_tip: invData?.has_tip,
			billing_address: formVal?.billing_address
		};
		// Check invoice type for bookings and handle accordingly
		chargePayload = this.getBkngPayload(invData, chargePayload, chargedAmt);
		// Include tip-related data in the payload
		chargePayload = this.tipPayload(invData, chargePayload);
		return chargePayload;
	}

	/**
	 * Retrieves the booking payload based on invoice data, charge payload, and charged amount.
	 * If the invoice type is 'bookings', it adds the captured amount in case of card hold and generates
	 * the payload specific to bookings using the 'bkngPayload' function.
	 * @param invData The invoice data.
	 * @param chargePayload The payload containing transaction details.
	 * @param chargedAmt The charged amount data.
	 * @returns The updated charge payload.
	 */
	private getBkngPayload(invData: any, chargePayload: any, chargedAmt: any): any {
		if(invData?.inv_type == 'bookings'){
			// captured amount in case of card hold
			if(chargedAmt?.captured_amount){
				chargePayload['captured_amount'] = chargedAmt.captured_amount;
			}
			// Generate payload specific to bookings
			chargePayload = this.bkngPayload(invData, chargePayload);
		}
		return chargePayload;
	}

	/**
	 * Redirects the user based on the payment information section and the current user.
	 * Retrieves the redirect URL from the payment information section and determines
	 * the type of redirect based on the 'link_to' property. If 'link' or 'web', it
	 * redirects directly to the link URL. If 'page', it generates the redirect URL
	 * after success redirection and redirects accordingly. If none of the above cases
	 * match, it redirects the user to the dashboard if logged in, otherwise to the login page.
	 * @param section The payment information section.
	 * @param currentUser The current user information.
	 */
	public invRedirectUser(section: any, currentUser: any) {
		let redirectUrl: any = section?.payment_info?.redirect_link?.link_url;
		let goToLinkUrl: any = null;
		switch (section?.payment_info?.redirect_link?.link_to){
			case 'link':
			case 'web':
				// eslint-disable-next-line no-case-declarations
				let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
				this.utilServ.redirect(linkUrl);
				break;
			case 'page':
				goToLinkUrl = this.utilServ.getAfterSuccessRedirection(redirectUrl);
				if(goToLinkUrl){
					this.utilServ.redirect(this.utilServ.generateLink(goToLinkUrl));
				}
			break;
			default:
				if(currentUser){
					this.router.navigate([`/${this.initServ.appDynamicRoutes['dashboard']}`]);
				} else {
					this.router.navigate([`/${this.initServ.appDynamicRoutes['login']}`]);
				}
			break;
		}
	}

	/**
	 * Fetches the booking invoice amount from the API.
	 * Constructs the payload if not provided and makes a POST request to 'BkngInvDetails' endpoint.
	 * Subscribes to the API response and handles it using 'bkngResHandler'.
	 * @param payload The payload for the API request.
	 * @param invData The invoice data.
	 */
	public fetchBkngInvAmt(payload: any = null, invData: any = null): void {
		payload = payload ? payload : this.invAmtPayload(invData)
		this.apiServ.callApi('POST', 'BkngInvDetails',payload).pipe(takeUntil(this.destroy)).subscribe((resp: BkngInvDetailsApiResp) => this.handleBkngResp(resp));
	}

	/**
	 * Handles the API response for booking total.
	 * If the API response is successful and data is available,
	 * it assigns the booking amount data to 'bkngAmtData'.
	 * @param res The API response.
	 */
	private handleBkngResp(res: BkngInvDetailsApiResp): void {
		if(this.apiServ.checkAPIRes(res) && res?.data){
			this.bkngAmtData = res.data;
		}
	}

	// Price Functions

	/**
	 * Updates the summary fields for a booking based on pricing and other related data.
	 * This function performs the following updates:
	 * 1. Service total: Adds the updated service price to the total.
	 * 2. Frequency discount: Adds the frequency discount (if available) to the total.
	 * 3. Referral discount: Calculates and applies the referral discount.
	 * 4. Expedited amount: Calculates and applies the expedited amount for the booking.
	 * 5. Exempt extras price: Calculates and adds the exempt extra amount for the booking.
	 * 6. Adjusted amount: Calculates the adjusted amount based on specific logic.
	 * 7. Spot discount: Applies any applicable spot discounts.
	 * 8. Service fee: Calculates and applies the service fee.
	 * 9. Priceable custom sections: Calculates and includes custom priceable fields in the summary.
	 * 10. Command fields: coupon_discount, discounted_total, sales_tax, tip, parking, gift_card_discount, sub_total and total_before_tax
	 *
	 * Additionally, the function loops through a list of specific price-related fields and updates them
	 * based on the corresponding values in the `bkngPriceObj`.
	 *
	 * @param bkngPriceObj - The object containing the price-related information for the booking.
	 * @param bkng - The booking object, used for some calculations related to discounts and other fields.
	 * @returns {BkngPriceFields} - The updated booking summary fields.
	 */
	public updateBkngSummaryFields(bkngPriceObj:BkngPrice, bkng: any): BkngPriceFields {
		// Destructure frequently accessed properties
		let { updatedDisplayServicePrice, displayFrequencyDiscount } = bkngPriceObj ?? {};
		let priceFields: BkngPriceFields = this.bkngPriceFields;
		// Service total
		priceFields.service_total = this.utilServ.roundToTwo(priceFields.service_total + (updatedDisplayServicePrice ?? 0));
		// Frequency discount
		priceFields.frequency_discount =  this.utilServ.roundToTwo(priceFields.frequency_discount + (displayFrequencyDiscount ?? 0));

		priceFields.referral_discount = this.calSummReferralDisc(priceFields, bkng, bkngPriceObj);
		priceFields.expedited_amount = this.calSummExpeditedAmt(priceFields, bkng);
		priceFields.exempt_extras_price = this.calSummExemptExtraAmt(priceFields, bkng);
		priceFields.adjusted_amount = this.calSummAdjustedAmt(priceFields, bkng);
		priceFields.spot_discount = this.calSummSpotDisc(priceFields, bkng, bkngPriceObj);
		priceFields.service_fee = this.calSummServiceFee(priceFields, bkng, bkngPriceObj);
		priceFields.priceable_custom_sections = this.calSummPriceableCustomFields(bkngPriceObj);

		// Command fields
		let fields: {priceFieldName: keyof BkngPrice;storeFieldName: keyof BkngPriceFields;}[] = [
			{ priceFieldName: 'displayCouponDiscount', storeFieldName: 'coupon_discount' },
			{ priceFieldName: 'displayDiscountedAmount', storeFieldName: 'discounted_total' },
			{ priceFieldName: 'displaySaleTax', storeFieldName: 'sales_tax' },
			{ priceFieldName: 'displayTipsAmount', storeFieldName: 'tip' },
			{ priceFieldName: 'displayParkingAmount', storeFieldName: 'parking' },
			{ priceFieldName: 'displayGiftCardAmount', storeFieldName: 'gift_card_discount' },
			{ priceFieldName: 'displayTotal', storeFieldName: 'sub_total' },
			{ priceFieldName: 'displayTotalBeforeTax', storeFieldName: 'total_before_tax' }
		];
		// Loop through each field and update it
		for (let field of fields) {
			priceFields[field.storeFieldName] = this.calSummCommanFields(bkngPriceObj, field.priceFieldName);
		}
		return priceFields;
	}

	/**
	 * This function calculates and updates the referral discount for a booking summary.
	 * It checks if a referral discount is available in the booking object or in the `singleBkngPriceObj` object.
	 * The discount is added to the `referral_discount` field in the `priceFields` and rounded to two decimal places.
	 *
	 * @param bkng - The booking object that may contain a `referral_discount` property.
	 * @param singleBkngPriceObj - The object that may contain a `displayReferralDiscount` property.
	 */
	private calSummReferralDisc(priceFields: BkngPriceFields, bkng: any, singleBkngPriceObj: BkngPrice): number {
		if (bkng?.referral_discount) {
			return this.utilServ.roundToTwo(priceFields.referral_discount + bkng.referral_discount);
		} else if (singleBkngPriceObj?.displayReferralDiscount) {
			return this.utilServ.roundToTwo(priceFields.referral_discount + singleBkngPriceObj.displayReferralDiscount);
		}
		return 0;
	}

	/**
	 * This function calculates and updates the expedited amount for a booking summary.
	 * It checks if the booking is marked as a same-day booking and whether it qualifies for an expedited charge.
	 * If both conditions are met, the expedited amount is added to the `expedited_amount` field in the `priceFields` and rounded to two decimal places.
	 *
	 * @param bkng - The booking object containing the necessary information for expedited charge calculation.
	 */
	private calSummExpeditedAmt(priceFields:BkngPriceFields, bkng: any): number {
		if (bkng?.same_day_booking && this.isExpeditedAmt(bkng)) {
			return this.utilServ.roundToTwo(priceFields.expedited_amount + bkng.expedited_amount);
		}
		return 0;
	}

	/**
	 * This function checks whether a booking qualifies for an expedited amount charge.
	 * It returns `true` if the booking has a valid `expedited_amount` and does not exclude the expedited charge.
	 *
	 * @param bkng - The booking object containing the necessary properties to determine if an expedited charge applies.
	 * @returns `true` if an expedited amount is applicable, otherwise `false`.
	 */
	private isExpeditedAmt(bkng: any): boolean {
		return bkng?.expedited_amount && !bkng?.exclude_expedited_charge;
	}

	/**
	 * This function calculates and updates the exempt extras price for a booking summary.
	 * If the booking has an `exempt_extras_price`, it adds it to the `exempt_extras_price` field in the `priceFields`
	 * and rounds the result to two decimal places.
	 *
	 * @param bkng - The booking object containing the exempt extras price to be added.
	 */
	private calSummExemptExtraAmt(priceFields: BkngPriceFields, bkng: any): number {
		if (bkng?.exempt_extras_price) {
			return this.utilServ.roundToTwo(priceFields.exempt_extras_price + bkng.exempt_extras_price);
		}
		return 0;
	}

	/**
	 * This function calculates and updates the adjusted amount for a booking summary.
	 * If the booking has an `adjusted_price`, it adds it to the `adjusted_amount` field in the `priceFields`
	 * and rounds the result to two decimal places.
	 *
	 * @param bkng - The booking object containing the adjusted price to be added.
	 */
	private calSummAdjustedAmt(priceFields: BkngPriceFields, bkng: any): number {
		if (bkng?.adjust_price) {
			return this.utilServ.roundToTwo(priceFields.adjusted_amount + bkng.adjusted_price);
		}
		return 0;
	}

	/**
	 * This function calculates and updates the spot discount for a booking summary.
	 * If the `displaySpotDiscount` is greater than zero and the booking does not exclude the day discount,
	 * the discount is added to the `spot_discount` field in the `priceFields` and rounded to two decimal places.
	 *
	 * @param bkng - The booking object that contains the exclusion flag for the day discount (`exclude_day_discount`).
	 * @param singleBkngPriceObj - The object containing the `displaySpotDiscount` value.
	 */
	private calSummSpotDisc(priceFields: BkngPriceFields, bkng: any, singleBkngPriceObj: BkngPrice): number {
		if (singleBkngPriceObj?.displaySpotDiscount > 0 && !bkng?.exclude_day_discount) {
			return this.utilServ.roundToTwo(priceFields.spot_discount + singleBkngPriceObj.displaySpotDiscount);
		}
		return 0;
	}

	/**
	 * This function calculates and updates the service fee for a booking summary.
	 * If the booking has a `service_fee` and does not exclude the service fee,
	 * the fee is added to the `service_fee` field in the `priceFields` and rounded to two decimal places.
	 *
	 * @param bkng - The booking object containing the service fee flag (`service_fee`) and exclusion flag (`exclude_service_fee`).
	 * @param singleBkngPriceObj - The object containing the `displayServiceFee` value.
	 */
	private calSummServiceFee(priceFields: BkngPriceFields, bkng: any, singleBkngPriceObj: BkngPrice): number {
		if (bkng?.service_fee && !bkng?.exclude_service_fee) {
			return this.utilServ.roundToTwo(priceFields.service_fee + singleBkngPriceObj?.displayServiceFee);
		}
		return 0;
	}

	/**
	 * This function calculates the total price for custom fields that are priceable and updates the corresponding field
	 * in the `priceFields` object.
	 * It checks specific sections in the `displayPriceableCustomFields` of `singleBkngPriceObj`, and if the section contains
	 * priceable fields, it sums their prices to calculate the total value.
	 *
	 * @param bkngPriceObj - The booking price object containing the priceable custom fields.
	 */
	private calSummPriceableCustomFields(bkngPriceObj: BkngPrice): number {
		let priceableCustomFields: number = 0;
		let sections: string[] = ['include_in_freq_disc', 'exempt_from_freq_disc', 'after_discounted_total', 'non_taxable'];
		for(let sec of sections){
			if(bkngPriceObj?.displayPriceableCustomFields && this.utilServ.checkArrLength(bkngPriceObj.displayPriceableCustomFields?.[sec])){
				for(let field of bkngPriceObj.displayPriceableCustomFields[sec]){
					priceableCustomFields += field.fieldPrice;
				}
			}
		}
		return priceableCustomFields;
	}

	/**
	 * This function calculates and updates common fields for a booking summary.
	 * It checks if the price field exists in the `singleBkngPriceObj` and, if so,
	 * adds the value to the corresponding field in `priceFields` after rounding it to two decimal places.
	 *
	 * @param singleBkngPriceObj - The object containing the price field to be updated.
	 * @param priceFieldName - The name of the price field in `singleBkngPriceObj` to be added.
	 */
	private calSummCommanFields(singleBkngPriceObj: BkngPrice, priceFieldName: keyof BkngPrice): number {
		if (singleBkngPriceObj?.[priceFieldName]) {
			return this.utilServ.roundToTwo(singleBkngPriceObj[priceFieldName] as number);
		}
		return 0;
	}
}
