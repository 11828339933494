import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
// Interfaces
import { FormPricingParams, APIRes, PricingParamValue, ServiceConfig, BkngPrice, FormParamNameById, NumberKeyStringMap, FormParamLimData, BkngPriceFields, BkngsSummCalFields } from 'src/app/Interfaces';
// Services
import { ApiServ, BkngFormServ, BkngListPriceCalServ, BuildCustomSectionService, InitServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';
import { InvServ } from 'src/app/Session/Invoices';
// Local interfaces
interface PricingParamApiRes extends APIRes {
	data?: FormPricingParams[]
}
interface StaticPricingParam {
	id?: number;
	cat_name?: string;
	value?: {[key: string]: PricingParamValue;} | null
}
interface PricingParamsObj {
	[key: string]: StaticPricingParam;
}
interface FormParamLimDataApiResp extends APIRes{
	data?: FormParamLimData | undefined | null;
}

@Component({
	selector: 'bk-inv-from-bkng-table',
	templateUrl: './InvFromBkngTable.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvFromBkngTableComponent implements OnInit {

	@Input() invSec : any;
	@Input() invData: any;
	@Input() invForm!: FormGroup;
	@Output() bkngsTipAmt: EventEmitter<number> = new EventEmitter<number>();
	@Output() summBkngFieldsChange: EventEmitter<BkngPriceFields> = new EventEmitter<BkngPriceFields>();
	bkngs: any[] = [];
	elementName: FormParamNameById | null = null;
	offset: number = 0;
	limit: number = 10;
	bkngIdsBatches: number[][] | undefined;
	loaderId: string = 'bkng-ids-loader';
	customFieldsBids: number[] = [];
	pricingParams: PricingParamsObj | null = null;
	isPlanPermission!: boolean;
	industryForms: number[] = [];
	idByServiceCat: {[key: string]: ServiceConfig };
	bkngPriceObj: Record<string, BkngPrice> = {};
	bkngsSummCalFields: BkngsSummCalFields = this.invServ.bkngsSummCalFields;

	// convenience getter for easy access to form fields
	get bkngTipGroup(): FormGroup {
		return <FormGroup>this.invForm.controls['bookings_tip'];
	}
	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, public bkngFormServ: BkngFormServ, public initServ: InitServ, private loader: LoaderServ,private apiServ: ApiServ, private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, private frmBldr: FormBuilder, public invServ: InvServ, public bkngCustSecServ: BuildCustomSectionService, private el: ElementRef, public listPriceCal: BkngListPriceCalServ) {
		this.isPlanPermission = this.initServ.appPlansPermission('Invoice Charges Settings');
		this.industryForms = this.bkngFormServ.getIndustryForm();
		this.idByServiceCat = this.bkngFormServ.buildIdByServiceCat();
	}

	ngOnInit(): void {
		this.invForm.addControl('bookings_tip', this.frmBldr.group({}));
		this.getPricingParamsApi();
		this.buildTable();
		this.getFormParamsApi();
	}

	/**
	 * Fetch the pricing params
	 */
	private getPricingParamsApi(): void {
		// API HIT in case of form 1 and form 2
		if (this.invData?.status != 3 && [1, 4].some((formId: number) => this.industryForms.includes(formId))) {
			this.apiServ.callApi('GET', 'PricingParams').pipe(takeUntil(this.destroy)).subscribe((resp: PricingParamApiRes) => this.handlePricingParamsApiResp(resp));
		}
	}

	/**
	 * Handles the response from the pricing parameters API and updates the global pricing parameters accordingly.
	 * @param res The response from the pricing parameters API.
	 */
	private async handlePricingParamsApiResp(resp: PricingParamApiRes): Promise<void> {
		if (this.apiServ.checkAPIRes(resp) && resp?.data) {
			this.pricingParams = await this.createGlobalPricingParam(resp.data);
			this.cDRef.detectChanges();
		}
	}

	/**
	 * Create global object for pricing parameters.
	 * @param industryId : industry id
	 * @param formId : Form id
	 * @param settings : Industry form settings
	 * @returns settings object
	 */
	public async createGlobalPricingParam(pricingParams: FormPricingParams[]): Promise<PricingParamsObj> {
		let settingsObj: PricingParamsObj = {}
		if (this.utilServ.checkArrLength(pricingParams)) {
			for (let pricingParam of pricingParams) {
				let obj: StaticPricingParam = await this.setObjForParam(pricingParam, {});
				if (+pricingParam?.form_id == 1) {
					settingsObj[pricingParam.id] = obj;
				} else {
					settingsObj[`${pricingParam?.industry_id}_${pricingParam?.form_id}`] = obj;
				}
			}
		}
		return settingsObj
	}

	/**
	 * Sets the pricing parameter object with the provided values.
	 *
	 * @param pricingParam - The pricing parameter object containing form and industry IDs, and values.
	 * @param obj - The static pricing parameter object to be populated with the pricing parameter data.
	 * @returns The updated static pricing parameter object with ID, category name, and value mappings.
	 */
	private setObjForParam(pricingParam: FormPricingParams, obj: StaticPricingParam): StaticPricingParam {
		if (this.utilServ.checkArrLength(pricingParam?.values)) {
			obj['id'] = pricingParam.id;
			obj['cat_name'] = pricingParam.name,
			obj['value'] = {};
			for (let section of pricingParam.values) {
				obj.value[section.id] = section;
			}
		}
		return obj;
	}

	/**
	 * Builds the invoice booking table by setting the table heading and fetching bookings.
	 * This function is responsible for initializing the table structure and data based on pre-filled data.
	 * It clears the current bookings and triggers the fetching of new bookings if necessary.
	 *
	 * @returns {void} - This function does not return anything; it updates the component's state and triggers change detection.
	 */
	private buildTable(): void {
		if(this.invData && this.utilServ.checkArrLength(this.invData?.booking_ids)){
			// Build the bookings ids chunk
			this.bkngIdsBatches = this.buildBkngsIdsChunk();
			if(this.utilServ.checkArrLength(this.bkngIdsBatches)){
				this.getCustBkngsApi();
				this.getCustomSec();
			}
		}
	}

	/**
	 * Get the booking ids array or array with the limit of 10
	 * Get the booking IDs from invData
	 * Array to store resulting chunks
	 * Check if booking IDs exist and exceed the limit
	 * @returns Return the resulting chunks
	 */
	private buildBkngsIdsChunk():any {
		let bkngIds: number[] = this.invData?.booking_ids;
		let result: number[][] = [];
		if(bkngIds.length > this.limit){
			//Calculate the total number of chunks needed
			let totalPages: number = Math.ceil(bkngIds.length / this.limit);
			//Iterate through each chunk
			for (let i = 0; i < totalPages; i++) {
				let startIndex: number = i * this.limit;
				let endIndex: number = Math.min(startIndex + this.limit, bkngIds.length);
				// Slice the booking IDs to create a chunk and add it to the result array
				let chunk: number[] = bkngIds.slice(startIndex, endIndex);
				result.push(chunk);
			}
		} else {
			result.push(bkngIds);
		}
		return result;
	}

	/**
	 * Fetches customer bookings based on the provided prefilled data and status.
	 * This function performs the following:
	 * 1. Checks if the `prefilledData.status` is not `3`(cancelled).
	 * 2. Displays a loading indicator using `this.loader.show` with a specified loader ID.
	 * 3. Makes a `POST` request to the `CustBookings` API endpoint, passing the `uid` from `prefilledData` and the payload generated by `custRecSchBkngsPayload`.
	 * 4. Subscribes to the API response and processes the result using the `handleBkngRes` method.
	 *
	 * This function is responsible for retrieving customer bookings data and handling the API response.
	 * @returns {void} - This method does not return anything but triggers an API call and handles the response.
	 */
	public getCustBkngsApi(): void {
		if(!(this.invData?.status !== 3 && this.bkngIdsBatches)) {
			return;
		}
		this.loader.show(this.loaderId);
		let payload = {
			uid: this.invData.uid,
			bids: this.bkngIdsBatches[this.offset]
		}
		this.fetchBkngsCustomFields(payload?.bids);
		this.apiServ.callApi('POST', 'CustBookings', payload).pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.handleBkngResp(resp));
	}

	/**
	 * Fetches custom fields for the specified booking IDs if certain conditions are met.
	 *
	 * @param bids - An array of booking IDs for which custom fields need to be fetched.
	 * The function checks if these IDs are valid and if the custom section is present.
	 * @returns void - This function does not return anything; it updates the component's state
	 * by fetching custom fields and setting the `isCustomFields` flag to true.
	 */
	private fetchBkngsCustomFields(bids: number[]): void {
		if(this.utilServ.checkArrLength(bids) && this.isCustomSec()){
			this.customFieldsBids = Array.from(new Set([...this.customFieldsBids, ...bids]));
			this.bkngCustSecServ.fetchBkngsCustomFields(this.customFieldsBids, this.cDRef);
		}
	}

	private isCustomSec(): boolean {
		return (this.utilServ.checkArrLength(this.invData?.form_params) && this.invData?.form_params.includes('custom_section'));
	}

	/**
	 * Handles the response from the booking API call.
	 * Updates the list of bookings and determines whether to show a "Load More" button.
	 * Emits the updated bookings data and updates recurring scheduled bookings IDs.
	 *
	 * @param res - The response object from the booking API call.
	 */
	private handleBkngResp(resp: any): void {
		if(this.apiServ.checkAPIRes(resp) && this.utilServ.checkArrLength(resp?.data)){
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(resp.data, this.bkngs);
			this.bkngs = loaMoreData.items;
			this.addTipControl();
			// Build pricing fields
			this.updateBkngPricesAndSummary();
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}

	private addTipControl(): void {
		if(this.invData?.settings?.tip_settings?.pay_type == 'specific' && this.bkngIdsBatches) {
			for(let bkng of this.bkngIdsBatches[this.offset]){
				this.bkngTipGroup.addControl(bkng.toString(), this.frmBldr.group({
					pay: [null],
					unit: ['amount']
				}))
			}
		}
	}

	// PRICE FUNCTIONS

	/**
	 * This function calculates and updates the pricing details for each booking in the list.
	 * It performs the following:
	 * 1. Initializes the booking price object and summary fields.
	 * 2. Loops through each booking (`bkng`), calculates the price details, tips, parking bonus, and updates the relevant fields.
	 * 3. Calls the `updateBkngSummary` method to update the summary for each booking.
	 * * Modifies:
	 * - `this.bkngPriceObj`: Object to store booking price details.
	 * - `this.bkngsSummCalFields`: Summary booking total fields.
	 * 4. Emits the summary fields change and triggers change detection after all calculations.
	 */
	private async updateBkngPricesAndSummary(): Promise<void> {
		this.bkngPriceObj = {};
		this.bkngsSummCalFields = { ...this.invServ.bkngsSummCalFields };

		if (this.utilServ.checkArrLength(this.bkngs)) {
			let pricePromises = this.bkngs.map(async (bkng) => {
				try {
					let bkngId: number = bkng._id;
					let calcPriceDetailObj: { priceLocalVar: BkngPrice } = this.listPriceCal.calcPriceDetails(this.listPriceCal.getCmnListPriceVar, bkng, this.listPriceCal.getCmnPrvdrPayDtls);
					let calcTipParkObj: { priceLocalVar: BkngPrice } = this.listPriceCal.calculateTipsParkingBonus(calcPriceDetailObj.priceLocalVar, bkng);
					let updatedPrice: BkngPrice = calcTipParkObj.priceLocalVar;
					updatedPrice = await this.listPriceCal.updateServPriceAccPriceableFields(calcPriceDetailObj.priceLocalVar, updatedPrice, { bkng, selectedService: this.idByServiceCat[bkngId] });
					updatedPrice['displayTotalBeforeTax'] = await this.listPriceCal.getTotalWithoutTax(updatedPrice);
					this.bkngPriceObj[bkngId] = updatedPrice;

					if(!this.bkngsSummCalFields.bkng_id){
						this.bkngsSummCalFields.bkng_id = {};
					}
					// Build summary for the booking
					this.bkngsSummCalFields.bkng_id[bkngId] = this.invServ.updateBkngSummaryFields(this.bkngPriceObj[bkngId], bkng);
					await this.setSummaryCalFields(bkngId);
				} catch (_error) { /* empty */ }
			});

			// Wait for all promises to resolve
			await Promise.all(pricePromises);
		}
		this.summBkngFieldsChange.emit(this.bkngsSummCalFields.summary);
		this.cDRef.detectChanges();
	}

	/**
	 * Updates the summary calculation fields for a booking by adding the values from the booking's fields.
	 *
	 * @param {number} bkngId - The ID of the booking for which the summary fields are being updated.
	 * @returns {void} - This function does not return anything; it updates the `bkngsSummCalFields` object.
	 */
	private setSummaryCalFields(bkngId: number): void {
		let bkngFields: BkngPriceFields = this.bkngsSummCalFields.bkng_id[bkngId];
		for (let key of Object.keys(this.invServ.bkngPriceFields) as Array<keyof BkngPriceFields>) {
			if (bkngFields[key]) {
				this.bkngsSummCalFields.summary[key] += bkngFields[key] as number;
			}
		}
	}

	//Select custom section td to apply class conditionally
	private getCustomSec(): void{
		setTimeout(() => {
			let element =  this.el.nativeElement.querySelector('#custom_section');
			if(element){
				element.classList.add('w-250');
			}
		},500)
	}

	/**
	 * Fetch the form params
	 */
	private getFormParamsApi(): void {
		this.apiServ.callApi('GET', 'FormParams').pipe(takeUntil(this.destroy)).subscribe((resp: FormParamLimDataApiResp) => this.formParamsApiResp(resp));
	}

	/**
	 * Handles the response from the form parameters API.
	 * @param res The response from the form parameters API.
	 */
	private formParamsApiResp(resp: FormParamLimDataApiResp): void {
		if(this.apiServ.checkAPIRes(resp) && resp?.data){
			this.elementName = this.getFormElementName(resp.data);
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Get the form element name
	 * @returns object
	 */
	public getFormElementName(data: FormParamLimData): FormParamNameById {
		let formParamObj: FormParamNameById = {'service_category':{}, 'form_frequencies':{}, 'extras':{}, 'excludes':{}, 'package':{}, 'items':{}, 'package_addons':{}, 'addons':{}};
		let types: (keyof FormParamNameById)[] = ['service_category', 'form_frequencies', 'extras', 'excludes', 'package', 'items', 'package_addons', 'addons'];
		for(let type of types){
			if(data?.[type as keyof FormParamLimData] && this.utilServ.checkArrLength(data[type as keyof FormParamLimData])) {
				for(let val of data[type as keyof FormParamLimData]) {
					(formParamObj[type] as NumberKeyStringMap)[val?.id] = this.utilServ.getFormParamName(val);
				}
			}
		}
		return formParamObj;
	}

	/**
	 * Load more
	 */
	public loadMoreBkngs(): void {
		this.offset++;
		this.getCustBkngsApi();
	}

	/**
	 * Calculate total tip amount for bookings.
	 * Emits the total tip amount.
	 */
	public calBkngTip(): void {
		this.invServ.bkngsTipAmt = 0;
		for(let bid in this.bkngTipGroup.value){
			if(this.bkngTipGroup.value[bid]?.pay){
				this.invServ.bkngsTipAmt += +(this.bkngTipGroup.value[bid]?.pay);
			}
		}
		this.bkngsTipAmt.emit(this.invServ.bkngsTipAmt);
	}
}
