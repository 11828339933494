import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class CookieServ {
	/**
	 * Creates a cookie with the specified name, value, expiration days, and domain.
	 * @param {string} name - The name of the cookie.
	 * @param {any} value - The value to be stored in the cookie.
	 * @param {number} days - The number of days until the cookie expires.
	 * @param {string} domain - (Optional) The domain for which the cookie is valid.
	 */
	public createCookie(name: string, value: any, days: number, isSecure: boolean = false): void {
		let expires: string;
		// Check if days is provided
		if (days) {
			// Calculate the expiration date
			let date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = `; expires=${date.toUTCString()}`;
		} else {
			expires = "";
		}
		document.cookie = isSecure ? `${name}=${value}${expires}; path=/; SameSite=none;Secure` : `${name}=${value}${expires}; path=/`;
	}

	/**
	 * Retrieves the value of a cookie by its name.
	 * @param {string} c_name - The name of the cookie to retrieve.
	 * @returns {string} - The value of the cookie, or an empty string if the cookie is not found.
	 */
	public getCookie(c_name: string): string {
		// Check if document has any cookies stored
		if (document.cookie.length > 0) {
			// Find the starting index of the cookie with the provided name
			let c_start = document.cookie.indexOf(c_name + "=");
			if (c_start != -1) {
				// Adjust starting index to point to the value of the cookie
				c_start = c_start + c_name.length + 1;
				// Find the ending index of the cookie value
				let c_end = document.cookie.indexOf(";", c_start);
				if (c_end == -1) {
						c_end = document.cookie.length;
				}
				// Decode and return the cookie value
				return decodeURIComponent(document.cookie.substring(c_start, c_end));
			}
		}
		// Return an empty string if the cookie is not found
		return "";
	}

	/**
	 * Retrieves the value of a cookie by its name specifically for Leads.
	 * This function uses a regular expression to match the cookie name and extract its value.
	 * If the cookie is not found, an empty string is returned.
	 * @param {string} name - The name of the cookie to retrieve.
	 * @returns {string} The value of the cookie, or an empty string if the cookie is not found.
	 */
	public getLeadsCookie(name: string): string {
		if (document.cookie.length > 0) {
			let match = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
			return match ? decodeURIComponent(match[2]) : '';
		}
		return '';
	}

	/**
	 * Creates a cookie to track the display of a popup.
	 * @param {any} popupId - The identifier of the popup.
	 * @param {string} type - (Optional) The type of time period for the cookie expiration (default: 'day').
	 * @param {number} time - (Optional) The duration for the cookie expiration (default: 1).
	 */
	public createPopupCookie(popupId: any, type: string = 'day', time: number = 1): void {
		// Get the current date
		let d = new Date();
		// Set the default cookie value
		let val = "1";
		// Calculate the expiration date based on the type and time
		switch (type) {
			case "day":
			case "days":
				d.setTime(d.getTime() + time * (24 * 60 * 60 * 1000));
				break;
			case "week":
			case "weeks":
				d.setTime(d.getTime() + time * (7 * 24 * 60 * 60 * 1000));
				break;
			case "month":
			case "months":
				d.setTime(d.getTime() + time * (30 * 24 * 60 * 60 * 1000));
				break;
		}
		// Set the expiration date for the cookie
		let expires: any = "expires=" + d.toUTCString();
		// Set the cookie name
		let cookieName: string = "popup-" + popupId;
		// Create the cookie with the specified name, value, and expiration
		document.cookie = cookieName + "=" + val + ";" + expires + ";path=/";
	}
	/**
	 * Retrieves the value of a popup cookie by its popupId.
	 * @param {any} popupId - The identifier of the popup.
	 * @returns {any} - The value of the popup cookie, or an empty string if the cookie is not found.
	 */
	public getPopupCookie(popupId: any): any {
		// Construct the name of the popup cookie
		let name: string = 'popup-' + popupId + '=';
		// Split document.cookie string into an array of individual cookies
		let ca: any = document.cookie.split(';');
		// Iterate through each cookie to find the one with the specified name
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			// Remove leading whitespaces
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			// Check if the cookie starts with the specified name
			if (c.indexOf(name) == 0) {
				// Return the value of the popup cookie
				return c.substring(name.length, c.length);
			}
		}
		// Return an empty string if the popup cookie is not found
		return "";
	}
	/**
	 * Deletes a cookie by setting its value to an empty string, effectively removing it.
	 * If no name is provided, all cookies will be deleted.
	 * @param {string} name - The name of the cookie to delete. If not provided, all cookies will be deleted.
	 */
	public deleteCookie(name: string = '', isExpires: boolean = false): void {
		// Set the cookie's value to an empty string, which effectively deletes it
		document.cookie = isExpires ? `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;` : `${name}=`;
	}
}