import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild,ElementRef, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from 'keen-slider';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ, ApiRespCacheServ } from '../../Services';

@Component({
	selector: 'bk-reviews',
	templateUrl: './Reviews.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ReviewsComponent implements OnInit, OnDestroy {

	@Input() secId : string = '';
	@Input() popupId: string = '';
	@ViewChild('reviewSlide') sliderRef : ElementRef<HTMLElement> | undefined;
	// Variables
	pageSett: any;
	isReviews: boolean = false;
	custDetail: string = '';
	reviews: any = [];
	reviewsLimit: number = 30;
	modalData:any;
	// Section fields
	section: any = {
		review: null,
		review_card: null
	}
	slider: any = null;
	// Slider configuration
	slideConfig = {
		slides: { perView: 1 },
		rtl:(this.initServ.selectedLang?.rtl) ? true : false,
		loop:false,
		defaultAnimation:{duration:1000},
	};
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public  initServ: InitServ, public utilServ: UtilServ, @Self() private destroy: NgOnDestroy,private cDRef: ChangeDetectorRef, private apiRespCacheServ: ApiRespCacheServ) {
		// Set the RTL value
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
		this.checkCustomerStoreSett();
	}

	ngOnInit(): void {
		// Build section data
		if(this.utilServ.appPermission('rating') && this.isReviews){
			this.sectionData = this.popupId && this.rcServ.popupData?.[this.popupId] ? this.rcServ.popupData[this.popupId] : this.rcServ.pageData;
			// Build section data
			if(this.secId && this.sectionData){
				this.buildSection(this.sectionData);
			}
			// Get data from api response cache service file
			this.apiRespCacheServ.getLiveReviewsApi(this.reviewsLimit).subscribe(data => {
				this.reviews = data;
				this.updateSliderConfig();
			});
		}
	}

	/**
	 * Checks and applies customer-related settings from merchant preferences.
	 * Determines if live reviews should be enabled and sets customer detail visibility.
	 */
	private checkCustomerStoreSett(): void {
		let {enable_live_reviews = null, show_customer_detail = null} = this.initServ.appAdmnStngs?.merchant_settings?.customers ?? {};
		// Review enable
		if(enable_live_reviews && enable_live_reviews == 'yes'){
			this.isReviews = true;
		}
		// Review customer name settings
		if(show_customer_detail){
			this.custDetail = show_customer_detail;
		}
	}

	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}

	/**
	 * Updates the slider configuration based on the number of reviews.
	 * If there is more than one review, looping is enabled.
	 * Reinitializes the slider with autoplay functionality after a short delay.
	 */
	private updateSliderConfig(): void {
		if(this.utilServ.checkArrLength(this.reviews)){
			this.slideConfig['loop'] = this.reviews.length > 1;
			setTimeout(()=>{
				if(this.sliderRef){
					if (this.slider) {this.slider.destroy();}
					this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig,[(slider) => this.utilServ.autoPlay(slider,6000)])
				}
			});
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Open modal
	 * @param review
	 */
	public openModal(review: any): void {
		this.modalData = review;
		setTimeout(() => {
			this.utilServ.togglePopup(`reviewModal_${review.id}`);
		}, 100);
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}
