import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { InitServ, NgOnDestroy } from '../../../../Services';
interface numberObject {
	chargedAmount : number;
	refundedAmount : number;
}
@Component({
	selector: 'bk-payment-adjustment',
	templateUrl: './PaymentAdjustment.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PaymentAdjustmentComponent implements OnInit {
	// Variables
	@Input() prefilledData: any;

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	initalChargedAmount: number = 0;
	finalAmount: any = 0;

	constructor(public initServ: InitServ, private cDRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		// Final total after adjustments
		this.finalTotalAfterAdjustments();
	}
	/**
	 * Final total after adjustments
	 */
	private finalTotalAfterAdjustments(): void {
		if(this.prefilledData && this.prefilledData.payment_log && (this.prefilledData.payment_log).length > 0){
			let final: number = 0;
			let chargedAmount: number = 0;
			if(this.prefilledData.is_voided){
				chargedAmount = 0;
			}
			let refundedAmount: number = 0;
			for(let log of this.prefilledData.payment_log){
				if(log.payment_type == 'booking'){
					this.initalChargedAmount = +log?.charged_amount;
					chargedAmount = +log?.charged_amount;
				}
				let amountObj : numberObject = this.setChargedAndRefundedAmount(log, chargedAmount, refundedAmount);
				chargedAmount = amountObj['chargedAmount'];
				refundedAmount = amountObj['refundedAmount'];
			}
			final = chargedAmount - refundedAmount;
			this.finalAmount = (final < 0) ? 0 : final;
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Set the charged amount and refunded amount from logs
	 * @param log
	 * @param chargedAmount
	 * @param refundedAmount
	 * @returns
	 */
	private setChargedAndRefundedAmount(log: any, chargedAmount: number, refundedAmount: number): numberObject{
		if(log.payment_type != 'cancellation' && log.payment_type != 'reschedule'){
			if(log.payment_type != 'booking' && log.payment_type != 'refund' && log?.status != 'leave_as_it_is'){
				chargedAmount = chargedAmount + (+log.charged_amount);
			}
			if(log.is_refunded){
				if(log.refund_type == 'full'){
					refundedAmount = refundedAmount + (+log.charged_amount);
				} else{
					refundedAmount = refundedAmount + (+log.refunded_amount);
				}
			}
			if(log?.payment_type == 'refund'){
				refundedAmount = refundedAmount + (+log.charged_amount);
			}
		}
		return {
			chargedAmount,
			refundedAmount
		}
	}
	/**
	 * Get the refund amount
	 */
	public getRefundAmount(log: any){
		let refundAmount = 0;
		if(log.refunded_amount && log.refunded_amount > 0){
			refundAmount = log.refunded_amount;
		}
		return refundAmount - this.getTaxAmount(log);
	}

	/**
	 * Get the tax amount
	 */
	public getTaxAmount(log: any){
		return log.tax_amount - log.tax_after_refund;
	}
}