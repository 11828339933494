<div class="position-relative" [attr.id]="section?.id">
	<ng-container *ngIf="initServ?.userInfo">
		<!-- Greeting -->
		<div class="mb-30" [ngClass]="{'d-md-flex justify-content-between align-items-center': type == 'giftCard'}">
			<h5 class="display-6 d-sm-flex align-items-center mb-0 flex-shrink-0 me-20" [attr.id]="section?.greetings_id">
				<span class="mb-sm-0 mb-15 me-sm-10 d-block" translate>{{greeting ? greeting : 'Welcome!'}}</span>
				<span class="fw-bold text-capitalize d-block">{{initServ?.userInfo?.first_name}} {{initServ?.userInfo?.last_name}}</span>
			</h5>
			<!-- Send gift card button -->
			<div *ngIf="type == 'giftCard'" class="d-md-inline-block d-block mt-20 mt-md-0">
				<bk-navigate [secId]="section?.send_gift_card_btn_id" [designId]="section?.send_gift_card_btn?.design_id" innerHTML="<i class='tjsicon-gift-card me-10'></i>{{(section?.send_gift_card_btn?.content)}}" customClass="btn btn-primary d-flex justify-content-center align-items-center btn-block" essentialClass="w-100" [routeLink]="['/'+initServ.appDynamicRoutes['gift-card']]"  [isLinkBtn]="true"></bk-navigate>
			</div>
		</div>
		<div class="mb-30" *ngIf="booking && type == 'dashboard'; else userInfo">
			<div class="row">
				<div class="col-sm-12 col-md-5 col-lg-4 col-xl-3">
					<div class="card mb-0 mb-lg-20 rounded-5 h-100 profile-wrap bk-section-card" [id]="section?.cards_id">
						<div class="card-body pb-0">
							<div class="d-flex flex-column flex-lg-row justify-content-center">
								<!-- Avatar -->
								<div class="flex-shrink-0 mb-30 mb-lg-0">
									<div class="tjs-img-wrap mx-auto">
										<bk-user-img [imgSrc]="initServ?.userInfo?.photo_url" [isParentMedia]="true" alt="user image" width="210" height="210" customClass="w-100 rounded-2 img-fluid-disabled" class="w-100"></bk-user-img>
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer tjs-card-footer pb-20 pt-0 border-0 bg-transparent">
							<bk-navigate [isLinkBtn]="true" [secId]="section?.cards?.edit_profile_btn_id"  [designId]="section?.cards?.edit_profile_btn?.design_id" [routeLink]="['/'+this.initServ.appDynamicRoutes['edit-personal-details']]" innerHTML="<i class='tjsicon-edit me-10'></i>{{ (section?.cards?.edit_profile_btn?.content )}}" essentialClass="btn btn-lg w-100 text-capitalize" customClass="btn-opacity-primary"></bk-navigate>
						</div>
					</div>
				</div>
				<!-- Summary details -->
				<div class="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-9">
					<div class="flex-grow-1 h-100">
						<div class="card h-100 rounded-5 tjs-profile-card" [id]="section?.cards_id">
							<div class="card-body d-flex flex-column">
								<h5 class="fw-bold mb-lg-30 mb-20 d-flex flex-wrap align-items-center" [attr.id]="section?.cards?.title_id">
									{{'Your next booking is on' | translate}} <!--{{utilServ.weekDayName(booking?.day) | translate}}--> {{booking?.day | translate}},
									<bk-date-display class="d-inline-block mx-5" [date]="booking?.arrival_date"></bk-date-display>
									{{'at' | translate}} <span class="dir-ltr mx-5">{{utilServ.slotToString(booking?.base_location_id, booking?.day, booking?.arrival_time,booking?.spot_type,booking?.arrival_window)}}</span>
								</h5>
								<div class="row h-100">
									<div class="col-lg-12 col-xl-9" [id]="section?.cards?.details_id">
										<ng-container *ngIf="booking?.status != 6 && booking?.status != 7 && booking?.status != 8; else noProvTemp">
											<div class="d-flex" *ngIf="utilServ.userNameVisible()">
												<p class="mb-15 d-flex w-100" *ngIf="booking?.provider_info && (booking?.provider_info).length > 0 && !(booking.provider_info).includes(0); else noProvTemp">
													<span class="tjs-custom-label position-relative me-5 pe-10 bk-title flex-shrink-0 bk-card-label" translate>Assigned to</span>
													<span class="tjs-custom-content text-dark fw-bold text-wrap bk-content text-capitalize flex-grow-1" [ngSwitch]="booking?.provider_category">
														<!-- Single -->
														<div class="fs-15 d-inline-block" *ngSwitchCase="'single'">
															<ng-container *ngTemplateOutlet="provNameTemp; context:{data:booking?.provider_info[0]}"></ng-container>
														</div>
														<!-- Team -->
														<div class="d-inline-block bk-card-value" *ngSwitchCase="'team'">
															<ng-container *ngIf="booking?.provider_info">
																<span class="fs-15 me-5 text-capitalize">{{booking?.provider_info[0]?.first_name}}</span>
																<span class="badge bg-primary ms-5" *ngIf="booking?.members && (booking?.members).length > 0">{{'Team of' | translate}} {{(booking?.members).length}}</span>
															</ng-container>
														</div>
														<!-- Pair -->
														<div class="tjs-tooltip-wrap d-inline-block" *ngSwitchCase="'pair'">
															<div class="badge tjs-pointer bg-warning" translate>Pair</div>
															<!-- Tooltip here -->
															<ul class="list-unstyled tjs-tooltip tjs-tooltip--right w-auto" *ngIf="booking?.provider_info && (booking?.provider_info).length > 0">
																<li class="fs-15 d-block text-nowrap" *ngFor="let pairProv of booking?.provider_info">
																	<ng-container *ngTemplateOutlet="provNameTemp; context:{data:pairProv}"></ng-container>
																</li>
															</ul>
														</div>
													</span>
												</p>
											</div>
										</ng-container>
										<!-- Amount -->
										<p class="mb-15 d-flex w-100">
											<span class="tjs-custom-label position-relative me-5 pe-10 bk-title flex-shrink-0 bk-card-label" translate>Amount</span>
											<span class="tjs-custom-content text-dark fw-bold text-wrap bk-content flex-grow-1 bk-card-value">
												<bk-amount-display *ngIf="booking?.final_amount && booking?.final_amount > 0; else zeroAmount" [amount]="booking?.final_amount"></bk-amount-display>
												<ng-template #zeroAmount>
													<bk-amount-display [isZero]="true"></bk-amount-display>
												</ng-template>
											</span>
										</p>
										<!-- Service -->
										<p class="mb-15 d-flex w-100" *ngIf="(booking?.industry_id+'_'+booking?.form_id) | bkngCustomLabel:'service':'Service'">
											<span class="tjs-custom-label position-relative me-5 pe-10 bk-title flex-shrink-0 bk-card-label">{{(booking?.industry_id+'_'+booking?.form_id) | bkngCustomLabel:'service': 'Service'}}</span>
											<span class="tjs-custom-content text-dark fw-bold text-wrap bk-content flex-grow-1 bk-card-value">{{utilServ.serviceName(booking?.service_category) | translate}}</span>
										</p>
										<!-- Frequency -->
										<p class="mb-15 d-flex w-100" *ngIf="(booking?.industry_id+'_'+booking?.form_id)| bkngCustomLabel:'frequency':'Frequency'">
											<span class="tjs-custom-label position-relative me-5 pe-10 bk-title flex-shrink-0 bk-card-label">{{(booking?.industry_id+'_'+booking?.form_id)| bkngCustomLabel : 'frequency':'Frequency'}}</span>
											<span class="tjs-custom-content text-dark fw-bold text-wrap bk-content flex-grow-1 bk-card-value">{{utilServ.frequencyName(booking?.frequency) | translate}}</span>
										</p>
										<!-- Location -->
										<p class="mb-15 d-flex w-100" *ngIf="((booking?.industry_id+'_'+booking?.form_id)| bkngCustomLabel:'location':'Location') && ((booking?.address && booking?.address?.address) || (utilServ.merchantLocation(booking?.address_id)))">
											<span class="tjs-custom-label position-relative me-5 pe-10 bk-title flex-shrink-0 bk-card-label">{{(booking?.industry_id+'_'+booking?.form_id)| bkngCustomLabel :'location':'Location'}}</span>
											<ng-container *ngIf="booking?.location_type == 'SA'; else merchantLoc">
												<span class="tjs-custom-content text-dark fw-bold text-wrap bk-content flex-grow-1">
													<span *ngIf="booking?.address?.apt && ((booking?.industry_id+'_'+booking?.form_id) | bkngCustomLabel:'apt':'Apt')" class="d-block mb-0 bk-card-value">{{(booking?.industry_id+'_'+booking?.form_id) | bkngCustomLabel:'apt': 'Apt'}} - {{booking?.address?.apt}}</span>
													<span class="d-block text-dark fw-bold bk-card-value">{{booking?.address?.address | translate}}</span>
												</span>
											</ng-container>
											<ng-template #merchantLoc>
												<span class="tjs-custom-content text-dark fw-bold text-wrap bk-conten flex-grow-1 bk-card-value">{{utilServ.merchantLocation(booking?.address_id) | translate}}</span>
											</ng-template>
										</p>
									</div>
									<!-- View Details btn -->
									<div class="col-lg-12 col-xl-3 align-self-end text-lg-end mt-15 mt-md-0">
										<bk-navigate [isLinkBtn]="true" [secId]="section?.cards?.view_btn_id" [designId]="section?.cards?.view_btn?.design_id" [routeLink]="['/reschedule-booking', booking?.industry_id, booking?.form_id, booking?._id]" innerHTML="{{(section?.cards?.view_btn?.content)}}" essentialClass="py-15 btn detail-btn" customClass="btn-primary"></bk-navigate>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Info -->
		<ng-template #userInfo>
			<div class="card mb-30" [attr.id]="section?.cards_id">
				<div class="card-body">
					<div class="d-flex flex-column flex-lg-row">
						<!-- Avatar -->
						<div class="flex-shrink-0 mb-30 mb-lg-0">
							<div class="tjs-media-profile__thumb text-center text-lg-start">
								<bk-user-img customClass="img-fluid-disabled" [imgSrc]="initServ?.userInfo?.photo_url" [isParentMedia]="true" alt="user image" width="210" height="210"></bk-user-img>
							</div>
						</div>
						<div class="flex-grow-1 ms-lg-20">
							<div class="d-flex flex-column h-100">
								<!-- User info: Name, email & phone -->
								<div class="d-flex flex-column justify-content-center h-100 pb-lg-0 pb-20 text-center text-lg-start">
									<h4 class="fw-bold mb-10 text-capitalize" [attr.id]="section?.name_id">{{initServ?.userInfo?.first_name}} {{initServ?.userInfo?.last_name}}</h4>
									<p class="mb-10" *ngIf="initServ?.userInfo?.email_id" [attr.id]="section?.email_id">{{initServ?.userInfo?.email_id}}</p>
									<p class="mb-10" *ngIf="initServ?.userInfo?.phone_number">
										<bk-phone-number [id]="section?.mobile_id" [phone]="initServ?.userInfo?.phone_number" customClass="fs-15 text-body d-inline-block dir-ltr" [hideIcon]="true"></bk-phone-number>
									</p>
								</div>
								<!-- Actions: Edit, change password & info -->
								<div class="row mx-n10">
									<div class="col-lg-4 px-10">
										<bk-navigate [secId]="section?.edit_btn_id" [designId]="section?.edit_btn?.design_id" innerHTML="<i class='tjsicon-edit me-10'></i>{{ (section?.edit_btn?.content)}}" customClass="btn btn-lg btn-opacity-primary" essentialClass="mb-15 mb-lg-0 w-100 px-10-imp" [routeLink]="['/'+this.initServ.appDynamicRoutes['edit-personal-details']]" [isLinkBtn]="true"></bk-navigate>
									</div>
									<div class="col-lg-4 px-10">
										<bk-navigate [secId]="section?.password_btn_id" [designId]="section?.password_btn?.design_id" innerHTML="<i class='tjsicon-pin-code me-10'></i>{{(section?.password_btn?.content)}}" customClass="btn btn-lg btn-light" essentialClass="mb-15 mb-lg-0  w-100 px-10-imp" (callback)="changePwd()"></bk-navigate>
									</div>
									<div class="col-lg-4 px-10" *ngIf="type != 'info'">
										<bk-navigate [secId]="section?.info_btn_id" [designId]="section?.info_btn?.design_id" innerHTML="<i class='tjsicon-user-2 me-10'></i>{{(section?.info_btn?.content)}}" customClass="btn btn-lg btn-opacity-success" essentialClass="mb-15 mb-lg-0 w-100 px-10-imp" [routeLink]="['/'+this.initServ.appDynamicRoutes['info-and-billing']]" [isLinkBtn]="true"></bk-navigate>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Provider name template -->
<ng-template #provNameTemp let-data="data">
	<span class="text-capitalize bk-card-value" *ngIf="utilServ.userNameSett('firstname')">{{data?.first_name}}&nbsp;</span>
	<span class="text-capitalize ms-5 bk-card-value" *ngIf="utilServ.userNameSett('lastname')">{{data?.last_name}}</span>
	<span class="text-capitalize ms-5 bk-card-value" *ngIf="!utilServ.userNameSett('lastname') && utilServ.userNameSett('last_initial')">{{utilServ.nameLastInitial(data?.last_name)}}</span>
</ng-template>
<!-- No provider template  -->
<ng-template #noProvTemp>
	<ng-container *ngIf="admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.bookings && admnStngs?.merchant_settings?.bookings?.show_with_pending_bookings">
		<div class="mb-15 d-flex w-100">
			<div class="d-inline-block tjs-custom-label position-relative me-5 pe-10 bk-card-label">{{'Assigned to' | translate}}</div>
			<div>
				<div class="tjs-tooltip-wrap">
					<div class="fs-15 text-danger fw-bold tjs-capitalize tjs-w-100" [class.tjs-pointer]="admnStngs?.merchant_settings?.bookings?.show_tooltip_for_pending_bookings">{{admnStngs?.merchant_settings?.bookings?.show_with_pending_bookings | translate}}</div>
					<span class="tjs-tooltip tjs-tooltip--top start-0" *ngIf="admnStngs?.merchant_settings?.bookings?.show_tooltip_for_pending_bookings">{{admnStngs?.merchant_settings?.bookings?.pending_booking_tooltip_content | translate}}</span>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>
