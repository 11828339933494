import { Component, Input, ViewEncapsulation, OnInit, NgZone, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InitServ, RenderComponentServ, SectionServ, UtilServ } from 'src/app/Services';

@Component({
	selector: 'bk-embed-form-base',
	template: ``,
	encapsulation: ViewEncapsulation.None
})
export class EmbedFormBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	embedFormUrl: SafeResourceUrl | undefined;
	embedJsUrl: SafeResourceUrl | undefined;
	varId: string | undefined;
	sectionData: any;
	private timeout: any;
	private resizeObserver!: ResizeObserver;
	private mutationObserver!: MutationObserver;

	@ViewChild('iframeRef') iframe!: ElementRef<HTMLIFrameElement>;

	// eslint-disable-next-line max-params
	constructor(
		public rcServ: RenderComponentServ,
		public secServ: SectionServ,
		public initServ: InitServ,
		public sanitizer: DomSanitizer,
		public utilServ: UtilServ,
		public cdRef: ChangeDetectorRef,
		public ngZone: NgZone
	) {
		this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.initServ.appData.primary_custom_domain + '/resources/embed.js');
	}

	ngOnInit() {
		this.setSecData();
		this.buildSecData();
	}

	ngAfterViewInit() {
		this.cdRef.detectChanges();
		this.adjustIframeHeight();
	}

	/**
	 * Adjusts the height of the iframe dynamically based on its content.
	 * - Sets up a timeout to ensure the iframe reference is available.
	 * - Listens for the iframe's load event to access its content and adjust its height.
	 * - Uses a ResizeObserver to update the height when the iframe's content is resized.
	 * - Uses a MutationObserver to track changes in the iframe's content structure (e.g., added/removed elements) and update the height accordingly.
	 * - Implements a debounce mechanism to prevent excessive height updates during content changes.
	 * - Handles cross-origin restrictions by safely catching errors when accessing iframe content.
	 */
	private adjustIframeHeight(): void {
			// Set a timeout to ensure the iframe reference is available before making adjustments
		this.timeout = setTimeout(() => {
			// Check if iframe reference is available
			if (!this.iframe) {
				console.warn('Iframe reference is not yet available.');
				return;
			}
			const iframeEl = this.iframe.nativeElement;
			// Set up the onload event handler for the iframe
			iframeEl.onload = () => {
				try {
					// Attempt to access the iframe's document
					const iframeDoc = iframeEl.contentDocument || iframeEl.contentWindow?.document;
					// If document is not accessible, return early
					if (!iframeDoc) return;
					// Run outside Angular's change detection to optimize performance
					this.ngZone.runOutsideAngular(() => {
						// Function to update the iframe's height based on its content
						const updateHeight = () => {
							this.ngZone.run(() => {
								// Calculate the new height based on content size
								const newHeight = Math.max(iframeDoc.body.scrollHeight, iframeDoc.body.offsetHeight);
								// Set the iframe's height to the new value
								iframeEl.style.height = `${newHeight}px`;
							});
						};
						// Debounced update function to avoid excessive updates
						let timeoutId: any;
						const debounceUpdate = () => {
							clearTimeout(timeoutId);
							timeoutId = setTimeout(updateHeight, 100); // Debounce delay of 100ms
						};
						// Observe the iframe's body for resizing events
						this.resizeObserver = new ResizeObserver(debounceUpdate);
						this.resizeObserver.observe(iframeDoc.body);
						// Observe changes to the iframe's content (e.g., added/removed elements)
						this.mutationObserver = new MutationObserver(debounceUpdate);
						this.mutationObserver.observe(iframeDoc.body, { childList: true, subtree: true, attributes: false }); // Ignore attribute changes
					});
				} catch (error) {
					// Handle errors related to cross-origin restrictions
					console.warn('Cross-origin restriction: Unable to access iframe content.');
				}
			};
		}, 0); // Trigger immediately after the current stack is cleared
	}

	/**
	 * Sets the section data based on popup or page data.
	 * - If `popupId` is present and exists in `popupData`, assign it to `sectionData`.
	 * - Otherwise, fall back to `pageData`.
	 */
	public setSecData(): void {
		if (this.popupId && this.rcServ?.popupData[this.popupId]) {
			// Assign popup-specific section data if available
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			// Default to general page data if no popup data is found
			this.sectionData = this.rcServ.pageData;
		}
	}

	/**
	 * Builds section data for embedding an iframe form.
	 * - Retrieves section settings and sets the variation ID.
	 * - Constructs the embed form URL with required parameters.
	 * - Bypasses security restrictions to trust the URL.
	 * - Dynamically sets multiple iframe heights for different use cases.
	 */
	public async buildSecData(): Promise<void> {
		// Build section data only if `secId` and `sectionData` are available
		if (!(this.secId && this.sectionData)) return;
		// Retrieve section settings
		this.pageSett = this.sectionData.section_settings;
		// Get the variation ID from section settings
		this.varId = this.pageSett[this.secId].variation_id;
		// Generate the iframe URL
		let url: string | null = this.getUrl();
		if (url) {
			// Construct the embedded form URL with language and embedding parameters
			let formUrl = this.getEmbedFormUrl(url);
			// Bypass Angular security restrictions to trust the iframe URL
			this.embedFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(await formUrl);
			let iframeElemId: string = `bk_embed_form_${this.secId}`;
			// Set different iframe heights (possibly for different screen sizes or responsive behavior)
			this.utilServ.setHeight(1000, iframeElemId);
			this.utilServ.setHeight(3000, iframeElemId);
			this.utilServ.setHeight(5000, iframeElemId);
			this.utilServ.setHeight(8000, iframeElemId);
		}
	}

	/**
	 * Retrieves the section element based on `secId`.
	 * - Checks if `sectionData` and `sections` exist.
	 * - Returns the section corresponding to `secId` if available.
	 * - Returns `null` if the section data is missing.
	 */
	private getSecElem(): {form: string;} | null {
		// Ensure sectionData and sections exist before accessing them
		if (this.sectionData && this.sectionData.sections) {
			// Return the section matching secId
			return this.sectionData.sections[this.secId];
		}
		// Return null if sectionData or sections are undefined
		return null;
	}

	/**
	 * Retrieves the URL for an iframe based on section settings.
	 * - Fetches the section element using `getSecElem()`.
	 * - Checks if the section contains a `form` property.
	 * - Verifies if the form element is active using `checkEleStatus`.
	 * - Returns the corresponding URL if available; otherwise, returns `null`.
	 */
	private getUrl(): string | null {
		// Retrieve the section element
		let secElem: {form: string;} | null = this.getSecElem();
		// Check if the section exists and has a form
		if (secElem && secElem.form) {
			// Validate the form's status before accessing its URL
			if (this.secServ.checkEleStatus(this.pageSett, secElem.form)) {
				// Return the form's URL if valid
				return this.pageSett[secElem.form]?.url;
			}
		}
		// Return null if conditions are not met
		return null;
	}

	/**
	 * Asynchronously builds the embed form URL based on the `slug` and `url` passed in.
	 * @param {string} url - The identifier for the form (e.g., campaign ID, lead ID).
	 * @returns A promise that resolves to the generated embed URL.
	 */
	private async getEmbedFormUrl(url: string): Promise<string> {
		let id: string = url;
		// Switch based on the 'slug' to determine the type of form URL to generate
		switch (this.pageSett[this.secId].slug) {
			case 'embed_campaign_form':
				// Generate URL for campaign form with embed parameter
				return `${this.initServ.baseUrl}/short-form/?sf_id=${id}&embed=true&language=${this.initServ.selectedLang.code}`;
			case 'embed_lead_form':
				// Encrypt URL for lead form and generate its embed URL
				id = await this.utilServ.dataEncryptByGCM(url);
				return `${this.initServ.baseUrl}/leads/form/${id}?embed=true&hide_trans=true&lang=${this.initServ.selectedLang.code}`;
			case 'hiring_form':
				// Generate URL for hiring form
				return `${this.initServ.baseUrl}/hiring/form/${id}`;
			default:
				// Return an empty string if no matching form type
				return '';
		}
	}

	ngOnDestroy() {
		// Clear the timeout to prevent the callback from being executed after the component is destroyed.
		clearTimeout(this.timeout);
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
		}
	}
}
