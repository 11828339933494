/* eslint-disable complexity */
import { Injectable } from '@angular/core';
declare let Stripe: any;
declare let Square: any;
declare let braintree: any;
declare let Accept: any;
// External lib
import Bugsnag from '@bugsnag/js';
// Services
import { ApiServ, InitServ, NgOnDestroy, UtilServ } from './index';
import { IS_DEV, IS_MOBILE } from '../Constants';
import { takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PaymentGatewayComponent } from '../Global/GlobalDefault/PaymentGateway/PaymentGateway.component';
// Interface
import { BillingAddress, PaymentGatewayBillingAddress } from '../Interfaces';

// Payment interface
export interface AuthDotNet { dataValue: string | null; dataDescriptor: string | null; last4: string | null; }
// instance get by third party
export interface PaymentGatewayPayload extends AuthDotNet {
	token: string | null,
	verification_token: string | null
	pay_with_cc: string | null,
	charge_id: string | null,
	last4: string | null,
	device_data: string | null,
	pay_customer_id?: string | number | null,
	instance?: any | null,
	postalCode?: string | null,
	decline_reason?: string | null
}

@Injectable({
	providedIn: 'root'
})
export class PaymentGatewayServ {

	// private variables
	stripeCard: any;
	stripeCvc: any;
	stripe: any;
	squarePayments: any;
	squareCard: any;
	squareStyle = {
		'.input-container': {
			borderColor: '#CCD4DE',
			borderRadius: '5px',
		}
	};

	// eslint-disable-next-line max-params
	constructor(private initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, private toastr: ToastrService, private destroy: NgOnDestroy) {}
	/**
	 * Generate the client token in case of stripe and paypal based on location id
	 * @param locId: location Id
	 * @returns client token
	 */
	public async generateClientToken(locId: string|number = 0){
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApiWithPathVariables('GET', 'PaymentClientToken', [locId], null, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				return res.data;
			} else {
				this.toastr.error(res?.message);
				return null;
			}
		});
	}
	// ----------------------------------------Stripe form functions----------------------------------------
	/**
	 * Stripe key
	 * @param locId Location id
	 * @returns null / key
	 */
	public async stripeKey(locId: any = null, baseLoc: boolean = false): Promise<any> {
		let status: any;
		if (locId && this.utilServ.checkArrLength(this.initServ.appData?.locations)) {
			for (let loc of this.initServ.appData.locations) {
				if (baseLoc) {
					status = await this.getStripeLocBasedKey(loc,locId, 'location_id');
					if(status){
						break;
					}
				} else {
					status = await this.getStripeLocBasedKey(loc,locId, '_id');
					if(status){
						break;
					}
				}
			}
		}
		if (!status) {
			status = await this.defaultStripeKey();
		}
		return await this.utilServ.aesDecryption(status);
	}
	/**
	 * Get the stripe location based key
	 * @param loc
	 * @param locId
	 * @param fieldName
	 * @returns
	 */
	private async getStripeLocBasedKey(loc: any,locId: any = null, fieldName: string = '_id'){
		if(loc[fieldName] == locId && loc?.live_stripe_publish_key) {
			return loc.live_stripe_publish_key;
		}
		return false;
	}
	/**
	 * Default stripe key
	 * @returns null / key
	 */
	public async defaultStripeKey() {
		if (this.initServ?.appData?.stripe_live_key) {
			return this.initServ.appData.stripe_live_key;
		}
		return null;
	}
	/**
	 * Set the stripe key in Stripe
	 * @param key
	 * @returns
	 */
	public setStripeKey(key: any): any {
		try {
			// Check if Stripe is defined
			if (typeof Stripe === 'undefined') {
				throw new Error('Stripe is not defined.');
			}
			// Initialize and return Stripe with the provided key
			return Stripe(key);
		} catch (error) {
			console.error('Error initializing Stripe:');
			return null;
		}
	}
	/**
	 * Function to create Payment Intent client secret for stripe
	 * @param extraData:object
	 */
	public async stripePaymentIntent(extraData: any = null): Promise<any> {
		let queryParams: any={};
		if(extraData?.is_hold){
			queryParams['type']='hold';
		}
		let postData: any = {
			amount: extraData.amount,
			booking_id: extraData?.booking_id,
			loc_id: extraData.base_location_id,
			uid: extraData?.uid,
			email_id: extraData?.email_id
		}
		// Pass the invoice number in case of invoice
		if(extraData?.inv_num){
			postData['inv_num'] = extraData?.inv_num;
		}
		if(extraData?.gift_card_recipient_email){
			postData['gift_card_recipient_email'] = extraData?.gift_card_recipient_email;
		}
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApiWithQueryParams('POST', 'PaymentIntent',queryParams, postData, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				return await this.confirmStripePayment(res.data, extraData);
			} else {
				if(!extraData?.hide_toast){
					// this.toastr.error(res?.message);
				}
				return null;
			}
		})
	}

	/**
	 * Transforms a billing address object into a format suitable for Stripe billing address.
	 * @param billingAddr The billing address object to transform.
	 * @returns The billing address formatted for Stripe.
	 */
	public getStripeBillingAddr(billingAddr: BillingAddress): PaymentGatewayBillingAddress {
		let paymentBillingAddr: PaymentGatewayBillingAddress | null;
		// If 3D Secure is enabled, format the address accordingly
		if(this.initServ.threeDSecure){
			paymentBillingAddr = {
				line1: billingAddr?.address ?? '',
				city: billingAddr?.city ?? '',
				postal_code: (billingAddr?.zipcode).toString(),
				state: billingAddr?.state ? `${billingAddr?.state},${billingAddr?.country}` : ''
			};
			if(billingAddr?.country_code){
				paymentBillingAddr['country'] = billingAddr.country_code;
			}
		} else {
			paymentBillingAddr = {
				address_line1: billingAddr?.address ?? '',
				address_city: billingAddr?.city ?? '',
				address_state: billingAddr?.state ?? '',
				address_zip: (billingAddr?.zipcode).toString()
			};
			if(billingAddr?.country_code){
				paymentBillingAddr['address_country'] = billingAddr.country_code;
			}
		}
		return paymentBillingAddr;
	}

	/**
	 * To confirm card payment for stripe
	 * @param clientSecret
	 * @param extraData: location, stripe, pay_with_cc
	 * @returns
	 */
	public async confirmStripePayment(clientSecret: any, extraData: any): Promise<any> {
		let billingAddr: PaymentGatewayBillingAddress | null = null
		if(extraData?.billing_address){
			billingAddr = this.getStripeBillingAddr(extraData.billing_address);
		}
		await this.setStripeElem(extraData);
		let card: any = this.setStripeCard(extraData);
		if(billingAddr){
			card['billing_details'] = {address:billingAddr}
		}
		return this.stripe.confirmCardPayment(clientSecret, { payment_method: card, setup_future_usage: 'off_session' }).then(async (result: any) => {
			if (this.utilServ.isValExist(result?.error?.message)) {
				// is_captured_amount use only invoice payment when some bookings with card hold and setting is captured.
				if(extraData?.is_captured_amount){
					return result?.error?.message;
				} else {
					if (extraData?.pay_with_cc && !extraData?.hide_toast) {
						this.toastr.error(result?.error?.message);
					} else {
						this.stripeElementsErrors(result);
					}
				}
				// If TTL is not working hit the api
				if(this.utilServ.checkArrLength(extraData?.booking_ids) || extraData?.inv_id){
					this.deletePaymentCache(extraData);
				}
				return null;
			} else {
				this.stripeElementsErrors(result);
				return result.paymentIntent;
			}
		});
	}
	/**
	 * Function to set stripe element
	 */
	private async setStripeElem(extraData: any): Promise<void> {
		if (extraData) {
			if (extraData?.stripe) {
				this.stripe = extraData?.stripe;
			} else {
				let locId: null = extraData?.location ?? extraData?.base_location_id;
				let bacLoc: boolean = extraData?.base_location_id ? true : false;
				let stripeKey = await this.stripeKey(locId, bacLoc);
				this.stripe = this.setStripeKey(stripeKey);
			}
		}
	}
	/**
	 * Function to set card (object or can be a card id)
	 */
	private setStripeCard(extraData: any): any {
		if (this.utilServ.isValExist(extraData?.pay_with_cc)) {
			return extraData?.pay_with_cc;
		} else {
			let cardObj = this.getStripeCard();
			return { card: cardObj };
		}
	}
	/**
	 * Build the stripe from
	 * @param elements: form elements
	 */
	public buildStripeForm(elements: any) {
		//Create the card and hide the postal code
		this.stripeCard = elements.create('card', {
			hidePostalCode: true,
			style: IS_MOBILE ? {
				base: {
					fontSize: '16px'
				}
			} : {}
		});
		if (this.stripeCard) {
			this.stripeCard.mount('#card-element');
			this.stripeCard.addEventListener('change', (event: any) => {
				this.stripeElementsErrors(event);
			}, { passive: true });
		}
	}
	/**
	 * Get the stripe card
	 * @returns
	 */
	public getStripeCard() {
		return this.stripeCard;
	}
	/**
	 * Create stripe cardCvc element form
	 */
	public async stripeCvvForm(location: any) {
		let stripeKey = await this.stripeKey(location, true);
		this.stripe = this.setStripeKey(stripeKey);
		let elements = this.stripe.elements({ locale: this.initServ.savedLng });
		if(elements) {
			this.stripeCvc = elements.create('cardCvc', {
				style: IS_MOBILE ? {
					base: {
						fontSize: '16px'
					}
				} : {}
			});
			if (this.stripeCvc) {
				this.stripeCvc.mount('#card-cvc');
				this.stripeCvc.addEventListener('change', (event: any) => {
					this.stripeElementsErrors(event);
				}, { passive: true });
			}
		}
	}
	/**
	 * 3DS to confirm stripe old card with cvc
	 */
	public confirmStripeCardWithCvv(paymentLog: any):any {
		return this.stripe.confirmCardPayment(paymentLog?.client_secret, { payment_method: paymentLog?.card_id, setup_future_usage: 'off_session', payment_method_options: { card: { cvc: this.stripeCvc } } }).then((result: any) => {
			if (result?.error) {
				// If TTL is not working hit the api
				if(this.utilServ.checkArrLength(paymentLog?.booking_ids)){
					this.deletePaymentCache(paymentLog);
				}
				this.stripeElementsErrors(result);
				return null;
			} else {
				this.stripeElementsErrors(result);
				return result.paymentIntent;
			}
		});
	}
	/**
	 * Stripe form error handler
	 * @param event Error
	 */
	public stripeElementsErrors(event: { error: { message: string; }; }) {
		const displayError = document.getElementById('card-errors');
		let cardElem: any = document.getElementById('card-element');
		let cardCvc: any = document.getElementById('card-cvc');
		if (event.error) {
			if (displayError) {
				displayError.innerHTML = `<i class="tjsicon-attention"></i>${event.error.message}.`;
				if (event?.error?.message) {
					const errorMessage = event.error.message.toLowerCase();
					if (errorMessage.includes('invalid api key provided')) {
						Bugsnag.notify(new Error(JSON.stringify(event)));
					}
				}
			}
			if (cardCvc) {
				cardCvc?.classList.add("is-invalid");
			}
			if (cardElem) {
				cardElem?.classList.add("is-invalid");
			}
		} else {
			if (displayError) {
				displayError.innerHTML = '';
			}
			if (cardCvc) {
				cardCvc?.classList.remove("is-invalid");
			}
			if (cardElem) {
				cardElem?.classList.remove("is-invalid");
			}
		}

	}
	/**
	 * Destroy the stripe card
	 */
	public destroyStripeCard() {
		if (this.stripeCard && !this.stripeCard._destroyed) {
			this.stripeCard.clear();
			this.stripeCard.unmount();
			this.stripeCard.destroy();
		}
	}
	/**
	 * Destroy stripe card cvc
	 */
	public destroyStripeCardCvc() {
		if (this.stripeCvc && !this.stripeCvc._destroyed) {
			this.stripeCvc.clear();
			this.stripeCvc.unmount();
			this.stripeCvc.destroy();
		}
	}

	/**
	 * Checks the payment cache for the specified booking IDs.
	 * @param bkngIds An array of booking IDs to check in the payment cache.
	 * @param token The authentication token for accessing the payment cache.
	 * @returns A Promise that resolves to a boolean indicating whether the payment cache check was successful.
	 */
	public checkPaymentCache(bkngIds: number[], token: string): Promise<boolean> {
		let payload: {token: string, booking_ids: number[]} = {
			token : token,
			booking_ids: bkngIds
		}
		return this.apiServ.callApi('POST', 'PaymentCache',payload).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any) => {
			if (this.apiServ.checkAPIRes(res)) {
				return true;
			} else {
				this.toastr.error(res?.message);
				return null;
			}
		});
	}

	// ----------------------------------------Square form functions----------------------------------------
	/**
	 * SquareUp application and location key
	 * @param locId Location id
	 * @returns null / object
	 */
	public async squareupKeys(locId: any = null, baseLoc: boolean = false): Promise<any> {
		let status: any;
		if (locId && this.utilServ.checkArrLength(this.initServ.appData?.locations)) {
			for(let loc of this.initServ.appData.locations){
				if(baseLoc){
					status = this.getSquareLocBasedKey(loc,locId, 'location_id');
					if(status){
						break;
					}
				}else{
					status = this.getSquareLocBasedKey(loc,locId, '_id');
					if(status){
						break;
					}
				}
			}
		}
		if (!status) {
			status = this.defaultSquareupKeys();
		}
		if(status){
			return {
				appId : await this.utilServ.aesDecryption(status?.appId),
				locId : await this.utilServ.aesDecryption(status?.locId)
			}
		} else {
			return null;
		}
	}
	/**
	 * Get the square location based key
	 * @param loc
	 * @param locId
	 * @param fieldName
	 * @returns
	 */
	private getSquareLocBasedKey(loc: any,locId: any = null, fieldName: string = '_id'){
		if(loc[fieldName] == locId && loc?.gateway_keys?.square_application_id && loc?.gateway_keys?.square_location_id){
			return {
				appId : loc.gateway_keys.square_application_id,
				locId : loc.gateway_keys.square_location_id
			}
		}
		return false;
	}
	/**
	 * SquareUp payment method default appliaction and location key
	 * @returns null / object
	 */
	public defaultSquareupKeys(): any {
		if (this.initServ.appData?.square_live_key && this.initServ.appData?.square_location_id) {
			return {
				appId : this.initServ.appData.square_live_key,
				locId : this.initServ.appData.square_location_id
			}
		}
		return null;
	}
	/**
	 * Build the square payment object
	 * @param squarUpKeys: square keys
	 * @param locationId: location id
	 */
	public async buildSquarePayment(squarUpKeys: any = null, locationId: number|string = 0, baseLoc:boolean = false): Promise<void> {
		if(!squarUpKeys){
			squarUpKeys = await this.squareupKeys(locationId, baseLoc);
		}
		try {
			this.squarePayments = Square.payments(squarUpKeys.appId, squarUpKeys.locId);
		} catch (e) {
			console.log('missing-credentials', e)
		}
	}
	/**
	 * Build the square form
	 */
	async buildSquareForm(squarUpKeys: any): Promise<any>{
		await this.buildSquarePayment(squarUpKeys);
		if (this.squarePayments) {
			try {
				this.squareCard = await this.initializeSquareCard(this.squarePayments);
				if(this.squareCard){
					return true;
				}
			} catch (e) {
				console.log('Initializing Card failed', e);
			}
		}
	}
	/**
	 * Initialize Square Card
	 * @param payments
	 * @returns card
	 */
	async initializeSquareCard(payments: any) {
		return payments.card({style: this.squareStyle}).then(async (card: any) => {
			await card.attach('#square-container');
			await card.recalculateSize();
			return card;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		}).catch(() => {});
	}
	/**
	 * Generate the square token
	 * @returns Token / null
	 */
	async generateSquareToken(): Promise<any> {
		try {
			const result = await this.squareCard.tokenize();
			if (result) {
				if (result.status === 'OK') {
					return { last4: result?.details?.card?.last4, token: result.token, postalCode: result?.details?.billing?.postalCode};
				} else {
					this.squareElementsErrors(result);
					return null;
				}
			} else {
				return null;
			}
		} catch (e) {
			console.log('Initializing Card failed', e);
		}
	}
	/**
	 * Square element error
	 * @param event Error response
	 */
	private squareElementsErrors(event: { errors: any; }) {
		const displayError = document.getElementById('square-errors');
		if (event.errors) {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			let sq: any = this;
			event.errors.forEach(function (error: { type: string; field: string; message: string; }) {
				if (error.type == "VALIDATION_ERROR") {
					sq.displaySquareError(error);
				} else {
					sq.toastr.error(error.message)
				}
			});
		} else {
			if (displayError) {
				displayError.innerHTML = '';
			}
			let cardElem: any = document.getElementById('card-element');
			if (cardElem) {
				cardElem?.classList.remove("is-invalid");
			}
		}
	}
	/**
	 * Display square error
	 * @param error: error message
	 */
	public displaySquareError(error: any, id:string = 'square-errors'){
		const displayError = document.getElementById(id);
		if (displayError) {
			displayError.innerHTML = '<i class="tjsicon-attention"></i>' + error.message + '.';
		}
		let squareElem: any = document.getElementById('square-container');
		if (squareElem) {
			squareElem?.classList.add("is-invalid");
		}
		this.utilServ.scrollToSpecificEle('payment_details');
	}

	/**
	 * Transforms a billing address object into a format suitable for Square billing address.
	 * @param billingAddr The billing address object to transform.
	 * @returns The billing address formatted for Square.
	 */
	public getSquareBillingAddr(billingAddr: BillingAddress): PaymentGatewayBillingAddress {
		let paymentBillingAddr: PaymentGatewayBillingAddress = {
			addressLines: [billingAddr?.address ?? ''],
			state: billingAddr?.state ?? '',
			city: billingAddr?.city ?? '',
			postalCode: (billingAddr?.zipcode) ? (billingAddr.zipcode).toString() : '',
		}
		if(billingAddr?.country_code){
			paymentBillingAddr['countryCode'] = billingAddr.country_code;
		}
		return paymentBillingAddr;
	}

	/**
	 * Store the square verified card into the
	 * @param extraData: extra data for on session payment
	 * @returns object(verification_token, token)
	 */
	public async squareVerifyCard(extraData: any = null): Promise<any>{
		let data: any = await this.generateSquareToken();
		if(extraData?.billing_address){
			extraData.billing_address['zipcode']=data?.postalCode;
		}
		const verificationDetails = {
			intent: 'STORE',
			billingContact:this.getSquareBillingAddr(extraData?.billing_address)
		};
		let sqToken: any = data?.token;
		extraData['last4']=data?.last4; // Last 4 digits
		if(sqToken){
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			let sq: any = this;
			return this.squarePayments.verifyBuyer(sqToken,verificationDetails).then(async (verify: any) => {
				let verifyData: any ={
					verification_token: verify.token,
					token: sqToken,
					last4: data?.last4,
					postalCode: data?.postalCode
				}
				if(extraData?.payment){
					// Save the card
					return await this.saveSquareCard(verifyData, extraData);
				} else{
					return verifyData;
				}
			}).catch((err: any) => {
				sq.displaySquareError(err, 'square-custom-errors');
			});
		} else {
			return null;
		}
	}
	/**
	 * Save the square varified card
	 * @param verifyData: verify data
	 * @param extraData: extra data(location, user id, amount) for on session payment
	 * @returns amount verified data
	 */
	public async saveSquareCard(verifyData: any, extraData: any): Promise<void> {
		verifyData['loc_id']=extraData.base_location_id;
		verifyData['uid']=extraData.uid;
		// For anonymous user
		if(!verifyData?.uid && extraData.email_id){
			verifyData['email_id']=extraData?.email_id;
			verifyData['name']=extraData?.first_name+' '+extraData?.last_name;
		}
		if(extraData?.billing_address){
			verifyData['billing_address'] = extraData?.billing_address;
		}
		// Pass recipient email in case of gift card
		if(extraData?.gift_card_recipient_email){
			verifyData['gift_card_recipient_email'] = extraData?.gift_card_recipient_email;
		}
		if(extraData?.inv_id){
			verifyData['inv_id'] = extraData?.inv_id;
		}
		if(this.utilServ.checkArrLength(extraData?.booking_ids)){
			verifyData['booking_ids'] = extraData?.booking_ids;
		}
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApi('POST', 'SquareCard', verifyData, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				extraData['pay_with_cc']=res?.data?.card_id;
				// For anonymous user
				extraData['pay_customer_id']=res?.data?.pay_customer_id;
				return await this.squarePaymentVerify(res?.data?.card_id, extraData);
			} else {
				// is_captured_amount use only invoice payment when some bookings with card hold and setting is captured.
				if(extraData?.is_captured_amount){
					return res?.message;
				} else {
					if(!extraData?.hide_toast){
						this.toastr.error(res?.message);
					}
				}
				return null;
			}
		});
	}
	/**
	 * Square payment verification
	 * @param sqToken: square token
	 * @param extraData: extra data: amount, location
	 * @param verificationToken: card verification token
	 * @returns object()
	 */
	public async squarePaymentVerify(sqToken: any, extraData: any){
		// Square payment object to there, build the square payment based on location id
		if(!this.squarePayments){
			// Base location true in case of re-auth page only
			let baseLoc: boolean = extraData?.re_auth_token ? true : false;
			await this.buildSquarePayment(null, extraData?.location, baseLoc)
		}
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let sq: any = this;
		const verificationDetails = {
			amount: extraData.amount.toString(),
			billingContact:this.getSquareBillingAddr(extraData?.billing_address),
			currencyCode: this.initServ?.appAdmnStngs?.merchant_settings?.store?.store_currency,
			intent: 'CHARGE'
		};
		return this.squarePayments.verifyBuyer(sqToken,verificationDetails).then(async (paymentVerify: any) => {
			let data: any = {
				verification_token: paymentVerify.token,
				token: sqToken
			}
			return await this.onSessionPayment(extraData, data);
		}).catch(async (err: any) => {
			// is_captured_amount use only invoice payment when some bookings with card hold and setting is captured.
			if(extraData?.is_captured_amount){
				extraData['decline_reason']=err?.message;
				await this.onSessionPayment(extraData);
			} else {
				if(extraData?.re_auth_token){
					sq.toastr.error(err.message);
				} else{
					sq.displaySquareError(err);
				}
			}
		});
	}
	/**
	 * Destroy the square card
	 */
	async destroySquareCard() {
		if (this.squareCard && !this.squareCard._destroyed) {
			await this.squareCard.destroy();
		}
	}

	// ----------------------------------------Paypal functions----------------------------------------
	/**
	 * Get the paypal key
	 * @returns
	 */
	public async paypalKey(): Promise<any> {
		if(this.initServ?.appData?.braintree_tokenization_key){
			return await this.utilServ.aesDecryption(this.initServ.appData.braintree_tokenization_key);
		}
		return null;
	}
	/**
	 * Get the paypal client instance
	 * @param locId : location id
	 * @returns client instance
	 */
	private async paypalClientInstance(locId: string|number = 0, clientAuthorization: any = null): Promise<void> {
		clientAuthorization = clientAuthorization ? clientAuthorization : await this.generateClientToken(locId);
		return await braintree.client.create({authorization: clientAuthorization});
	}
	/**
	 * To help detect fraud, use dataCollector to collect information about a customer's device on your on session payment.
	 * @param instance: Paypal instance
	 * @returns deviceData
	 */
	public async paypalDeviceToken(instance: any): Promise<any>{
		let dataCollectorInstance: any = await braintree.dataCollector.create({client: instance});
		return dataCollectorInstance?.deviceData;
	}
	/**
	 * Generate paypal token
	 * @param data:payment form card values
	 * @param clientAuthorization: client authorization
	 * @param extraData: (location, uid, amount)
	 * @param isPayment: true(on session payment)
	 * @returns token/null
	 */
	public async generatePaypalToken(paymentForm: any,clientAuthorization: any, extraData: any=null, isPayment:boolean = false){
		let instance: any = await this.paypalClientInstance(0, clientAuthorization);
		if(instance){
			return await instance.request({
				endpoint: 'payment_methods/credit_cards',
				method: 'post',
				data: paymentForm
			}).then(async (res: any) => {
				if(res && res._httpStatus == 200){
					extraData['device_data'] = await this.paypalDeviceToken(instance);
					extraData['last4'] = res.creditCards[0].details?.lastFour;
					if(extraData && extraData?.threeDSecure){
						let tokenData: any = { nonce: res.creditCards[0].nonce, bin: res.creditCards[0].details?.bin, instance: instance };
						let token: any = await this.paypalThreeDSecure(tokenData, extraData, isPayment);
						if(token){
							if(extraData?.is_captured_amount){
								return token;
							}
							// For reschedule booking
							if(extraData?.instance){
								return { token: token, instance: instance, last4: extraData?.last4, device_data: extraData?.device_data };
							} else if(isPayment && token?.token){
								return { token: token?.token, card_id: token?.card_id, charge_id: token?.charge_id, pay_customer_id:token?.pay_customer_id,  last4: extraData?.last4, device_data: extraData?.device_data };
							}
							return { token: token, last4: extraData?.last4, device_data: extraData?.device_data };

						}
						return null;
					} else {
						return { token: res.creditCards[0].nonce, last4: extraData?.last4, device_data: extraData?.device_data };
					}
				} else {
					return null;
				}
			}).catch((err: any) => {
				this.paypalElementsErrors(err);
				return null;
			});
		} else {
			return null;
		}
	}
	/**
	 * Paypal element error
	 * @param event Error response
	 */
	public paypalElementsErrors(event:any) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let sq: any = this;
		if(event && event?.details && event?.details?.originalError){
			if(this.utilServ.checkArrLength(event?.details?.originalError?.fieldErrors)){
				(event.details.originalError.fieldErrors).forEach((error: any) => {
					if(error && this.utilServ.checkArrLength(error?.fieldErrors)){
						(error.fieldErrors).forEach((fieldErr: any) => {
							if (fieldErr.message) {
								sq.paypalError(fieldErr.message);
							}
						});
					}
				});
			} else if(event?.details?.originalError?.details && event?.details?.originalError?.details?.originalError && event?.details?.originalError?.details?.originalError?.error){
				if (event?.details?.originalError?.details?.originalError?.error?.message) {
					sq.paypalError(event?.details?.originalError?.details?.originalError?.error?.message);
				}
			} else {
				this.removePaypalError();
			}
		} else {
			this.removePaypalError();
		}
	}
	/**
	 * Paypal error message
	 * @param msg: error message
	 */
	public paypalError(msg: any){
		const displayError = document.getElementById('paypal-errors');
		if (displayError) {
			displayError.innerHTML = '<i class="tjsicon-attention"></i>' + msg + '.';
		}
		this.utilServ.scrollToSpecificEle('payment_details');
	}
	/**
	 * Remove the paypal error
	 */
	public removePaypalError(): void {
		const displayError = document.getElementById('paypal-errors');
		if (displayError) {
			displayError.innerHTML = '';
		}
	}
	/**
	 * Get the payment nonce in case of existing card and new card
	 * New card: Pass the uid to api, based on that create a new card and return the nonce with card id(pay_with_cc)
	 * @param extraData: extra data object(uid, location, amount)
	 * @param instance: new card instance
	 * @returns token/null
	 */
	public async paypalPaymentNonce(extraData: any = null, instance: any = null): Promise<void> {
		let postData: any ={ pay_with_cc:extraData?.pay_with_cc};
		// For card hold case
		if(extraData?.booking_id){
			postData['booking_id']=extraData?.booking_id;
			postData['card_hold']=extraData?.is_hold;
		}
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApi('POST', 'BraintreeNonce', postData, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				// Instance not there get the client instance, this work for already saved card(existing card)
				if(!instance){
					let locId: any = (extraData && extraData?.base_location_id) ? extraData?.base_location_id : 0;
					instance = await this.paypalClientInstance(locId);
				}
				if(instance){
					extraData['device_data'] = await this.paypalDeviceToken(instance);
					let nonceData: any = { nonce: res?.data?.nonce, bin: res?.data?.bin, instance: instance };
					return await this.paypalThreeDSecure(nonceData, extraData, true);
				}
				return false;
			} else {
				if(!extraData?.hide_toast){
					this.toastr.error(res?.message);
				}
				return null;
			}
		});
	}
	/**
	 * Paypal (Braintree) three D secure for verify the card
	 * @param nonceData: braintree data
	 * @param extraData: (location, uid, amount)
	 * @param isPayment: true(on session payment)
	 * @returns token/null
	 */
	public paypalThreeDSecure(nonceData: any, extraData: any=null, isPayment:boolean = false){
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let sq: any = this;
		return braintree.threeDSecure.create({client: nonceData.instance,version:2}).then((threeDS: any) => {
			return threeDS.verifyCard({
				onLookupComplete: function (_data: any, next: () => void) {
					next();
				},
				amount: (extraData && extraData.amount) ? (extraData.amount).toString() : '0',
				nonce: nonceData.nonce,
				bin: nonceData.bin,
				cardAddChallengeRequested: isPayment ? false : true,
				challengeRequested:isPayment
			}).then(async (payload: any) => {
				const { nonce, liabilityShifted } = payload;
				//liabilityShifted indicates that 3D Secure worked and authentication succeeded. This will also be true if the issuing bank does not support 3D Secure, but the payment method does. In both cases, the liability for fraud has been shifted to the bank.
				if (liabilityShifted) {
					if(isPayment){
						return await this.onSessionPayment(extraData, nonce);
					} else {
						return nonce;
					}
				}
			}).catch(async function (err: any) {
				// is_captured_amount use only invoice payment when some bookings with card hold and setting is captured.
				if(extraData?.is_captured_amount){
					extraData['decline_reason']=err?.message;
					return await sq.onSessionPayment(extraData);
				} else {
					if(!extraData?.hide_toast){
						if(err && err.message){
							sq.paypalElementsErrors(err);
						} else {
							sq.toastr.error("3DSecure varification failed");
						}
					}
				}
			});
		}).catch(function (err: { message: any; }) {
			console.log('component error:', err);
		});
	}
	/**
	 * Build the cvv Form
	 * @param locId: location is
	 * @returns object(client instance and hosted field instance)
	 */
	public async braintreeCvvForm(locId: string|number = 0){
		let clientInstance: any = await this.paypalClientInstance(locId);
		if(clientInstance){
			return braintree.hostedFields.create({client: clientInstance,
				styles: {
					input: {
						'font-size': '1rem',
						color: '#495057'
					}
				},
				fields: {
					cvv: {
						selector: "#card-cvc",
						placeholder: "cvv",
					}
				}
			}).then((hostedFieldsInstance: any) => {
				return { clientInstance, hostedFieldsInstance };
			}).catch(function (err: any) {
				console.log("Build error:", err);
			});
		}
	}
	/**
	 * Payment with cvv in paypal
	 * @param data: client instance and hosted field instance
	 * @param log: log data(nonce:existing card, amount)
	 * @returns object
	 */
	public paypalPayWithCvv(data: any, log: any, extraData: any){
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let sq: any=this;
		const { clientInstance, hostedFieldsInstance } = data;
		return hostedFieldsInstance.tokenize().then((fieldPayload: any) => {
			return braintree.threeDSecure.create({client: clientInstance,version:2}).then((threeDS: any) => {
				return threeDS.verifyCard({
					onLookupComplete: function (_data: any, next: () => void) {
						next();
					},
					amount: log.amount.toString(),
					nonce: log.client_secret,
					bin: log.bin,
					challengeRequested:true
				}).then(async (payload: any) => {
					extraData['device_data'] = await this.paypalDeviceToken(clientInstance);
					//liabilityShifted indicates that 3D Secure worked and authentication succeeded. This will also be true if the issuing bank does not support 3D Secure, but the payment method does. In both cases, the liability for fraud has been shifted to the bank.
					if (payload.liabilityShifted) {
						return await this.paypalOnSessionWithCvv(extraData, payload, fieldPayload, log);
					} else {
						return {rebuild: true};
					}
				}).catch(function (err: any) {
					if(err && err.message){
						sq.toastr.error(err.message);
					} else {
						sq.toastr.error("3DSecure varification failed");
					}
					return {rebuild: true};
				});
			});
		}).catch((tokenizeErr: any) => {
			if(tokenizeErr && tokenizeErr.message){
				sq.toastr.error(tokenizeErr.message);
			}
			return {rebuild: true};
		});
	}
	/**
	 * Re auth on session payment
	 * @param token: url token(payment log)
	 * @param data: api send data
	 * @returns object|null
	 */
	private async paypalOnSessionWithCvv(extraData:any, payload: any, fieldPayload: any, log: any): Promise<any>{
		let obj = {
			authentication_id: payload?.threeDSecureInfo?.threeDSecureAuthenticationId,
			cvv_nonce: fieldPayload.nonce,
			loc_id: log.location,
			amount: log.amount,
			pay_with_cc: log.card_id,
			device_data: extraData?.device_data //To help detect fraud, use dataCollector to collect information about a customer's device on your on session payment.
		}
		let queryParams: any = {token: extraData?.re_auth_token};
		if(extraData?.pay_type){
			queryParams['pay_type']=extraData?.pay_type;
		}
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApiWithQueryParams('POST', 'OnSessionPayment', queryParams, obj, header).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				return res.data;
			} else {
				this.toastr.error(res?.message);
				return {rebuild: true};
			}
		});
	}

	// On session api functions use in square and paypal case

	/**
	 * On session payment work on square and braintree payment method
	 * @param extraData: extra data
	 * @param nonce: paypal nonce/square payment data
	 * @param isHold: for query param
	 * @returns api res
	 */
	private async onSessionPayment(extraData: any=null, payment: any=null): Promise<void> {
		let payload: any={
			uid: extraData.uid,
			loc_id: extraData.base_location_id,
			amount: extraData.amount,
			pay_with_cc: extraData?.pay_with_cc,
			pay_customer_id: extraData?.pay_customer_id,
			first_name: extraData?.first_name,
			last_name: extraData?.last_name,
			email_id: extraData?.email_id,
			card_last4_digits:extraData?.last4
		};
		// Booking id if exits
		if(extraData?.booking_id){
			payload['booking_id']=extraData?.booking_id;
		}
		if(this.initServ.paymentGateway == 'square'){
			payload['verification_token'] = payment?.verification_token;
		} else {
			if(!extraData?.pay_with_cc){
				payload['token']=payment;
			}
			payload['verification_token'] = payment;  //In case of braintree, card nonce obtained from verifyCard()are same.
			payload['device_data']= extraData?.device_data; //To help detect fraud, use dataCollector to collect information about a customer's device on your on session payment.
		}
		// Pass the invoice number in case of invoice
		if(extraData?.inv_num){
			payload['inv_num'] = extraData?.inv_num;
		}
		// Pass recipient email in case of gift card
		if(extraData?.gift_card_recipient_email){
			payload['gift_card_recipient_email'] = extraData?.gift_card_recipient_email;
		}
		// is_captured_amount use only invoice payment when some bookings with card hold and setting is captured.
		if(extraData?.is_captured_amount && extraData?.captured_amount){
			payload['captured_amount'] = extraData?.captured_amount;
			payload['decline_reason'] = extraData?.decline_reason;
		}
		if(extraData?.billing_address){
			payload['billing_address'] = extraData?.billing_address;
		}
		if(extraData?.inv_id){
			payload['inv_id'] = extraData?.inv_id;
		}
		if(this.utilServ.checkArrLength(extraData?.booking_ids)){
			payload['booking_ids'] = extraData?.booking_ids;
		}
		// Query params
		let queryParams: any = this.onSessionQueryParams(extraData);
		return await this.onSessionApi(queryParams, payload, payment, extraData);
	}
	/**
	 * Retrun the query param in case of on session payment
	 * @param extraData: extra data
	 * @returns object
	 */
	private onSessionQueryParams(extraData: any): any{
		let queryParams: any = {}
		// payment from Re-authenticate page
		if(extraData?.re_auth_token){
			queryParams['token']=extraData?.re_auth_token;
			if(extraData?.pay_type){
				queryParams['pay_type']=extraData?.pay_type;
			}
		} else {
			// Card hold
			if(extraData?.is_hold){
				queryParams['type']='hold';
			}
		}
		return queryParams;
	}
	/**
	 * On session API call
	 * @param queryParams: query params
	 * @param payload: post data
	 * @param payment: payment data(nonce/token)
	 * @param extraData: extra data
	 * @returns data
	 */
	private async onSessionApi(queryParams: any, payload: any, payment: any, extraData: any){
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApiWithQueryParams('POST', 'OnSessionPayment', queryParams, payload, header).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				let data: any = res.data;
				data['token']=payment;
				if(this.initServ.paymentGateway == 'square'){
					data['token']=payment?.token;
					// For anonymous user
					data['pay_customer_id']=extraData?.pay_customer_id;
				}
				data['last4']=extraData?.last4;
				data['device_data']=payload?.device_data;
				return data;
			} else {
				// is_captured_amount use only invoice payment when some bookings with card hold and setting is captured.
				if(extraData?.is_captured_amount){
					return payload?.decline_reason ?? res?.message;
				} else {
					if(!extraData?.hide_toast){
						this.toastr.error(res?.message);
					}
				}
				return null;
			}
		});
	}

	// Authorize.net functions
	/**
	 * Get the authorize.net key
	 * @returns
	 */
	public async authorizedontnetKey(): Promise<any> {
		if (this.initServ.appData?.authorizedotnet_login_id && this.initServ.appData?.authorizedotnet_client_key) {
			return {
				appId: await this.utilServ.aesDecryption(this.initServ.appData.authorizedotnet_login_id),
				clientKey: await this.utilServ.aesDecryption(this.initServ.appData.authorizedotnet_client_key)
			};
		} else {
			return null;
		}
	}

	/**
	 * Generate token from authorize.net api
	 * @param cardData
	 * @param authorizedotnetKey
	 * @returns
	 */
	public async generateAuthorizedotnetToken(cardData: any, authorizedotnetKey: any) {
		let authData: any = {};
		authData.clientKey = authorizedotnetKey.clientKey;
		authData.apiLoginID = authorizedotnetKey.appId;

		let secureData: any = {};
		secureData.authData = authData;
		secureData.cardData = cardData;

		let output: any = {};
		return createToken().then(res => {
			if (IS_DEV) {
				console.log(res);
			}
			return reuturnOutput();
		});
		// Create token
		function createToken() {
			return new Promise<void>((resolve) => {
				Accept.dispatchData(secureData, (res: any) => {
					{
						if (res.messages.resultCode === "Error") {
							if (res.messages.message[0]) {
								output.message = res.messages.message[0].code + ": " + res.messages.message[0].text;
							}
						} else {
							output.dataValue = res.opaqueData.dataValue;
							output.dataDescriptor = res.opaqueData.dataDescriptor;
						}
						resolve();
					}
				});
			});
		}
		// return output
		function reuturnOutput() {
			return output;
		}
	}
	/**
	 * Authorization booking in case of 3DS card hold
	 * @param bkngRes: booking api res
	 */
	public async authorizationStatus(bkngRes: any, bookingData: any  = null):Promise<any>{
		let data: any = bkngRes?.data;
		if(!bookingData?._id){
			data['type']='add';
		}
		switch(this.initServ.paymentGateway){
			case 'square':
			case 'paypal':
				data = await this.squarePaypalAuthorization(data, bookingData);
			break;
			default:
				this.stripe = null;
				data = await this.stripeAuthorization(data, bookingData);
				break;
		}
		data['token']=bkngRes?.data?.token;
		let header: any = await this.initServ.getAuthHeader();
		return this.apiServ.callApi('PUT', 'AuthorizationStatus', data, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any)=>{
			return res;
		});
	}
	/**
	 * Stripe existing card authorization
	* @param data:booking api res
	 * @param bookingData: booking data
	 * @returns object
	 */
	async stripeAuthorization(data: any, bookingData: any): Promise<void>{
		let extraData: any = {
			client_secret: data?.client_secret,
			amount: data?.amount,
			pay_with_cc: data?.card_id,
			uid:data?.user_id,
			location: bookingData.location_id,
			is_hold: true,
			hide_toast: true,
			stripe: bookingData?.stripe,
		}
		if(!extraData?.uid && bookingData?.uid){
			extraData['uid']=bookingData?.uid;
		}
		let paymentMethod: any = await this.confirmStripePayment(data?.client_secret, extraData);
		if (paymentMethod) {
			data['charge_id']=paymentMethod?.id;
			data['status']=paymentMethod?.status;
		} else {
			data['status']="failed";
		}
		return data;
	}
	/**
	 * square/paypal existing card authorization
	 * @param data:booking api res
	 * @param bookingData: booking data
	 * @returns object
	 */
	async squarePaypalAuthorization(data: any, bookingData: any): Promise<void>{
		let paymentMethod: any;
		let extraData: any = {
			client_secret: data?.client_secret,
			amount: data?.amount,
			pay_with_cc: data?.card_id,
			uid:data?.user_id,
			base_location_id: bookingData.base_location_id,
			location: bookingData.location_id,
			is_hold: true,
			hide_toast: true,
		}
		if(!extraData?.uid && bookingData?.uid){
			extraData['uid']=bookingData?.uid;
		}
		if(this.initServ.paymentGateway == 'square'){
			paymentMethod = await this.squarePaymentVerify(data?.card_id, extraData);
		} else {
			paymentMethod = await this.paypalPaymentNonce(extraData);
		}
		if (paymentMethod) {
			data['charge_id']=paymentMethod?.charge_id;
			data['status']=paymentMethod?.status;
		} else {
			data['status']="failed";
		}
		return data;
	}

	//  Token get functions

	/**
	 * Payment object
	 * @returns object
	 */
	private getPaymentGatewayObj(): PaymentGatewayPayload {
		return {
			token: null,
			verification_token: null,
			pay_with_cc: null,
			charge_id: null,
			last4: null,
			device_data: null,
			pay_customer_id: null,
			instance: null,
			dataValue:null,
			dataDescriptor: null,
			postalCode: null
		};
	}

	/**
	 * Retrieves the token based on the selected payment gateway.
	 * @param formVal - The form data used to generate the token.
	 * @param paymentGatewayChild - An optional PaymentGatewayComponent for additional configuration.
	 * @returns The token generated by the selected payment gateway.
	 */
	//  TODO any
	public generatePaymentGatewayToken(formVal: any, paymentGatewayChild: PaymentGatewayComponent |undefined, isSetupIntent: boolean = false): any{
		switch (this.initServ.paymentGateway) {
			case 'square':
				return this.squareToken(formVal);
			case 'paypal':
				return this.paypalToken(formVal, paymentGatewayChild, isSetupIntent);
			case 'authorizedotnet':
				return this.authorizedotnetToken(paymentGatewayChild);
			default:
				// Default stripe
				return this.stripeToken(formVal, paymentGatewayChild, isSetupIntent);
		}
	}

	/**
	 * Generates a payment gateway token using stripe for payment processing.
	 * @param formVal - The form data containing payment details.
	 * @param paymentGatewayChild - Instance of PaymentGatewayComponent for payment setup.
	 * @param isSetupIntent - Only set the setup intent
	 * @returns An object containing payment details and generated token/IDs.
	 */
	private async stripeToken(formVal: any, paymentGatewayChild: PaymentGatewayComponent |undefined, isSetupIntent: boolean = false): Promise<PaymentGatewayPayload> {
		let token: any;
		// Create an object to store payment gateway details
		let paymentGatewayObj: PaymentGatewayPayload = this.getPaymentGatewayObj();
		if (this.initServ.threeDSecure) {
			if(isSetupIntent){
				token = await paymentGatewayChild?.generateToken(formVal);
			} else {
				if (formVal?.payment_method == 'new_credit_card') {
					formVal['pay_with_cc'] = null;
					formVal['stripe'] = paymentGatewayChild?.stripe;
				} else {
					paymentGatewayObj.pay_with_cc = formVal.pay_with_cc;
				}
				// Call the appropriate payment service method based on configuration
				token = await this.stripePaymentIntent(formVal);
			}
		} else {
			token  = await paymentGatewayChild?.generateStripeToken(formVal);
		}
		return this.updatePayObj(paymentGatewayObj, token, isSetupIntent);
	}

	private updatePayObj(paymentGatewayObj: PaymentGatewayPayload, token: any, isSetupIntent: boolean = false): PaymentGatewayPayload {
		paymentGatewayObj.last4 = token?.last4;
		if(token?.pay_customer_id){
			paymentGatewayObj.pay_customer_id = token?.pay_customer_id;
		}
		if(token){
			paymentGatewayObj.decline_reason = token;
		}
		switch (this.initServ.paymentGateway) {
			case 'stripe':
				if (this.initServ.threeDSecure) {
					paymentGatewayObj.token = isSetupIntent ? token : token?.payment_method;
					paymentGatewayObj.charge_id = token?.id;
				} else {
					paymentGatewayObj.token = token?.token;
				}
				break;
			case 'square':
				paymentGatewayObj.token = token?.token;
				if(this.initServ.threeDSecure) {
					paymentGatewayObj.pay_with_cc = token?.card_id;
					paymentGatewayObj.charge_id = token?.charge_id;
					paymentGatewayObj.verification_token = token?.verification_token;
				}
				paymentGatewayObj.postalCode = token?.postalCode
				break;
			case 'paypal':
				paymentGatewayObj.device_data = token?.device_data;
				paymentGatewayObj.pay_with_cc = token?.card_id;
				paymentGatewayObj.token = token?.token
				paymentGatewayObj.charge_id = token?.charge_id;
				if(token?.instance){
					paymentGatewayObj.instance = token.instance;
				}
				break;
		}
		return paymentGatewayObj;
	}

	/**
	 * Generates a payment gateway token using Square for payment processing.
	 * @param formVal - The form data containing payment details.
	 * @param paymentGatewayChild - Instance of PaymentGatewayComponent for Square setup.
	  * @returns An object with payment details including token, last4 and additional properties based on payment flow.
	 */
	private async squareToken(formVal: any): Promise<PaymentGatewayPayload> {
		let token: any;
		// Create an object to store payment details
		let paymentGatewayObj: PaymentGatewayPayload = this.getPaymentGatewayObj();
		// Check if 3D Secure is enabled
		if(this.initServ.threeDSecure){
			if (formVal?.payment_method == 'new_credit_card') {
				token = await this.squareVerifyCard(formVal);
			} else {
				paymentGatewayObj['pay_with_cc']  = formVal?.pay_with_cc;
				token =  await this.squarePaymentVerify(formVal?.pay_with_cc, formVal);
			}
		} else {
			token  = await this.generateSquareToken();
		}
		return this.updatePayObj(paymentGatewayObj, token);
	}

	/**
	 * Generate a PayPal token based on provided form values and payment gateway child component.
	 * @param formVal - The form data containing payment details.
	 * @param paymentGatewayChild - Instance of PaymentGatewayComponent for PayPal setup.
	 * @returns An object with payment details including token, last4, device_data, and additional properties based on payment flow.
	 */
	public async paypalToken(formVal: any, paymentGatewayChild: PaymentGatewayComponent |undefined, isSetupIntent: boolean = false): Promise<PaymentGatewayPayload>{
		let token: any;
		// Create an object to store payment details
		let paymentGatewayObj: PaymentGatewayPayload = this.getPaymentGatewayObj();
		if(this.initServ.threeDSecure){
			if (formVal?.payment_method == 'new_credit_card') {
				// Setup with payment, true: means on session payment
				let isPayment: boolean = isSetupIntent ? false : true;
				token = await paymentGatewayChild?.paypalSetupInt(formVal, isPayment);
			} else {
				paymentGatewayObj['pay_with_cc']  = formVal?.pay_with_cc;
				token = await this.paypalPaymentNonce(formVal);
			}
		} else {
			token = await paymentGatewayChild?.generatePaypalToken(formVal);
		}
		return this.updatePayObj(paymentGatewayObj, token);
	}

	/**
	 * Generate a authorized dot net token based on payment gateway child component.
	 * @param paymentGatewayChild - Instance of PaymentGatewayComponent for authorized setup.
	 * @returns An object with payment details including token or null.
	 */
	private async authorizedotnetToken(paymentGatewayChild: PaymentGatewayComponent |undefined): Promise<AuthDotNet | null>{
		let token: any = await paymentGatewayChild?.generateAuthDotNetToken();
		if(token && token.dataValue && token.dataDescriptor) {
			return {dataValue: token.dataValue, dataDescriptor: token.dataDescriptor, last4: token.last4};
		} else if(token && token.message) {
			this.toastr.error(token.message);
		}
		return null;
	}

	/**
	 * Assign payment gateway token values in payload data
	 * @param sendData: Payload data
	 * @param paymentGatewayToken: Token object
	 * @returns
	 */
	public assignToken(payload: any, paymentGatewayToken: PaymentGatewayPayload, isAddCard: boolean = false, isCardId: boolean = false): any {
		if(paymentGatewayToken?.last4){
			payload['card_last4_digits']= paymentGatewayToken?.last4;
		}
		let existingCardId: string | null = isCardId ? 'card_id' : 'pay_with_cc';
		if(!isAddCard){
			payload[existingCardId] = paymentGatewayToken?.pay_with_cc;
		}
		if(paymentGatewayToken?.pay_customer_id){
			payload.pay_customer_id = paymentGatewayToken?.pay_customer_id;
		}
		// Work in case of square only
		if(payload?.billing_address && paymentGatewayToken?.postalCode){
			payload.billing_address['zipcode'] = paymentGatewayToken?.postalCode;
		}
		if(this.initServ.paymentGateway == 'authorizedotnet'){
			payload[existingCardId] = null;
			payload['dataValue'] = paymentGatewayToken?.dataValue;
			payload['dataDescriptor'] = paymentGatewayToken?.dataDescriptor;
		} else {
			if(!isAddCard && paymentGatewayToken?.charge_id){
				payload['charge_id'] = paymentGatewayToken?.charge_id;
			}
			if(paymentGatewayToken?.pay_with_cc !== paymentGatewayToken?.token){
				payload['token'] = paymentGatewayToken?.token;
			}
			if(paymentGatewayToken?.verification_token){
				payload['verification_token'] = paymentGatewayToken?.verification_token; // Square payment gateway
			}
			if(paymentGatewayToken?.device_data){
				payload['device_data'] = paymentGatewayToken?.device_data; // Paypal payment gateway
			}
		}
		return payload;
	}


	/**
	 * Deletes payment cache based on the provided extra data.
	 * @param {any} extraData - An object potentially containing `inv_id` and `booking_ids` to include in the payload.
	 * @returns {Promise<any>} - A promise that resolves when the API call completes.
	 * Payload Structure:
	 * - inv_id: string | null (optional) - Invoice ID to include in the payload, or null if not provided.
	 * - booking_ids: number[] | null (optional) - Array of booking IDs to include in the payload, or null if not provided.
	 */
	public deletePaymentCache(extraData:any): void {
		let payload:{inv_id?: string | null; booking_ids?: number[] | null}={inv_id: null,booking_ids: null}
		if(extraData?.inv_id){
			payload['inv_id'] = extraData?.inv_id;
		}
		if(this.utilServ.checkArrLength(extraData?.booking_ids)){
			payload['booking_ids'] = extraData?.booking_ids;
		}
		return this.apiServ.callApi('POST', 'DeletePaymentCache', payload).pipe(takeUntil(this.destroy)).toPromise();
	}
}
