/* eslint-disable max-depth */
/* eslint-disable complexity */
import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from "rxjs/operators";
// External lib
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
// Services
import { NgOnDestroy, ApiServ, LoaderServ, UtilServ, InitServ, PopupServ, RenderComponentServ, SectionServ } from '../../Services';
// Constants
import { CDN_URL } from '../../Constants';

@Component({
	selector: 'bk-drive',
	templateUrl: './Drive.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DriveComponent implements OnInit {
	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	loaderId: string = 'drive-loader';
	uploadLoaderId: string = 'drive-upload-loader';
	limit: number = 20;
	page: number = 1;
	totalRecords: number = 0;
	drives: any;
	route: any;
	docTypes: any = { "file": "fa fa-file", "code": "fa fa-file-code-o", "xls": "fa fa-file-excel-o", "pdf": "fa fa-file-pdf-o", "txt": "fa fa-file-text-o", "docx": "fa fa-file-word-o" };
	driveNote: string = '';
	//
	secId: any;
	section: any = { form: null, save_btn: null, cancel_btn:null, listing: null }
	formKeys: string[] = ['browse', 'note'];
	formBtns: string[] = ['cancel_btn'];
	public uploader:FileUploader = this.utilServ.fileUploadOptions(undefined, 1 * 10485760);
	uploadFileName: string = '';

	// eslint-disable-next-line max-params
	constructor(private actRoute: ActivatedRoute, private router: Router, @Self() private destroy: NgOnDestroy, private toastr: ToastrService, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, public initServ: InitServ, private popupServ: PopupServ, public rcServ: RenderComponentServ, public secServ: SectionServ) {
		// Current url without fragment
		this.route = this.utilServ.getUrlWithoutFragment(this.router.url);
		// URL Fragment, based this pagination page number, other wise default.
		let fragment = this.actRoute.snapshot.fragment;
		if (fragment) {
			this.page = +fragment;
		}
	}

	ngOnInit(): void {
		if (this.admnStngs.merchant_settings?.customers && this.admnStngs.merchant_settings.customers?.show_drive_to_customer == 'no') {
			this.utilServ.redirectBrokenUrl();
		} else {
			this.getDrive(true); // Get the drives
		}
		// Uploader after adding file
		this.uploader.onAfterAddingFile = f => {
			f.withCredentials = false;
			if (this.uploader.queue.length > 1) {
				this.uploader.removeFromQueue(this.uploader.queue[0]);
			}
			this.uploadFileName = f._file.name;
		};
		// building the section
		if (this.secId && this.rcServ.pageData) {
			let pageSett: any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content, this.formKeys, this.formBtns);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}

	/**
	 * User files
	 */
	private getDrive(total: boolean = false): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		let queryParams = { limit: this.limit, page: this.page, total: total };
		this.apiServ.callApiWithPathQueryVars('GET', 'Drive', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
	}
	/**
	 * Get the page number, get the referrals based on page number
	 */
	public pageChange(page: number): void {
		this.page = page;
		this.router.navigateByUrl(`${this.route}#${page}`);
		this.getDrive();
	}
	/**
	 * Confirm popup for delete file
	 * @param id drive id
	 */
	public confirmPopup(id: string | number): void {
		this.popupServ.confirmPopup('delete_file').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res, id));
	}
	/**
	 * On popup result callback method
	 * @param res: popup res
	 * Popup response handler
	 */
	public popupCallback(res: any, data: any = null): void {
		if (res) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId);
			this.apiServ.callApiWithPathVariables('DELETE', 'Drive', [data]).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'delete'));
		}
	}
	/**
	 * Download drive
	 * @param drive
	 */
	public downloadDrive(drive: any): void {
		let url: string = CDN_URL + drive.download_url;
		this.utilServ.downloadFiles(url, drive.document_name, true);
	}
	/**
	 * Empty the upload fields
	 */
	public cancel(): void {
		this.uploadFileName = '';
		this.driveNote = '';
		if (this.uploader.queue.length > 0) {
			this.uploader.removeFromQueue(this.uploader.queue[0])
		}
	}
	/**
	 * Calculate bytes
	 * @param x
	 * @returns
	 */
	private bytesToSize(x: any): any {
		let l = 0, n = parseInt(x, 10) || 0;
		let units = ['bytes', 'KB', 'MB'];
		while (n >= 1024) {
			n = n / 1024;
			l++;
		}
		return (n.toFixed(n >= 10 || l < 1 ? 0 : 1) + ' ' + units[l]);
	}
	/**
	 * Upload file
	 */
	public async uploadFile(): Promise<void> {
		if (this.uploader.queue[0]) {
			this.apiServ.setLoaderId(this.uploadLoaderId);
			this.loader.show(this.uploadLoaderId);
			this.utilServ.fileUploaderApi(this.uploader);
			this.uploader.onCompleteItem = (item: any, res: any) => {
				let result = JSON.parse(res);
				if (result.response) {
					if (result.response.api_status == 1) {
						let sendData: any = {
							customer_id: +this.utilServ.userId(),
							document_name: item.some.name,
							document_title: item.some.name,
							document_size: this.bytesToSize(item.some.size),
							download_url: result.response.data.upload_url,
							file_type: item.some.type,
							is_private: false,
							note: this.driveNote
						}
						this.apiServ.callApiWithPathVariables('POST', 'Drive', [this.utilServ.userId()], sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'upload'));
					} else {
						this.loader.hide(this.uploadLoaderId);
						this.toastr.error(result.response.message);
					}
				}
			}
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any, type: string = 'list'): void {
		switch (type) {
			case 'list':
				if(this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)){
					this.totalRecords = res?.data?.length;
					let driveList:any[] = [];
					// Updates the list of items and determines whether to show a "Load More" button based on the limit.
					let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, driveList, this.limit);
					driveList = loaMoreData.items;
					// add non-private drive items to the list
					if(this.utilServ.checkArrLength(driveList)) {
						this.drives = driveList.filter((drive:any) => !drive.is_private);
					}
				}
				this.loader.hide(this.loaderId);
			break;
			case 'delete':
			case 'upload':
				if(this.apiServ.checkAPIRes(res)){
					this.toastr.success(res.message);
					this.getDrive();
					if(type == 'upload') {
						this.cancel();
					}
				} else {
					if(res && res.message){
						this.toastr.error(res.message);
					}
				}
				this.loader.hide(this.uploadLoaderId);
			break;
			default: break;
		}
	}
}
