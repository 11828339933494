import { Component, Input, OnInit, ViewEncapsulation, Self} from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, SectionServ, RenderComponentServ, ApiServ, InitServ, UtilServ, LeadsServ } from '../../../Services';
// Constants
import { EMAIL_REG_EXP } from '../../../Constants';

@Component({
	selector: 'bk-subscribe-popup',
	templateUrl: './SubscribePopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SubscribePopupComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: any;
	popupData: any;
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		form: null
	}
	subscribeForm: FormGroup;
	varId: any;
	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<SubscribePopupComponent>,public secServ: SectionServ, public rcServ: RenderComponentServ, private frmBldr: FormBuilder, private apiServ: ApiServ, private toastr: ToastrService, @Self() private destroy: NgOnDestroy, private utilServ: UtilServ, public initServ: InitServ, private leadsServ: LeadsServ) {
		this.subscribeForm = this.frmBldr.group({
			list_id : [],
			email: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
		});
	}

	ngOnInit(): void {
		if(this.secId && this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.popupData = this.rcServ.popupData[this.popupId];
			this.buildSection();
		}
	}
	/**
	 * Build section
	 */
	private buildSection(): void {
		this.pageSett = this.popupData.section_settings;
		this.varId = 	this.pageSett[this.secId].variation_id;
		this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.popupData.settings, this.dialogRef);
		this.secServ.setServData(this.pageSett, this.popupData.content);
		let secElem : any = null;
		if(this.popupData.sections){
			secElem = this.popupData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "form":
							this.section[key] =  this.secServ.buildForm(secElem[key]);
							break;
						default:
							this.section[key] = this.secServ.buildText(secElem[key]);
							break;
					}
				}
			}
		}
	}
	/**
	 * Handles the focus out event for the details section.
	 * This function checks if the subscribeForm is valid.
	 * If it is valid, it converts the email to lowercase and adds contact information to a lead.
	 */
	public detailsFocusOut(interval: number | null = null){
		if(this.subscribeForm.valid){
			this.subscribeForm.controls['email'].setValue((this.subscribeForm.controls['email'].value).toLowerCase());
			this.leadsServ.addContactToLead(this.subscribeForm, {type: 'popup', slug: `popup_${this.popupId}`}, interval);
		}
	}

	/**
	 * Submit form
	 */
	public submitForm(): void {
		if(this.subscribeForm.valid){
			this.detailsFocusOut(1);
			this.subscribeForm.controls['email'].setValue((this.subscribeForm.controls['email'].value).toLowerCase());
			let sendData = {
				list_id: this.popupData.settings.mailchimp_list_id,
				email_id: this.subscribeForm.controls['email'].value
			}
			this.apiServ.callApi('POST', 'SubscribeEmail', sendData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		} else {
			this.subscribeForm.controls['email'].markAsTouched();
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.toastr.success(res.message);
			this.dialogRef.close();
		} else{
			if(res && res.message){
				this.toastr.error(res.message);
			}
		}
		this.subscribeForm.reset();
		this.subscribeForm.controls['email'].markAsUntouched();
	}
}
