<div class="py-30 py-md-60" [id]="secId">
	<div class="container">
		<!-- Upload -->
		<div class="card mb-30 bk-section-card" [id]="section?.form?.id" *ngIf="admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.customers && admnStngs?.merchant_settings?.customers?.allow_customer_to_upload_file == 'yes'">
			<div class="card-body position-relative">
				<div class="tjs-card-title bk-section-border" *ngIf="section?.form?.title">
					<h4 class="mb-0 card-title fw-bold" [id]="section?.form?.title_id" [innerHTML]="section?.form?.title | safeHtml"></h4>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<!-- Choose file -->
						<div class="mb-20 profile-file-upload" [id]="section?.form?.browse_id">
							<label class="form-label bk-form-label d-block" *ngIf="section?.form?.browse?.label" [id]="section?.form?.browse?.label_id" [innerHTML]="section?.form?.browse?.label | safeHtml"></label>
							<div class="tjs-custom-file w-100 d-block position-relative rounded">
								<input class="d-none form-control m-0 position-relative w-100" #uploadEl id="chooseFile" type="file" ng2FileSelect [uploader]="uploader">
								<label [id]="section?.form?.browse?.placeholder_id" [attr.data-browse-label]="section?.form?.browse?.upload_btn" class="upload-file__label tjs-pointer tjs-custom-file__label form-check-label form-control overflow-hidden text-truncate d-flex align-items-center" for="chooseFile">
									<ng-container *ngIf="uploadFileName; else noFile">{{uploadFileName | translate}}</ng-container>
									<ng-template #noFile>{{section?.form?.browse?.placeholder}} ...</ng-template>
								</label>
							</div>
						</div>
						<div class="mb-20" [id]="section?.form?.note_id">
							<label class="form-label bk-form-label d-block" *ngIf="section?.form?.note?.label" [id]="section?.form?.note?.label_id" [innerHTML]="section?.form?.note?.label | safeHtml"></label>
							<textarea class="form-control" attr.placeholder="{{section?.form?.note?.placeholder}}" [(ngModel)]="driveNote" rows="3"></textarea>
						</div>
					</div>
				</div>
				<div class="d-sm-flex d-grid align-items-center justify-content-sm-end pt-20 border-top bk-section-border">
					<bk-navigate [isDebounce]="true" class="me-sm-10 mb-sm-0 mb-15" [secSett]="section?.save_btn" [secId]="section?.save_btn?.id" [designId]="section?.save_btn?.design_id" [innerHTML]="(section?.save_btn?.content)" customClass="btn btn-primary btn-lg" essentialClass="w-100 py-15" (callback)="uploadFile()"></bk-navigate>
					<bk-navigate [secSett]="section?.cancel_btn" [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="(section?.cancel_btn?.content)" customClass="btn btn-danger btn-lg" essentialClass="w-100 py-15" (callback)="cancel()"></bk-navigate>
				</div>
				<!-- Loader -->
				<bk-app-loader [loaderId]="uploadLoaderId"></bk-app-loader>
			</div>
		</div>
		<!-- Listing -->
		<div class="card bk-section-card position-relative" [id]="section?.listing?.id">
			<div class="card-body">
				<ng-container *ngIf="drives && drives.length > 0; else noData">
					<div class="tjs-card-title bk-section-border" *ngIf="section?.listing?.title">
						<h4 class="mb-0 card-title fw-bold" [id]="section?.listing?.title_id" [innerHTML]="section?.listing?.title | safeHtml"></h4>
					</div>
					<div class="card shadow-sm">
						<div class="card-body p-0">
							<table [id]="section?.listing?.table_id" class="drive-files-table bk-table table tjs-table tjs-table--hover tjs-table--responsive tjs-table--border__x">
								<thead>
									<tr>
										<th class="w-35">{{section?.listing?.table?.name}}</th>
										<th class="w-25">{{section?.listing?.table?.file_type}}</th>
										<th class="w-15">{{section?.listing?.table?.size}}</th>
										<th class="w-25 text-end">{{section?.listing?.table?.action}}</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let drive of drives;trackBy: utilServ.trackByFnIndex">
										<td [attr.data-label]="section?.listing?.table?.name">
											<div class="d-flex align-items-center flex-wrap td-text--fs">
												<div class="d-flex align-items-center">
													<span *ngIf="docTypes && docTypes[drive?.file_type]; else noIcon"><i [class]="docTypes[drive?.file_type]"></i></span>
													<ng-template #noIcon><i [class]="docTypes?.txt" ></i></ng-template>
													<span class="ms-5 fs-14 d-block text-truncate tjs-maxwidth-200 td-text--fs">{{drive?.document_name}}</span>
												</div>
												<div class="tjs-tooltip-wrap" *ngIf="drive?.note">
													<i class="tjsicon-information ms-5 text-secondary tjs-pointer tooltip--color"></i>
													<div class="tjs-tooltip tjs-tooltip--top"><span>{{drive?.note}}</span></div>
												</div>
											</div>
										</td>
										<td [attr.data-label]="section?.listing?.table?.file_type">
											<div><small class="td-text--fs">{{drive?.file_type}}</small></div>
										</td>
										<td [attr.data-label]="section?.listing?.table?.size">
											<div><small class="dir-ltr td-text--fs">{{drive?.document_size}}</small></div>
										</td>
										<td [attr.data-label]="section?.listing?.table?.action">
											<div class="tjs-table--btn d-flex align-items-center justify-content-end gap-10 flex-wrap">
												<ng-container *ngIf="drive?.is_secure_download; else oldMethod">
													<bk-navigate innerHTML="<i class='tjsicon-download me-sm-10 me-5'></i>{{'Download' | translate}}" customClass="bg-transparent border-0" essentialClass="btn-link fs-14 p-0 text-dark d-flex align-items-center action-btn action-btn__download" (callback)="utilServ.downloadSecureFile(drive?.download_url, drive?.document_name)"></bk-navigate>
												</ng-container>
												<ng-template #oldMethod>
													<bk-navigate innerHTML="<i class='tjsicon-download me-sm-10 me-5'></i>{{'Download' | translate}}" customClass="bg-transparent border-0" essentialClass="btn-link fs-14 p-0 text-dark d-flex align-items-center action-btn action-btn__download" (callback)="downloadDrive(drive)"></bk-navigate>
												</ng-template>
												<bk-navigate innerHTML="<i class='tjsicon-delete me-sm-10 me-5'></i>{{'Delete' | translate}}" customClass="bg-transparent border-0" essentialClass="btn-link fs-14 p-0 text-dark d-flex align-items-center action-btn action-btn__delete" (callback)="confirmPopup(drive?.id)"></bk-navigate>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</ng-container>
				<ng-template #noData>
					<h4 class="mb-0 fw-bold" [id]="section?.listing?.title_id" translate>{{initServ.appStr?.errorMsg?.noDrive}}</h4>
				</ng-template>
			</div>
			<!-- Pagination -->
			<bk-prev-next-pagination [secId]="section?.listing?.pagination_id"  [totalRecords]="totalRecords" [currentPage]="page" [limit]="limit" (callback)="pageChange($event)"></bk-prev-next-pagination>
			<!-- Loader -->
			<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
		</div>
	</div>
</div>
