import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BkngInvDetailsPrice, BkngPriceFields, Invoice } from 'src/app/Interfaces';
// Services
import { InitServ, UtilServ } from 'src/app/Services';
import { InvServ } from 'src/app/Session/Invoices';

@Component({
	selector: 'bk-inv-custom-fields',
	templateUrl: './InvCustomFields.component.html',
	encapsulation: ViewEncapsulation.None
})
export class InvCustomFieldsComponent implements OnInit  {

	@Input() invSec: any;
	@Input() invData: Invoice | undefined;
	@Input() bkngAmtData: BkngInvDetailsPrice | undefined;
	@Input() summaryBkngPriceFields: BkngPriceFields | null = null
	bkngCustomFields: {text: keyof BkngPriceFields}[] = [];

	constructor(public utilServ: UtilServ, public invServ:InvServ, public initServ: InitServ) { }

	ngOnInit(): void {
		this.bkngCustomFields = this.invData?.bkng_custom_fields ?? [];
	}
}
