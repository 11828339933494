import { UtilServ } from 'src/app/Services';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// Service
import { InitServ } from '../Services';

/**
 * Pipe to retrieve customized booking labels based on industry and form ID.
 * Uses a cached dataset (`bkngCustomzData`) to return user-defined labels.
 */
@Pipe({
	name: 'bkngCustomLabel'
})
export class BkngCustomzLabelPipe implements PipeTransform {

	constructor(private translate: TranslateService, private initServ: InitServ, private utilServ: UtilServ) {}

	/**
	* Transforms the given industry-form ID and label name into a customized label.
	* If no customization is found, returns the default translated value.
	* @param {string} indFormId - Industry and form ID combined as a key.
	* @param {string} labelName - The label name to look up.
	* @param {string} defVal - Default value if no customization exists.
	* @returns {string | boolean} - Returns the customized label, default translated value, or `false` if hidden.
	*/
	transform(indFormId: string, labelName: string, defVal: string): string | boolean {
		// const bkngCustData = this.initServ.bkngCustomzData;
		let bkngCustData = this.initServ.bkngCustData;
		// console.log(bkngCustData, 'bkngCustData');

		if (!bkngCustData || !indFormId) return this.translate.instant(defVal);

		if (this.isFieldExistInObject(bkngCustData, indFormId, labelName) && bkngCustData[indFormId][labelName]) {
			return bkngCustData[indFormId][labelName] === 'ele_hide' ? false : bkngCustData[indFormId][labelName];
		}
		return this.translate.instant(defVal);
	}

	public isFieldExistInObject(bkngCustData: any, indFormId: string, labelName: string): boolean {
		return this.utilServ.objHasProp(bkngCustData, indFormId) && this.utilServ.objHasProp(bkngCustData[indFormId], labelName);
	}
}